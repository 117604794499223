import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
)


const options = {
    responsive: true,
    plugins: {
      legend: false,
      title: {
        display: false
      },
    }
}

const LineChart = () => {

  const { dataKeywordResult } = useSelector( state => state.news )
  const { t } = useTranslation()
  const sortedPolarityAmounts = dataKeywordResult.polarityAmounts.slice().sort((a,b) => a.value - b.value)
  
  // Polaridad sin repetidos
  const labels = sortedPolarityAmounts.map( row => row.value )
  // Cantidad de veces que se repite
  const scores = sortedPolarityAmounts.map( row => row.amount )

  
  const data = useMemo(function(){
    return {
        datasets: [{
            data: scores,
            fill: true,
            pointRadius: 0,
            tension: 0.1,
            borderColor: '#0089e4',
            borderWidth: 2,
            backgroundColor: 'rgb(0, 137, 228, 0.4)',
        }],
        labels
    }
}, [])


  return (
    <div className='chart-styles'>
        <h5 className='chart-title mb-0'>{ t('averagePolarityTitle') }</h5>
        <small className='chart-subtitle'>{ t('averagePolaritySubtitle') }</small>

        <Line data={data} options={options} />
    </div>
  )
}

export default LineChart