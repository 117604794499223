import React from 'react'

const MediaCarousel = ({media, mediaIndex}) => {

  return (
    <div id={`carouselExampleIndicators${mediaIndex}`} className="carousel slide mt-3" data-bs-ride="carousel">
        <div className="carousel-inner">
            {
                media.map( (media, index) => (
                    <div
                        key={index}
                        className={ index === 0 ? 'carousel-item active' : 'carousel-item'}
                        data-interval="2000"
                    >
                        <img src={media.media_url_https} className="d-block w-100" alt=''/>
                    </div>
                ) )
            }
        </div>

        <button className="carousel-control-prev" type="button" data-bs-target={`#carouselExampleIndicators${mediaIndex}`} data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target={`#carouselExampleIndicators${mediaIndex}`} data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
    </div>
  )
}

export default MediaCarousel