import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import { AgGridReact } from 'ag-grid-react';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import SentimentFormatter from '../../SentimentFormatter';
import DateFormatter from '../../DateFormatter';
import VerifiedFormatter from '../../VerifiedFormatter';
import FollowersFormatter, { AccountFormatter, LikesFormatter, LocationFormatter, PolarityFormatter, RetweetsFormatter, UsernameFormatter } from '../../NumberFormatter';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../../styles/table.css'


class deliveredMessageIndicator {
    init(params) {
        const element = document.createElement('a');
        const tweetId = 'Ver tweet'
        element.className = 'text-decoration-none text-lightblue see-tweet-link'
        element.innerText = tweetId
        element.href = `https://twitter.com/${params.data.user_account}/status/${params.value}`
        element.target = '_blank'
        this.eGui = element;
    }
    getGui() {
        return this.eGui;
    }
}


const TableSentimentResult = () => {
    
    const { dataKeywordResult } = useSelector( state => state.twitter )
    const { t } = useTranslation()
  
    const gridRef = useRef(); // Optional - for accessing Grid's API
    const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row

    // Each Column Definition results in one Column.
    const [columnDefs, setColumnDefs] = useState([
        {
            field: 'user_name',
            headerName: t('userName'),
            cellRenderer: UsernameFormatter
        },
        {
            field: 'user_account',
            headerName: t('account'),
            cellRenderer: AccountFormatter
        },
        {
            field: 'is_verified',
            headerName: 'Verificado',
            cellRenderer: VerifiedFormatter,
            width: 130
        },
        {
            field: 'followers_count',
            headerName: t('followers'),
            cellRenderer: FollowersFormatter,
            sort: 'desc',
            width: 160
        },
        {
            field: 'tweet_raw',
            headerName: t('tweet'),
            width: 400
        },
        {
            field: 'tweet_analysis.sentiment',
            headerName: t('sentiment'),
            cellRenderer: SentimentFormatter
        },
        {
            field: 'created_date_raw',
            headerName: t('tweetDate'),
            cellRenderer: DateFormatter
        },
        {
            field: 'id_tweet',
            headerName: 'Tweet link',
            cellRenderer: deliveredMessageIndicator,
            width: 130
        },
        {
            field: 'likes',
            headerName: t('likes'),
            cellRenderer: LikesFormatter,
            width: 120
        },
        {
            field: 'retweet_count',
            headerName: t('retweets'),
            cellRenderer: RetweetsFormatter,
            width: 120
        },
        {
            field: 'user_location',
            headerName: t('location'),
            cellRenderer: LocationFormatter
        },
        {
            field: 'tweet_analysis.polarity',
            headerName: t('polarity'),
            cellRenderer: PolarityFormatter
        }
    ]);
    
    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo( ()=> ({ sortable: true, filter: true, resizable: true}) );

  
    // Example load data from sever
    useEffect(() => {
        setRowData(dataKeywordResult.data)
    }, []);

    
    // const autoSizeAll = useCallback((skipHeader) => {
    //     const allColumnIds = [];
    //         gridRef.current.columnApi.getColumns().forEach((column) => {
    //             allColumnIds.push(column.getId());
    //         });
    //     gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    // }, []);


    const onBtnExport = () => {
        const sheetData = dataKeywordResult.data
        const wb = XLSX.utils.book_new()
        const ws = XLSX.utils.json_to_sheet(sheetData)
        XLSX.utils.book_append_sheet(wb, ws, "MySheet1")
        XLSX.writeFile(wb, "Feelings-sentiment-analysis.xlsx");
    }

    
    const onFirstDataRendered = useCallback(() => {
        // gridRef.current.api.sizeColumnsToFit();
        // const allColumnIds = [];
        // gridRef.current.columnApi.getColumns().forEach((column) => {
        //     allColumnIds.push(column.getId());
        // });
        // gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }, []);


    return (
        <div>
            <div className='mt-5'>
                <h5 className='text-center sentiment-result-charts-title mb-0'>
                    { t('sentimentTable') }
                </h5>
                <h6 className='text-center sentiment-result-charts-subtitle'>
                    { t('tableDetail') }
                </h6>
            </div>

            <div className='d-flex justify-content-end'>
                <button
                    className='btn btn-sm btn-mediumblue'
                    onClick={ onBtnExport }
                >
                    <Icon icon="mdi:microsoft-excel" className='icon-size2 me-2' />
                    { t('exportTable') }
                </button>
            </div>
            
            <div className="ag-theme-alpine" style={{width: "100%", height: 600}}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    pagination={true}
                    onFirstDataRendered={onFirstDataRendered}
                />
            </div>
        </div>
    )
}

export default TableSentimentResult