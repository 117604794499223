import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TwitterIcon from '@mui/icons-material/Twitter';
import MediaCarousel from '../../MediaCarousel';
import twitterVerified from '../../../img/twitter_verified.png'
import { nfObject } from '../../../helpers/utils';
import { useTranslation } from 'react-i18next';
import '../../../styles/twitter-card.css'

let videoData;

const TwitterCard = ({tweetData, mediaIndex}) => {
  
  const formatDate = new Date(tweetData.created_date_raw).toLocaleString()
  const { t } = useTranslation()

  if(tweetData.media && (tweetData.media.media[0].type === 'video')){
    videoData = tweetData.media.media[0].video_info.variants.filter( videoData => videoData.bitrate )
    videoData.sort( (a,b) => b.bitrate - a.bitrate )
  }

  return (
    <Card className='twitter-card-shadow' sx={{ maxWidth: 345 }}>
      <CardHeader
        avatar={
          <Avatar src={tweetData.avatar_image} aria-label="recipe"></Avatar>
        }
        title={
            <div className='d-flex align-items-center'>
              <a className='d-flex align-items-center text-decoration-none text-dark' href={`https://twitter.com/${tweetData.user_account}`} target="_blank" rel="noopener noreferrer">
                <strong>{tweetData.user_name}</strong>
              </a>
              {
                tweetData.is_verified && (
                  <img className='ms-1' src={twitterVerified} width={20} alt="Verified" />
                )
              }
            </div>
        }
        subheader={
          <div className='d-flex align-items-center'>
            <a className='d-flex align-items-center text-decoration-none' href={`https://twitter.com/${tweetData.user_account}`} target="_blank" rel="noopener noreferrer">
              <small small style={{ color: '#536471' }}>@{tweetData.user_account}</small>
            </a>
            <strong className='mx-1'>·</strong>
            <small style={{ color: '#536471' }}>{nfObject.format(tweetData.followers_count)} {t('followers')}</small>
          </div>
        }
      />
      
      {/* Text content */}
      <CardContent className='py-0'>
        <Typography variant="body2" color="text.secondary">
          { tweetData.tweet_raw }
        </Typography>
      </CardContent>
      
      {/* Media content */}
      {
        tweetData.media && (tweetData.media.media.length === 1) && tweetData.media.media[0].type === 'photo' && (
          <CardMedia
            className='mt-3'
            component="img"
            height="194"
            image={tweetData.media.media[0].media_url_https}
          />
        )
      }
      {
        tweetData.media && (tweetData.media.media.length > 1) && tweetData.media.media[0].type === 'photo' && (
          <MediaCarousel media={tweetData.media.media} key={mediaIndex} mediaIndex={mediaIndex} />
        )
      }
      {
        tweetData.media && tweetData.media.media[0].type === 'video' && (
          <CardMedia
            className='mt-3'
            component="video"
            height="194"
            src={videoData[0].url}
            autoPlay
            muted
            controls
          />
        )
      }

      {/* Data and icons */}
      <div className='d-flex justify-content-between align-items-center px-3 mt-3'>
        <span className={`tweet-tag tweet-tag-${tweetData.tweet_analysis.sentiment.toLowerCase()}`}>
            { tweetData.tweet_analysis.sentiment }
        </span>

        <small style={{ color: '#536471', fontSize: '12px' }}>{formatDate}</small>
      </div>
      <hr className='mt-2 mb-1 mx-3'/>

      <CardActions className='d-flex justify-content-between pt-0' disableSpacing>
        <div>
          <IconButton disableRipple aria-label="favorites">
            <small className='card-likes'>{tweetData.likes} {t('likes')}</small>
          </IconButton>
          <strong style={{ color: '#536471' }} className='mx-1'>·</strong>

          <IconButton disableRipple aria-label="favorites">
            <small className='card-likes'>{tweetData.retweet_count} Retweets</small>
          </IconButton>
        </div>

        <IconButton className='text-decoration-none' aria-label="share" href={`https://twitter.com/${tweetData.user_account}/status/${tweetData.id_tweet}`} target='_blank'>
            <TwitterIcon className='text-twitter' />
        </IconButton>
      </CardActions>
    </Card>
  );
}

export default TwitterCard