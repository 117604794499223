import { useAuth0 } from '@auth0/auth0-react';
import { Icon } from '@iconify/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkIfAccountSelected } from '../../helpers/analysis';
import { showWarnToast } from '../../helpers/utils';
import { closeModal } from '../../store/slices/modal/modalSlice';
import { startGettingAccountAnalysis, startGettingTweetsAnalysis } from '../../store/slices/twitter/thunks';
import { setTwitterSearchParameters } from '../../store/slices/twitter/twitterSlice';
import { startGettingNewsAnalysis } from '../../store/slices/news/thunks';
import { startGettingHashtagAnalysis } from '../../store/slices/instagram/thunks';



const DelayRequestNotification = () => {
    
    const { user } = useAuth0();
    const { twitterSearchParameters } = useSelector( state => state.twitter )
    const { instagramSearchParameters } = useSelector( state => state.instagram )
    const { newsSearchParameters } = useSelector( state => state.news )
    const { platformSelected, plan } = useSelector( state => state.user )
    const { globalSettings } = useSelector( state => state.settings )
    const dispatch = useDispatch();
    const { t } = useTranslation();

    
    const handleSearchTweets = async() => {
        const searchParameters = await platformSelected === 'twitter' ? twitterSearchParameters : ( platformSelected === 'instagram' ? instagramSearchParameters : newsSearchParameters)
        const paramsChecked = checkIfAccountSelected( searchParameters )
        if(!paramsChecked){ return console.log('Selecciona una cuenta por favor') }

        const email = user.email
        const tenant = user.email.replace('@', '_')
        const body = { ...searchParameters, email, tenant, platformSelected, plan }

        if(platformSelected === 'twitter' && (searchParameters.requestType === 'keyword')){

            const amountRequested = searchParameters.requestAmount
            const resultsType = searchParameters.resultsType
            if(amountRequested < 100){
                return showWarnToast('Cantidad debe ser mayor o igual a 100')
            } else if(amountRequested > searchParameters.maxRequestAmount){
                return showWarnToast(`Cantidad debe ser menor o igual a ${searchParameters.maxRequestAmount}`)
            } else if(resultsType === ''){
                return showWarnToast('Debe seleccionar un tipo de resultado a extraer')
            } else {
                dispatch( startGettingTweetsAnalysis(body) )
            }

        } else if(platformSelected === 'twitter' && (searchParameters.requestType === 'account')){

            const accountSelected = await document.querySelector(`#isMyAccount${searchParameters.requestSelectedAccountIndex}`).value
            dispatch( setTwitterSearchParameters({name: 'requestSelectedAccount', value: accountSelected}) )
            dispatch( startGettingAccountAnalysis(body) )

        } else if(platformSelected === 'instagram'){
            const amountRequested = searchParameters.requestAmount
            const email = user.email
            const tenant = user.email.replace('@', '_')
            const igAccessToken = await globalSettings.instagramCredentials.access_token
            const body = { ...instagramSearchParameters, email, tenant, platformSelected, plan, igAccessToken }
            
            if(amountRequested < 100){
                return showWarnToast('Cantidad debe ser mayor o igual a 100')
            } else if(amountRequested > searchParameters.maxRequestAmount){
                return showWarnToast(`Cantidad debe ser menor o igual a ${searchParameters.maxRequestAmount}`)
            } else {
                dispatch( startGettingHashtagAnalysis(body) )
            }

        } else if(platformSelected === 'news') {
            dispatch( startGettingNewsAnalysis(body) )
        }

        dispatch( closeModal() )
    }


  return (
    <div className="alert alert-warning m-0 p-5" role="alert">
        <h4 className='text-center text-mediumblue mb-3'>{ t('importantNotification') }</h4>

        <div className='d-flex'>
            <div>
                <Icon icon="bi:info-circle-fill" className='alert-icon-details' />
            </div>
            <span>
                { t('delayNotificationText') }.
            </span>
        </div>

        <div className='d-flex justify-content-center mt-3'>
            <button
                className='btn btn-sm btn-lightblue'
                onClick={ handleSearchTweets }
            >
                { t('continue') }
            </button>
            <button
                className='btn btn-sm btn-91 ms-3'
                onClick={ () => dispatch( closeModal() ) }
            >
                { t('cancel') }
            </button>
        </div>
    </div>
  )
}

export default DelayRequestNotification