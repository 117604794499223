import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { checkingAuthentication } from '../store/auth/thunks';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';


const LoginButton = () => {

  const { t } = useTranslation();
  const { loginWithRedirect } = useAuth0();
  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();
    loginWithRedirect();
    dispatch( checkingAuthentication() )
  }

  return (
    <div
        className='pointer text-primary ms-2'
        onClick={ handleLogin }
    >
        { t('login') }
    </div>
  )
}

export default LoginButton