import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  openModal: false,
  modalSelected: null
}

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal: (state, {payload}) => {
            state.openModal = true;
            state.modalSelected = payload;
        },
        closeModal: (state) => {
            state.openModal = false;
        },
    },
})

// Action creators are generated for each case reducer function
export const { openModal, closeModal } = modalSlice.actions