import { fetchBackend } from "./fetch"


export const getUserInformationFromDatabase = async(tenantid) => {
    const resp = await fetchBackend(`tenant-information/${tenantid}`, 'GET')
    const respJson = await resp.json()
    const tenantInfo = await respJson.tenantData
    const userInfo = await respJson.userData
    const historyData = await respJson.historyData
    const twitterPeriodCount = await respJson.twitterPeriodCount
    const instagramPeriodCount = await respJson.instagramPeriodCount
    const newsPeriodCount = await respJson.newsPeriodCount
    const favoriteData = await historyData.filter( data => JSON.parse(data.isfavorite) )
    const historyCount = await respJson.historyCount
    const globalSettings = await JSON.parse(respJson.globalSettings.setting)
    const planDetails = await globalSettings.products.filter( prod => prod.plan_name.toLowerCase() === tenantInfo.plan )[0]
    
    const date1 = new Date(tenantInfo.datecreated);
    const date2 = new Date();
    var differenceInTime = date2.getTime() - date1.getTime();
    var differenceInDays = parseInt(differenceInTime / (1000 * 3600 * 24));

    const userEnabledToContinue = (historyCount < planDetails.max_requests) && (JSON.parse(tenantInfo.active)) && planConditionsVerify(tenantInfo, differenceInDays)

    let twitterRequests = 0
    let instagramRequests = 0
    for( let row of historyData ){
        if(row.socialmedia === 'twitter'){
            twitterRequests++
        } else if(row.socialmedia === 'instagram'){
            instagramRequests++
        }
    }

    return { tenantInfo, userInfo, twitterRequests, instagramRequests, historyCount, historyData, twitterPeriodCount, instagramPeriodCount, newsPeriodCount, globalSettings, userEnabledToContinue, planDetails, differenceInDays, favoriteData }
}


const planConditionsVerify = (tenantInfo, differenceInDays) => {
    let verified;

    if(tenantInfo.plan !== 'trial' && JSON.parse(tenantInfo.paid)){
        verified = true
    } else if(tenantInfo.plan === 'trial' && (differenceInDays <= 15)){
        verified = true
    } else {
        verified = false
    }

    return verified
}


export const sendResetPasswordEmail = async(email) => {
    const checkUserRequest = await fetchBackend(`users/${email}`)
    const checkUserRes = await checkUserRequest.json()

    if(checkUserRes.ok){
        const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN
        const auth0ClientID = process.env.REACT_APP_AUTH0_CLIENT_ID
        const url = `https://${auth0Domain}/dbconnections/change_password`;
    
        const data = {
            client_id: auth0ClientID,
            email,
            connection: "Username-Password-Authentication"
        }
    
        await fetch( url, {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify( data )
        });
    }

    return checkUserRes
}
