import React, { useState } from 'react'
import FeelingsLogo from '../../img/feelings-logo.png'
import { sendResetPasswordEmail } from '../../helpers/user'
import '../../styles/login.css'
import { useTranslation } from 'react-i18next'


const ResetPasswordScreen = () => {

    const { t } = useTranslation()
    const [userEmail, setUserEmail] = useState('')
    const [emailChecked, setEmailChecked] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleResetPassword = async() => {
        setLoading(true)
        const changePasswordRequest = await sendResetPasswordEmail(userEmail)
        if(changePasswordRequest.ok){
            setEmailChecked('emailSent')
        } else {
            setEmailChecked('notFound')
        }
        setLoading(false)
    }


    if(emailChecked === 'emailSent'){
        return <div className="login-screen">
                    <div className="reset-password-box">
                        <div>
                            <div className='login-logo'>
                                <img src={FeelingsLogo} alt="Feelings" />
                            </div>
                        </div>

                        <div className='login-text'>
                            <h1 className='login-title'>{ t('emailSent') }</h1>
                            <span className='login-subtitle'>
                                { t('emailSentSubtitle1') } <strong>{userEmail}</strong> { t('emailSentSubtitle2') }.
                            </span>
                        </div>

                        <hr />
                        <div className='d-flex justify-content-center'>
                            <a
                                href='/'
                                className='text-decoration-none pointer text-mediumblue'
                            >
                                { t('backToLogin') }
                            </a>
                        </div>
                    </div>
               </div>
    }

  return (
    <div className='login-screen'>
        <div className="reset-password-box">
            <div>
                <div className='login-logo'>
                    <img src={FeelingsLogo} alt="Feelings" />
                </div>

                <div className='login-text'>
                    <h1 className='login-title'>{ t('emailPlaceholder') }</h1>
                    <span className='login-subtitle'>
                        { t('resetPasswordSubtitle') }.
                    </span>
                </div>

                {
                    emailChecked === 'notFound' && (
                        <div className="alert alert-danger user-email-rejected" role="alert">
                            { t('emailDoesnotExist') }
                        </div>
                    )
                }
                
                <div className='inputs-container'>
                    <div className='login-input'>
                        <input
                            type="text"
                            id='resetPasswordEmail'
                            className='form-control'
                            placeholder='Correo electrónico'
                            value={userEmail}
                            onChange={ (e) => setUserEmail(e.target.value) }
                        />
                    </div>
                </div>
            </div>

            <div>
                <button
                    className='btn btn-lightblue btn-login'
                    onClick={ handleResetPassword }
                    disabled={!userEmail || loading}
                >
                    { t('confirm') }
                </button>

                <hr />
                <div className='d-flex justify-content-center'>
                    <a
                        href='/'
                        className='text-decoration-none pointer text-mediumblue'
                    >
                        { t('backToLogin') }
                    </a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ResetPasswordScreen