import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    newUserInformation: {
        userName: '',
        userLastname: '',
        userPassword: '',
        userEmail: '',
        userEmailVerify: '',
        userPasswordVerify: '',
        userCompany: '',
        userPhone: '',
        userCountry: '',
        selectedPlan: 'trial',
        userSettings: '',
        userLanguage: ''
    },
    registerStages: {
        loadingButton: false,
        showErrorMessage: false,
        showPasswordErrorMessage: false,
        countryOptions: [],
        termsAndConditionsChecked: false,
        message: '',
        showPasswordRules: false,
        loadingRedirect: false
    }
}

export const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        setNewUserInformation: (state, {payload}) => {
            state.newUserInformation[payload.name] = payload.value;
        },
        setRegisterStage: (state, {payload}) => {
            state.registerStages[payload.type] = payload.value;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setNewUserInformation, setRegisterStage, processingRegisterNewUser, finishRegisterNewUser } = registerSlice.actions