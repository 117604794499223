import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
)


const options = {
    fill: true,
    responsive: true
}

const ComparativeAccountsbarChart = () => {
    
    const { dataAccountsResult } = useSelector( state => state.twitter )
    const [comparativeDataSelected, setComparativeDataSelected] = useState('posts')
    const { t } = useTranslation()

    const alcance = dataAccountsResult.data.map( row => row.alcance )
    const efectividad = dataAccountsResult.data.map( row => row.efectividad )
    const following = dataAccountsResult.data.map( row => row.amigos_totales )
    const followers = dataAccountsResult.data.map( row => row.followers_count )
    const likes_emitidos = dataAccountsResult.data.map( row => row.likes_totales )
    const posteos = dataAccountsResult.data.map( row => row.posts_count )

    const postsScores = posteos
    const likesEmitidosScores = likes_emitidos
    const followersScores = followers
    const followingScores = following
    const alcanceScores = alcance
    const efectividadScores = efectividad
    const labels = dataAccountsResult.data.map( row => row.screen_name )


    const alcanceData = useMemo(function(){
        return {
            datasets: [
                {
                    label: t('scope'),
                    data: alcanceScores,
                    tension: 0.3,
                    borderWidth: 1,
                    borderColor: "rgb(38, 60, 122)",
                    backgroundColor: "rgb(38, 60, 122, 0.5)"
                }
            ],
            labels
        }
    }, [])

    
    const efectividadData = useMemo(function(){
        return {
            datasets: [
                {
                    label: t('effective'),
                    data: efectividadScores,
                    tension: 0.3,
                    borderWidth: 1,
                    borderColor: "rgb(38, 60, 122)",
                    backgroundColor: "rgb(38, 60, 122, 0.5)"
                }
            ],
            labels
        }
    }, [])

    
    const followingData = useMemo(function(){
        return {
            datasets: [
                {
                    label: t('following'),
                    data: followingScores,
                    tension: 0.3,
                    borderWidth: 1,
                    borderColor: "rgb(38, 60, 122)",
                    backgroundColor: "rgb(38, 60, 122, 0.5)"
                }
            ],
            labels
        }
    }, [])

    
    const followersData = useMemo(function(){
        return {
            datasets: [
                {
                    label: t('followers'),
                    data: followersScores,
                    tension: 0.3,
                    borderWidth: 1,
                    borderColor: "rgb(38, 60, 122)",
                    backgroundColor: "rgb(38, 60, 122, 0.5)"
                }
            ],
            labels
        }
    }, [])

    
    const postsData = useMemo(function(){
        return {
            datasets: [
                {
                    label: t('posts'),
                    data: postsScores,
                    tension: 0.3,
                    borderWidth: 1,
                    borderColor: "rgb(38, 60, 122)",
                    backgroundColor: "rgb(38, 60, 122, 0.5)"
                }
            ],
            labels
        }
    }, [])

    
    const likesEmitidosData = useMemo(function(){
        return {
            datasets: [
                {
                    label: t('likesEmitted'),
                    data: likesEmitidosScores,
                    tension: 0.3,
                    borderWidth: 1,
                    borderColor: "rgb(38, 60, 122)",
                    backgroundColor: "rgb(38, 60, 122, 0.5)"
                }
            ],
            labels
        }
    }, [])


    const selectData = () => {
        let dataToShow;
        switch (comparativeDataSelected) {
            case 'posts': dataToShow = postsData
                break;
            case 'likes': dataToShow = likesEmitidosData
                break;
            case 'alcance': dataToShow = alcanceData
                break;
            case 'efectividad': dataToShow = efectividadData
                break;
            case 'following': dataToShow = followingData
                break;
            case 'followers': dataToShow = followersData
                break;
            default:
                break;
        }
        return dataToShow
    }

  return (
    <div className='chart-styles pt-2'>
        <h5 className='chart-title mb-0'>{ t('comparative') }</h5>
        <small className='chart-subtitle'>{ t('comparativeData') }</small>

        <div className='row row-cols-auto d-flex justify-content-center'>
            <div
                className={ comparativeDataSelected === 'posts' ? 'badge m-badge bg-mediumblue pointer mb-1' : 'badge m-badge bg-91 pointer mb-1' }
                onClick={ () => setComparativeDataSelected('posts') }>
                { t('posts') }
            </div>
            <div
                className={ comparativeDataSelected === 'likes' ? 'badge m-badge bg-mediumblue pointer mb-1' : 'badge m-badge bg-91 pointer mb-1' }
                onClick={ () => setComparativeDataSelected('likes') }>
                { t('likes') }
            </div>
            <div
                className={ comparativeDataSelected === 'followers' ? 'badge m-badge bg-mediumblue pointer mb-1' : 'badge m-badge bg-91 pointer mb-1' }
                onClick={ () => setComparativeDataSelected('followers') }>
                { t('followers') }
            </div>
            <div
                className={ comparativeDataSelected === 'following' ? 'badge m-badge bg-mediumblue pointer mb-1' : 'badge m-badge bg-91 pointer mb-1' }
                onClick={ () => setComparativeDataSelected('following') }>
                { t('following') }
            </div>
            <div
                className={ comparativeDataSelected === 'alcance' ? 'badge m-badge bg-mediumblue pointer mb-1' : 'badge m-badge bg-91 pointer mb-1' }
                onClick={ () => setComparativeDataSelected('alcance') }>
                { t('scope') }
            </div>
            <div
                className={ comparativeDataSelected === 'efectividad' ? 'badge m-badge bg-mediumblue pointer mb-1' : 'badge m-badge bg-91 pointer mb-1' }
                onClick={ () => setComparativeDataSelected('efectividad') }>
                { t('effective') }
            </div>
        </div>

        <Bar
            data={ selectData() }
            options={options}
        />
    </div>
  )
}

export default ComparativeAccountsbarChart