import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
)


const UniqueBarChart = () => {
    
    const { dataKeywordResult } = useSelector( state => state.twitter )
    const { t } = useTranslation()
    
    const positiveScores = [ dataKeywordResult.totalAnalysisAmounts.positive_amount ]
    const neutralScores = [ dataKeywordResult.totalAnalysisAmounts.neutral_amount ]
    const negativeScores = [ dataKeywordResult.totalAnalysisAmounts.negative_amount ]
    const labels = [ '' ]


    const data = useMemo(function(){
        return {
          datasets: [
            {
              label: t('positives'),
              data: positiveScores,
              backgroundColor: 'rgb(66, 171, 73, 0.7)',
              borderWidth: 2,
              borderColor: '#42ab49',
              hoverOffset: 4
            },
            {
              label: t('neutrals'),
              data: neutralScores,
              backgroundColor: 'rgb(239, 184, 16, 0.7)',
              borderWidth: 2,
              borderColor: '#efb810',
              hoverOffset: 4
            },
            {
              label: t('negatives'),
              data: negativeScores,
              backgroundColor: 'rgb(209, 5, 42, 0.7)',
              borderWidth: 2,
              borderColor: '#d1052a',
              hoverOffset: 1000
            }
          ],
            labels
        }
    }, [])

    
    const options = {
      indexAxis: 'y',
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true
        }
      },
      plugins: {
        legend: {
          position: 'top',
          labels: {
            generateLabels: () => {
              const myLabels = [t('positives'), t('neutrals'), t('negatives')]
              return myLabels.map(
                (label) => ({
                  text: label,
                  strokeStyle: label === t('negatives') ? '#d1052a' : (label === t('neutrals') ? '#efb810' : '#42ab49'),
                  fillStyle: label === t('negatives') ? 'rgb(209, 5, 42, 0.7)' : (label === t('neutrals') ? 'rgb(239, 184, 16, 0.7)' : 'rgb(66, 171, 73, 0.7)'),
                  hidden: false
              }))
            }
          }
        },
        title: {
          display: false
        },
      },
    }


  return (
    <div className='chart-styles'>
        <h5 className='chart-title mb-0'>{ t('totalSentiments') }</h5>
        <small className='chart-subtitle'>{ t('sentimentResults') }</small>

        <Bar data={data} options={options} />
    </div>
  )
}

export default UniqueBarChart