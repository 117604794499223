import React from 'react'
import ParentModal from './modals/ParentModal'
import Sidebar from './Sidebar'
import UserBoxInfo from './UserBoxInfo'
import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";
import Home from './pages/Home';
import TwitterScreen from './pages/twitter/TwitterScreen';
import NewsScreen from './pages/news/NewsScreen';
import InstagramScreen from './pages/instagram/InstagramScreen'
import TwitterAccountsResult from './pages/twitter/TwitterAccountsResult';
import SentimentResultTable from './tables/twitter/SentimentResultTable';
import InstagramSentimentCharts from './pages/instagram/InstagramSentimentCharts';
import InstagramSentimentTable from './tables/instagram/InstagramSentimentTable';
import InstagramCardsResults from './pages/instagram/InstagramCardsResults';
import History from './pages/History';
import { useSelector } from 'react-redux';
import AppValidations from './pages/AppValidations';
import UpdatePlanScreen from './pages/UpdatePlanScreen';
import AnalysisScreen from './pages/AnalysisScreen';
import UpgradePlanScreen from './pages/UpgradePlanScreen';
import TwitterSentimentCharts from './charts/twitter/sentiments/TwitterSentimentCharts';
import NewsSentimentCharts from './charts/news/sentiments/NewsSentimentCharts';
import NewsSentimentCards from './pages/news/NewsSentimentCards';
import TwitterCardsResults from './pages/twitter/TwitterCardsResults';

const AppRouter = () => {
  
  const { userValidated, updatePlanPage } = useSelector( state => state.user )
  
  
  if( updatePlanPage ){
    return <UpdatePlanScreen/>
  }

  if( !userValidated ){
    return <AppValidations/>
  }
  
  
  return (
    <BrowserRouter>
        <Sidebar />

        <Routes>
            <Route exact path='/' element={<Home />} />
            <Route path='analysis' element={ <AnalysisScreen /> } />
            <Route path='analysis/instagram' element={<InstagramScreen/>} >
              <Route path='table-sentiment' element={<InstagramSentimentTable />} />
              <Route path='charts-sentiment' element={<InstagramSentimentCharts />} />
              <Route path='cards-results' element={<InstagramCardsResults />} />
            </Route>
            <Route path='analysis/twitter' element={<TwitterScreen />} >
              <Route path='table-sentiment' element={<SentimentResultTable />} />
              <Route path='charts-sentiment' element={<TwitterSentimentCharts />} />
              <Route path='cards-results' element={<TwitterCardsResults />} />
              <Route path='charts-data' element={<TwitterAccountsResult />} />
            </Route>
            <Route path='analysis/news' element={<NewsScreen />} >
              <Route path='table-sentiment' element={<NewsSentimentCards />} />
              <Route path='charts-sentiment' element={<NewsSentimentCharts />} />
              <Route path='charts-data' element={<TwitterAccountsResult />} />
            </Route>
            <Route path='history' element={<History/>} />
            <Route path='upgrade-plan' element={<UpgradePlanScreen/>} />
        </Routes>

        <UserBoxInfo />
        <ParentModal />
    </BrowserRouter>
  )
}

export default AppRouter