import { Icon } from '@iconify/react';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { apiBaseUrl } from '../../helpers/fetch';
import { setUserState } from '../../store/slices/user/userSlice';


const UpdatePlanScreen = () => {

  const { email, subscriptionId, userData, userValidated } = useSelector( state => state.user )
  const dispatch = useDispatch();
  const { t } = useTranslation();
  localStorage.setItem('subscriptionId', subscriptionId)

  return (
      <div className='d-flex flex-column justify-content-center'>
        {
            !userValidated && (
                <div className="row w-100">
                    <div className='d-flex justify-content-end mt-2 p-0'>
                        <Icon
                            icon="akar-icons:arrow-back-thick-fill"
                            className='pointer text-lightblue icon-size15'
                            onClick={ () => dispatch( setUserState({ name: 'updatePlanPage', value: false }) ) }
                        />
                    </div>
                </div>
            )
        }

          <h3 className='text-center text-mediumblue'>
              { t('updatePlanTitle') }
          </h3>
          <p className='text-center subtitle mb-5'>
              { t('updatePlanSubtitle') }
          </p>
          <div className='container'>
              <div className="row d-flex justify-content-center">

                  <div className="col-3 shadow d-flex flex-column align-items-center plan-cards">
                      <p className='text-lightblue'>PERSONAL</p>
                      <i className='bx bxs-user text-lightblue'></i>
                      <h5 className='color-login'>
                        {
                            userData.countrycode === 'MX' ? '600.- MXN' : '30.- USD'
                        }
                      </h5>
                      <ul className='text-91'>
                          <li>{ t('personalPlanItem1') }</li>
                          <li>Twitter</li>
                          <li>Instagram</li>
                          <li>{ t('personalPlanItem2') }</li>
                      </ul>
                        {/* Form que acciona el link de pago | Oculto para el usuario */}
                        <form action={`${apiBaseUrl}/stripe/update-subscription`} method="POST" className='mt-auto'>
                            <input type="hidden" name="plan_selected" value='personal' />
                            <input type="hidden" name="user_country_code" value={ userData.countrycode } />
                            <input type="hidden" name="user_email" value={ email.toLowerCase() } />

                            <button
                                id='payment-button'
                                type="submit"
                                className="btn btn-lightblue btn-sm"
                            >
                                { t('select') }
                            </button>
                        </form>
                  </div>

                  <div className="col-3 shadow d-flex flex-column align-items-center plan-cards">
                      <p className='text-lightblue'>PRO</p>
                      <i className='bx bxs-bar-chart-alt-2 text-lightblue'></i>
                      <h5 className='color-login'>
                        {
                            userData.countrycode === 'MX' ? '1200.- MXN' : '60.- USD'
                        }
                      </h5>
                      <ul className='text-91'>
                          <li>{ t('proPlanItem1') }</li>
                          <li>Twitter</li>
                          <li>Instagram</li>
                          <li>Meta (Facebook)</li>
                          <li>{ t('proPlanItem2') }</li>
                      </ul>
                      
                        {/* Form que acciona el link de pago | Oculto para el usuario */}
                        <form action={`${apiBaseUrl}/stripe/update-subscription`} method="POST" className='mt-auto'>
                            <input type="hidden" name="plan_selected" value='pro' />
                            <input type="hidden" name="user_country_code" value={ userData.countrycode } />
                            <input type="hidden" name="user_email" value={ email.toLowerCase() } />

                            <button
                                id='payment-button'
                                type="submit"
                                className="btn btn-lightblue btn-sm"
                                disabled
                            >
                                { t('commingSoon') }
                            </button>
                        </form>
                  </div>

                  <div className="col-3 shadow d-flex flex-column align-items-center plan-cards">
                      <p className='text-lightblue'>BUSINESS</p>
                      <i className='bx bxs-briefcase text-lightblue'></i>
                      <h5 className='color-login'>
                        {
                            userData.countrycode === 'MX' ? '3000.- MXN' : '150.- USD'
                        }
                      </h5>
                      <ul className='text-91'>
                          <li>{ t('businessPlanItem1') }</li>
                          <li>Twitter</li>
                          <li>Instagram</li>
                          <li>Meta (Facebook)</li>
                          <li>LinkedIn</li>
                          <li>{ t('businessPlanItem2') }</li>
                      </ul>
                      
                        {/* Form que acciona el link de pago | Oculto para el usuario */}
                        <form action={`${apiBaseUrl}/stripe/update-subscription`} method="POST" className='mt-auto'>
                            <input type="hidden" name="plan_selected" value='business' />
                            <input type="hidden" name="user_country_code" value={ userData.countrycode } />
                            <input type="hidden" name="user_email" value={ email.toLowerCase() } />

                            <button
                                id='payment-button'
                                type="submit"
                                className="btn btn-lightblue btn-sm"
                                disabled
                            >
                                { t('commingSoon') }
                            </button>
                        </form>
                  </div>

                  <div className="col-3 shadow d-flex flex-column align-items-center plan-cards">
                      <p className='text-lightblue'>ENTERPRISE</p>
                      <i className='bx bxs-business text-lightblue'></i>
                      <h5 className='color-login'>{ t('contactUs') }</h5>
                      <ul className='text-91'>
                          <li>{ t('enterprisePlanItem1') }</li>
                          <li>Twitter</li>
                          <li>Instagram</li>
                          <li>Meta (Facebook)</li>
                          <li>LinkedIn</li>
                          <li>{ t('enterprisePlanItem2') }</li>
                          <li>{ t('enterprisePlanItem3') }</li>
                          <li>{ t('enterprisePlanItem4') }</li>
                          <li>{ t('enterprisePlanItem5') }</li>
                      </ul>

                      <a
                        href='https://global.seidoranalytics.com/harbingerenterprise'
                        target='_blank'
                        rel="noopener noreferrer"
                        className='btn btn-sm btn-lightblue mt-auto disabled'
                      >
                        { t('commingSoon') }
                      </a>
                  </div>

              </div>
          </div>
      </div>
  )
}

export default UpdatePlanScreen