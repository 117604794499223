import React from 'react'
import { useSelector } from 'react-redux';
import DefaultHomeContent from '../DefaultHomeContent';
import FavoritesAnalysisCards from './FavoritesAnalysisCards';

const Home = () => {
  
  const { favoritesAnalysis } = useSelector( state => state.history )

  return favoritesAnalysis.length === 0 ? (
    <DefaultHomeContent/>
  ) : (
    <FavoritesAnalysisCards/>
  )
}

export default Home