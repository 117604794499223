import { createSlice } from '@reduxjs/toolkit'
import { checkInstagramCredentialsExpiration } from '../../../helpers/settings';

const initialState = {
  globalSettings: {
    products: [],
    userPlanSettings: null,
    instagramCredentials: null
  },
}

export const settingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        setGlobalSettings: (state, {payload}) => {
          state.globalSettings.products = payload.globalSettings.products;
          const userPlanDetails = payload.globalSettings.products.filter( row => row.plan_name.toLowerCase() === payload.userPlan )
          state.globalSettings.userPlanSettings = userPlanDetails[0];
          state.globalSettings.instagramCredentials = payload.globalSettings.instagram_credentials
          
          checkInstagramCredentialsExpiration(payload.globalSettings)
        }
    },
})

// Action creators are generated for each case reducer function
export const { setGlobalSettings } = settingSlice.actions