import { fetchBackend } from "./fetch"



export const getHistoryByTenant = async(tenantid) => {
    const analysisResp = await fetchBackend(`historical-analysis/${tenantid}`, 'GET')
    const jsonAnalysisResp = await analysisResp.json()
    const historyData = await jsonAnalysisResp.historicalAnalysisResults
    const historyCount = await jsonAnalysisResp.historyCount
    const instagramPeriodCount = await jsonAnalysisResp.instagramPeriodCount
    const newsPeriodCount = await jsonAnalysisResp.newsPeriodCount
    const twitterPeriodCount = await jsonAnalysisResp.twitterPeriodCount
    const favoriteData = await historyData.filter( data => JSON.parse(data.isfavorite) )
    
    return { historyData, historyCount, favoriteData, instagramPeriodCount, newsPeriodCount, twitterPeriodCount }
}


export const saveResultAsFavoriteInDB = async(analysisId) => {
    const isFavorite = true
    const saveRequest = await fetchBackend('historical-analysis/save-favorite', {analysisId, isFavorite}, 'PUT')
    const saveResponse = await saveRequest.json()
    return saveResponse
}


export const deleteResultAsFavoriteInDB = async(analysisId) => {
    const isFavorite = false
    const saveRequest = await fetchBackend('historical-analysis/save-favorite', {analysisId, isFavorite}, 'PUT')
    const saveResponse = await saveRequest.json()
    return saveResponse
}