import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    checkingInformation: true,
    tenantid: null,
    twitterCount: null,
    instagramCount: null,
    email: null,
    emailVerified: false,
    plan: null,
    paid: false,
    tenantActive: false,
    userValidated: false,
    language: 'es',
    openModal: false,
    platformSelected: null,
    userEnabledToContinue: false,
    planDetails: null,
    differenceInDays: null,
    trialRemainingDays: null,
    updatePlanPage: false,
    subscriptionId: null,
    userData: null
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        openUserModal: (state) => {
            state.openModal = true;
        },
        closeUserModal: (state) => {
            state.openModal = false;
        },
        setUserState: (state, {payload}) => {
            state[payload.name] = payload.value;
        },
        setUserInformation: (state, {payload}) => {
            state.tenantid = payload.tenantInfo.tenantid
            state.email = payload.tenantInfo.email
            state.plan = payload.tenantInfo.plan
            state.paid = JSON.parse(payload.tenantInfo.paid)
            state.tenantActive = JSON.parse(payload.tenantInfo.active)
            state.twitterCount = payload.twitterRequests
            state.instagramCount = payload.instagramRequests
            state.userEnabledToContinue = payload.userEnabledToContinue
            state.planDetails = payload.planDetails
            state.differenceInDays = payload.differenceInDays
            state.subscriptionId = payload.tenantInfo.subscriptionid
            state.trialRemainingDays = 15 - payload.differenceInDays
            state.userData = payload.userInfo
        }
    },
})

// Action creators are generated for each case reducer function
export const { openUserModal, closeUserModal, setUserState, setUserInformation } = userSlice.actions