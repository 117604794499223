import { createSlice } from '@reduxjs/toolkit'
import { handleToggle } from '../../../helpers/sidebar'

const initialState = {
    toggleSidebar: false,
}

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        openSidebar: (state) => {
            state.toggleSidebar = true;
            handleToggle()
        },
        closeSidebar: (state) => {
            state.toggleSidebar = false;
            handleToggle()
        },
    },
})

// Action creators are generated for each case reducer function
export const { openSidebar, closeSidebar } = sidebarSlice.actions