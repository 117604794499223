// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag-theme-alpine {
    --ag-foreground-color: #000;
    --ag-background-color: #fff;
    --ag-header-foreground-color: #fff;
    --ag-header-background-color: #66b6ff;
    --ag-odd-row-background-color: #fff;
    --ag-header-column-resize-handle-color: #fff;
    --ag-font-size: 14px;
    --ag-font-family: 'Poppins';
    --ag-border-radius: 10px !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/table.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,2BAA2B;IAC3B,kCAAkC;IAClC,qCAAqC;IACrC,mCAAmC;IACnC,4CAA4C;IAC5C,oBAAoB;IACpB,2BAA2B;IAC3B,mCAAmC;AACvC","sourcesContent":[".ag-theme-alpine {\r\n    --ag-foreground-color: #000;\r\n    --ag-background-color: #fff;\r\n    --ag-header-foreground-color: #fff;\r\n    --ag-header-background-color: #66b6ff;\r\n    --ag-odd-row-background-color: #fff;\r\n    --ag-header-column-resize-handle-color: #fff;\r\n    --ag-font-size: 14px;\r\n    --ag-font-family: 'Poppins';\r\n    --ag-border-radius: 10px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
