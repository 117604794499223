import React from 'react'
import SelectPlatform from './pages/SelectPlatform'
import { useTranslation } from 'react-i18next';

const DefaultHomeContent = () => {

    const { t } = useTranslation();

  return (
    <div className="home">
        <h1 className='text-mediumblue ms-5 mt-4 mb-0'>{ t('welcomeToFeelings') }</h1>
        <p className='subtitle ms-5'>
          { t('dataSourceToBegin') }
        </p>

        <div className='margin-icons-home'>
          <SelectPlatform />
        </div>
    </div>
  )
}

export default DefaultHomeContent