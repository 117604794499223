import React from 'react'

const DateFormatter = (props) => {

    const tweetDateRaw = props.data.datecreated ? new Date(props.data.datecreated) : new Date(props.data.created_date_raw)
    const year = tweetDateRaw.getFullYear()
    const month = tweetDateRaw.getMonth().toString().length === 1 ? `0${tweetDateRaw.getMonth()+1}` : tweetDateRaw.getMonth()+1
    const day = tweetDateRaw.getDate().toString().length === 1 ? `0${tweetDateRaw.getDate()}` : tweetDateRaw.getDate()
    const hour = tweetDateRaw.getHours().toString().length === 1 ? `0${tweetDateRaw.getHours()}` : tweetDateRaw.getHours()
    const min = tweetDateRaw.getMinutes().toString().length === 1 ? `0${tweetDateRaw.getMinutes()}` : tweetDateRaw.getMinutes()
    const sec = tweetDateRaw.getSeconds().toString().length === 1 ? `0${tweetDateRaw.getSeconds()}` : tweetDateRaw.getSeconds()

    const tweetDate = `${day}-${month}-${year} ${hour}:${min}:${sec}`

  return (
    <small>{ tweetDate }</small>
  )
}

export default DateFormatter