import { createSlice } from '@reduxjs/toolkit'
import { processWordCount } from '../../../helpers/utils'

const initialState = {
    status: 'ready', // ready, processing
    failedRequest: false,
    dataKeywordResult: {
        data: null,
        totalAnalysisAmounts: null,
        totalAnalysisPercentages: null,
        polarityAmounts: null,
        wordCount: {
            showingWords : 'all',
            negativeWords : [],
            positiveWords: [],
            allWords: []
        }
    },
    dataAccountsResult: {
        data: [],
        evolution: [],
        lastReceivedLikes: null
    },
    twitterSearchParameters: {
        analysisId: null,
        resultsType: 'recent',
        requestKeyword: '',
        requestType: '',
        requestName: '',
        requestLanguage: '',
        requestAmount: 100,
        maxRequestAmount: null,
        requestSelectedAccount: '',
        requestDate: null,
        requestSocialmedia: 'twitter',
        requestSelectedAccountIndex: null,
        requestAccounts: [''],
        isFavorite: false
    }
}

export const twitterSlice = createSlice({
    name: 'twitter',
    initialState,
    reducers: {
        setParametersAndResultsFromHistory: (state, {payload}) => {
            if( payload.parameters.analysistype === 'keyword' ){

                state.twitterSearchParameters.requestKeyword = payload.parameters.keyword
                state.twitterSearchParameters.requestType = payload.parameters.analysistype
                state.twitterSearchParameters.requestName = payload.parameters.searchname
                state.twitterSearchParameters.requestLanguage = payload.parameters.language
                state.twitterSearchParameters.requestAmount = payload.parameters.amount
                state.twitterSearchParameters.requestDate = payload.requestDate
                state.twitterSearchParameters.resultsType = payload.parameters.resultstype
                state.twitterSearchParameters.isFavorite = payload.isFavorite
                state.twitterSearchParameters.analysisId = payload.analysisId
    
                state.dataKeywordResult.data = payload.results.sentimentAnalysisResult.analyzedTweets
                state.dataKeywordResult.totalAnalysisAmounts = payload.results.total_numbers.total_amounts
                state.dataKeywordResult.totalAnalysisPercentages = payload.results.total_numbers.total_percentages
                state.dataKeywordResult.polarityAmounts = payload.results.polarity_amounts
                state.dataKeywordResult.wordCount.showingWords = 'all'
                state.dataKeywordResult.wordCount.negativeWords = processWordCount(payload.results.sentimentAnalysisResult.wordCount).sortedNegativeWords
                state.dataKeywordResult.wordCount.positiveWords = processWordCount(payload.results.sentimentAnalysisResult.wordCount).sortedPositiveWords
                state.dataKeywordResult.wordCount.allWords = processWordCount(payload.results.sentimentAnalysisResult.wordCount).allWordsCount

            } else if( payload.parameters.analysistype === 'account' ){

                state.twitterSearchParameters.requestType = payload.parameters.analysistype
                state.twitterSearchParameters.requestName = payload.parameters.searchname
                state.twitterSearchParameters.requestSocialMedia = payload.parameters.socialmedia
                state.twitterSearchParameters.requestSelectedAccount = payload.parameters.accounts[payload.parameters.accountselected]
                state.twitterSearchParameters.requestSelectedAccountIndex = payload.parameters.accountselected
                state.twitterSearchParameters.requestAccounts = payload.parameters.accounts
                state.twitterSearchParameters.requestDate = payload.requestDate

                state.dataAccountsResult.data = payload.results.accountAnalysisResult
                state.dataAccountsResult.evolution = payload.results.evolution
                state.dataAccountsResult.lastReceivedLikes = payload.results.total_likes
            }
        },
        setTwitterSearchParametersFromHistory: (state, {payload}) => {
            state.twitterSearchParameters.requestSocialMedia = 'twitter';
            state.twitterSearchParameters.requestType = payload.analysistype;
            state.twitterSearchParameters.requestName = payload.searchname;
            state.twitterSearchParameters.requestSelectedAccountIndex = payload.accountselected;
            state.twitterSearchParameters.requestAccounts = payload.accounts;
        },
        setTwitterSearchParameters: (state, {payload}) => {
            state.twitterSearchParameters[payload.name] = payload.value;
        },
        setSearchAccounts: (state, {payload}) => {
            state.twitterSearchParameters[payload.name][payload.index] = payload.value;
        },
        addAccountInput: (state) => {
            state.twitterSearchParameters.requestAccounts = [...state.twitterSearchParameters.requestAccounts, '']
        },
        removeAccountInput: (state) => {
            state.twitterSearchParameters.requestAccounts = state.twitterSearchParameters.requestAccounts.slice(0, state.twitterSearchParameters.requestAccounts.length-1)
        },
        getTwitterAccountResult: (state, {payload}) => {
            state.status = 'ready';
            state.dataAccountsResult.data = payload.data;
            state.dataAccountsResult.evolution = payload.evolution.reverse();
            state.dataAccountsResult.lastReceivedLikes = payload.total_likes;
        },
        processingRequest: (state) => {
            state.status = 'processing';
        },
        finishRequest: (state) => {
            state.status = 'ready';
        },
        changeWordsToShowOnCloud: (state, {payload}) => {
            state.dataKeywordResult.wordCount.showingWords = payload.wordsType;
        },
        cleanTwitterSearchParams: (state) => {
            state.twitterSearchParameters.requestSelectedAccountIndex = null;
            state.twitterSearchParameters.resultsType = 'recent';
            state.dataAccountsResult.data = [];
            state.dataAccountsResult.evolution = [];
            state.dataKeywordResult.data = null;
            state.dataKeywordResult.totalAnalysisAmounts = null;
            state.dataKeywordResult.totalAnalysisPercentages = null;
        },
        cleanAccountSearchParams: (state) => {
            state.twitterSearchParameters.requestSelectedAccountIndex = null;
            state.twitterSearchParameters.requestAccounts = [''];
            state.twitterSearchParameters.requestSelectedAccount = '';
        },
        cleanKeywordSearchParams: (state) => {
            state.twitterSearchParameters.requestKeyword = '';
            state.twitterSearchParameters.requestLanguage = '';
            state.twitterSearchParameters.requestAmount = '';
        },
        getTwitterAnalysisResult: (state, {payload}) => {
            state.status = 'ready';
            state.dataKeywordResult.data = payload.data;
            state.dataKeywordResult.totalAnalysisAmounts = payload.total_analysis_amounts.total_amounts;
            state.dataKeywordResult.totalAnalysisPercentages = payload.total_analysis_amounts.total_percentages;
            state.dataKeywordResult.polarityAmounts = payload.polarity_amounts;
            state.dataKeywordResult.wordCount.negativeWords = processWordCount(payload.wordCount).sortedNegativeWords;
            state.dataKeywordResult.wordCount.positiveWords = processWordCount(payload.wordCount).sortedPositiveWords;
            state.dataKeywordResult.wordCount.allWords = processWordCount(payload.wordCount).allWordsCount
        },
        requestReady: (state) => {
            state.status = 'ready';
            state.failedRequest = true
        }
    },
})

// Action creators are generated for each case reducer function
export const { setTwitterSearchParameters, getTwitterAnalysisResult, getTwitterAccountResult, processingRequest, addAccountInput, removeAccountInput, setSearchAccounts, cleanTwitterSearchParams, changeWordsToShowOnCloud, cleanAccountSearchParams, cleanKeywordSearchParams, setParametersAndResultsFromHistory, setTwitterSearchParametersFromHistory, requestReady, finishRequest } = twitterSlice.actions