import React from 'react'
import { Icon } from '@iconify/react';
import {Link} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import instagramLogo from '../img/instagram-logo.png';
import { setUserState } from '../store/slices/user/userSlice';
import '../styles/icons.css'
import { useEffect } from 'react';
import NewsIcon from './NewsIcon';


const Navbar = () => {

  const { platformSelected } = useSelector( state => state.user )
  const dispatch = useDispatch();

  useEffect(() => {
    if( window.location.pathname.includes('analysis/twitter') ){
      dispatch( setUserState({ name: 'platformSelected', value: 'twitter' }))
    } else if(window.location.pathname.includes('analysis/instagram')){
      dispatch( setUserState({ name: 'platformSelected', value: 'instagram' }))
    } else if(window.location.pathname.includes('analysis/news')){
      dispatch( setUserState({ name: 'platformSelected', value: 'news' }))
    }
  }, [])
  

  return (
    <nav className="navbar navbar-light">
        <div className="container-fluid justify-content-start ms-4">
          {/* <Link to='/analysis/twitter' className='ms-4' onClick={ () => dispatch( setUserState({ name: 'platformSelected', value: 'twitter' }) ) }>
            {
              platformSelected === 'twitter' ? (
                <Icon icon="logos:twitter" className='pointer icon-size2' />
              ) : (
                <Icon icon="akar-icons:twitter-fill" className='pointer text-darkgray icon-size25' />
              )
            }
          </Link> */}
          <Link to='/analysis/instagram' className='' onClick={ () => dispatch( setUserState({ name: 'platformSelected', value: 'instagram' }) ) }>
            {
              platformSelected === 'instagram' ? (
                <div className='ig-size-nav'>
                    <img src={instagramLogo} alt='Instagram' className='pointer' />
                </div>
              ) : (
                <Icon icon="akar-icons:instagram-fill" className='pointer text-darkgray icon-size22' />
              )
            }
          </Link>
          <Link to='/analysis/news' className='text-decoration-none ms-5' onClick={ () => dispatch( setUserState({ name: 'platformSelected', value: 'news' }) ) }>
            {
              platformSelected === 'news' ? (
                <NewsIcon settings={{ location: 'navbar', mainClass: 'news-myicon-nav' }} />
              ) : (
                <NewsIcon settings={{ location: 'navbar', mainClass: 'news-myicon-nav-darkgrey' }} />
              )
            }
          </Link>

          <Icon icon="akar-icons:twitter-fill" className='pointer text-darkgray icon-size25 ms-5' />
          <Icon icon="akar-icons:facebook-fill" className='pointer text-darkgray icon-size2 ms-5' />
          <Icon icon="akar-icons:linkedin-box-fill" className='pointer text-darkgray icon-size2 ms-5' />
          <Icon icon="akar-icons:whatsapp-fill" className='pointer text-darkgray icon-size2 ms-5' />
          <Icon icon="akar-icons:telegram-fill" className='pointer text-darkgray icon-size2 ms-5' />
          <Icon icon="ant-design:folder-open-twotone" className='pointer text-darkgray icon-size25 ms-5' />
          <Icon icon="dashicons:database" className='pointer text-darkgray icon-size25 ms-5' />
        </div>
    </nav>
  )
}

export default Navbar