import React, { useMemo, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
)

const options = {
    responsive: true,
    fill: true,
    plugins: {
      legend: false,
      title: {
        display: false
      },
    },
}

const LikesEvolutionBarChart = () => {
  
    const { dataAccountsResult } = useSelector( state => state.twitter )
    const { t } = useTranslation()

    const dateRawArray = dataAccountsResult.evolution.map( row => ({ date: row.created_at, likes: row.likes }) )
    const [linesToShow, setLinesToShow] = useState('months')



    const getAllMinutes = () => {
      const arrayAllData = dateRawArray.map( row => {
        const myDate = new Date(row.date)
        const month = `${myDate.getMonth()+1}-${myDate.getFullYear()}`
        const date = `${myDate.getDate()}-${myDate.getMonth()+1}-${myDate.getFullYear()}`
        const hour = myDate.getHours().toString().length === 1 ? `${date} 0${myDate.getHours()}` : `${date} ${myDate.getHours()}`
        const likes = row.likes
        return { hour: `${hour}`, date, month, likes }
      }).reverse()
      
      
      let likesPerDate = []
      arrayAllData.forEach(function (a) {
        if (!this[a.date]) {
            this[a.date] = { date: a.date, likes: 0 };
            likesPerDate.push(this[a.date]);
        }
        this[a.date].likes += a.likes;
      }, []);
      
      let likesPerMonth = []
      arrayAllData.forEach(function (a) {
        if (!this[a.month]) {
            this[a.month] = { month: a.month, likes: 0 };
            likesPerMonth.push(this[a.month]);
        }
        this[a.month].likes += a.likes;
      }, []);

      return { likesPerDate, likesPerMonth }
    }
    
    
    const likesPerDayScores = getAllMinutes().likesPerDate.map( row => row.likes )
    const likesPerDaylabels = getAllMinutes().likesPerDate.map( row => row.date )
    
    const likesPerMonthScores = getAllMinutes().likesPerMonth.map( row => row.likes )
    const likesPerMonthlabels = getAllMinutes().likesPerMonth.map( row => row.month )

    
    const likesPerDayData = useMemo(function(){
      return {
          datasets: [
            {
              label: "Likes",
              data: likesPerDayScores,
              borderWidth: 0.7,
              pointRadius: 1,
              borderColor: "rgb(38, 60, 122)",
              backgroundColor: "rgb(38, 60, 122, 0.5)"
          }
        ],
          labels: likesPerDaylabels
      }
    }, [])
  
    const likesPerMonthData = useMemo(function(){
      return {
          datasets: [
            {
              label: "Likes",
              data: likesPerMonthScores,
              borderWidth: 0.7,
              borderColor: "rgb(38, 60, 122)",
              backgroundColor: "rgb(38, 60, 122, 0.5)"
          }
        ],
          labels: likesPerMonthlabels
      }
    }, [])


  return (
    <div className='chart-styles pt-2'>
      <h5 className='chart-title mb-0'>{ t('likes') }</h5>
      <small className='chart-subtitle'>{ t('likesEvolution') }</small>
      
      <div className='d-flex justify-content-center'>
          <div
            className={ linesToShow === 'days' ? 'badge m-badge bg-mediumblue pointer' : 'badge m-badge bg-91 pointer' }
            onClick={ () => setLinesToShow('days') }
          >{ t('day') }</div>
          <div
            className={ linesToShow === 'months' ? 'badge m-badge bg-mediumblue pointer' : 'badge m-badge bg-91 pointer' }
            onClick={ () => setLinesToShow('months') }
          >{ t('month') }</div>
      </div>
        <Bar
          data={ linesToShow === 'days' ? likesPerDayData : likesPerMonthData }
          options={options}
        />
    </div>
  )
}

export default LikesEvolutionBarChart