import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InstagramIcon from '@mui/icons-material/Instagram';
// import MediaCarousel from '../../MediaCarousel';
import twitterVerified from '../../../img/twitter_verified.png'
import { nfObject } from '../../../helpers/utils';
import { useTranslation } from 'react-i18next';
import '../../../styles/twitter-card.css'
import { pink } from '@mui/material/colors';

// let videoData;

const InstagramCard = ({igData, mediaIndex}) => {
  
  const formatDate = new Date(igData.created_date_raw).toLocaleString()
  console.log(igData)
  const { t } = useTranslation()

  // if(tweetData.media && (tweetData.media.media[0].type === 'video')){
  //   videoData = tweetData.media.media[0].video_info.variants.filter( videoData => videoData.bitrate )
  //   videoData.sort( (a,b) => b.bitrate - a.bitrate )
  // }

  return (
    // <h1>Hola soy una Card amigable</h1>
    <Card className='twitter-card-shadow' sx={{ maxWidth: 345 }}>
      <CardHeader
        avatar={
          <Avatar src='https://i0.wp.com/www.abaudblog.it/wp-content/uploads/2021/04/instagram-logo-e1619792460913.png?fit=500%2C500&ssl=1' aria-label="recipe"></Avatar>
        }
        title={
            <div className='d-flex align-items-center'>
              <a className='d-flex align-items-center text-decoration-none text-dark' href={`https://twitter.com/`} target="_blank" rel="noopener noreferrer">
                <strong>User</strong>
              </a>
              {
                true && (
                  <img className='ms-1' src={twitterVerified} width={20} alt="Verified" />
                )
              }
            </div>
        }
        subheader={
          <div className='d-flex align-items-center'>
            <a className='d-flex align-items-center text-decoration-none' href={`https://twitter.com/${'home'}`} target="_blank" rel="noopener noreferrer">
              <small style={{ color: '#536471' }}>@user_account</small>
            </a>
            <strong className='mx-1'>·</strong>
            <small style={{ color: '#536471' }}>{nfObject.format(999999)} {t('followers')}</small>
          </div>
        }
      />
      
      {/* Text content */}
      <CardContent className='py-0'>
        <Typography variant="body2" color="text.secondary">
          { igData.caption }
        </Typography>
      </CardContent>
      
      {/* Media content */}
      {
        igData.media_type === 'IMAGE' &&
        <CardMedia
          className='mt-3'
          component="img"
          height="400"
          image={ igData.media_url }
        />
      }
      {/* {
        tweetData.media && (tweetData.media.media.length > 1) && tweetData.media.media[0].type === 'photo' && (
          <MediaCarousel media={tweetData.media.media} key={mediaIndex} mediaIndex={mediaIndex} />
        )
      } */}
      {
        igData.media_type === 'VIDEO' &&
          <CardMedia
            className='mt-3'
            component="video"
            height="469"
            src={igData.media_url}
            autoPlay
            muted
            controls
          />
      }

      {/* Data and icons */}
      <div className='d-flex justify-content-between align-items-center px-3 mt-3'>
        <span className={`tweet-tag tweet-tag-${igData.caption_analysis.sentiment.toLowerCase()}`}>
            { igData.caption_analysis.sentiment }
        </span>

        <small style={{ color: '#536471', fontSize: '12px' }}>{formatDate}</small>
      </div>
      <hr className='mt-2 mb-1 mx-3'/>

      <CardActions className='d-flex justify-content-between pt-0' disableSpacing>
        <div>
          <IconButton disableRipple aria-label="favorites">
            <small className='card-likes'>{igData.like_count} {t('likes')}</small>
          </IconButton>
          <strong style={{ color: '#536471' }} className='mx-1'>·</strong>

          <IconButton disableRipple aria-label="favorites">
            <small className='card-likes'>{igData.comments_count} Comentarios</small>
          </IconButton>
        </div>

        <IconButton className='text-decoration-none' aria-label="share" href={igData.permalink} target='_blank'>
            <InstagramIcon sx={{ color: pink[500] }} />
        </IconButton>
      </CardActions>
    </Card>
  );
}

export default InstagramCard