import React from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactWordcloud from 'react-wordcloud';
import { changeWordsToShowOnCloud } from '../../../../store/slices/news/newsSlice';



const options = {
    colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
    enableTooltip: true,
    deterministic: true,
    fontFamily: "Poppins",
    fontSizes: [12, 60],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 0,
    rotations: 3,
    rotationAngles: [0, 90],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000
};
const size = [400, 400];


const WordCloud = () => {
  
  const { dataKeywordResult } = useSelector( state => state.news )
  let firstAllWords = []
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [wordSelected, setWordSelected] = useState({ text: t('selectWord'), value: '-' })
  
  const callbacks = {
    // getWordColor: word => word.value > 25 ? "blue" : "red",
    onWordClick: word => setWordSelected({ text: word.text, value: word.value}),
    // onWordMouseOver: console.log,
    // getWordTooltip: word => `${word.text} (${word.value}) [${word.value > 50 ? "good" : "bad"}]`,
  }
  
  const getFirstAllWords = () => {
    let result = []
    for(let i in dataKeywordResult.wordCount.allWords){
      if( i < 100 ){
          firstAllWords.push(dataKeywordResult.wordCount.allWords[i])
      }
    }

    firstAllWords.forEach(function (a) {
      if (!this[a.text]) {
          this[a.text] = { text: a.text, value: 0 };
          result.push(this[a.text]);
      }
      this[a.text].value += a.value;
    }, []);
    firstAllWords = result
  }
  getFirstAllWords()

  const getFirstSentimentWords = () => {
    let firstPositiveWords = []
    let firstNegativeWords = []
    
    for(let i in dataKeywordResult.wordCount.negativeWords){
      if( i < 100 ){
        firstNegativeWords.push(dataKeywordResult.wordCount.negativeWords[i])
      }
    }
    
    for(let i in dataKeywordResult.wordCount.positiveWords){
      if( i < 100 ){
        firstPositiveWords.push(dataKeywordResult.wordCount.positiveWords[i])
      }
    }

    console.log(firstNegativeWords)
    console.log(firstPositiveWords)
    return { firstNegativeWords, firstPositiveWords }
  }
  

  const handleChangeWordsToShow = (wordsType) => {
    dispatch( changeWordsToShowOnCloud({ wordsType }) )
  }



  return (
    <div className='wordcloud-styles p-0 pt-2'>
        <div className='mb-2'>
            <div
              className='badge m-badge bg-badgegreen pointer'
              onClick={ () => handleChangeWordsToShow('positive') }
            >{ t('positives') }</div>
            <div
              className='badge m-badge bg-badgered pointer'
              onClick={ () => handleChangeWordsToShow('negative') }
            >{ t('negatives') }</div>
            <div
              className='badge m-badge bg-badgelightblue pointer'
              onClick={ () => handleChangeWordsToShow('all') }
            >{ t('all') }</div>
        </div>
        <h5 className='text-center text-lightblue'>{ wordSelected.text } ({ wordSelected.value })</h5>

        <ReactWordcloud
            callbacks={callbacks}
            options={options}
            size={size}
            words={ dataKeywordResult.wordCount.showingWords === 'positive' ? getFirstSentimentWords().firstPositiveWords : ( dataKeywordResult.wordCount.showingWords === 'negative' ? getFirstSentimentWords().firstNegativeWords : firstAllWords ) }
        />
    </div>
  )
}

export default WordCloud