import { configureStore } from '@reduxjs/toolkit'
import { authSlice } from './auth/authSlice'
import { historySlice } from './slices/history/historySlice'
import { instagramSlice } from './slices/instagram/instagramSlice'
import { modalSlice } from './slices/modal/modalSlice'
import { planSlice } from './slices/plans/planSlice'
import { registerSlice } from './slices/register/registerSlice'
import { settingSlice } from './slices/settings/settingSlice'
import { sidebarSlice } from './slices/sidebar/sidebarSlice'
import { twitterSlice } from './slices/twitter/twitterSlice'
import { userSlice } from './slices/user/userSlice'
import { newsSlice } from './slices/news/newsSlice'

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    user: userSlice.reducer,
    sidebar: sidebarSlice.reducer,
    modal: modalSlice.reducer,
    twitter: twitterSlice.reducer,
    news: newsSlice.reducer,
    plan: planSlice.reducer,
    register: registerSlice.reducer,
    history: historySlice.reducer,
    instagram: instagramSlice.reducer,
    settings: settingSlice.reducer
  },
})