import { getNewsAndAnalysis } from "../../../helpers/news"
import { showErrorToast } from "../../../helpers/utils"
import { startGettingHistoricAnalysis } from "../history/thunks"
import { getNewsAnalysisResult, processingRequest, requestReady, setNewsSearchParameters } from "./newsSlice"


export const startGettingNewsAnalysis = (body) => {
    return async( dispatch ) => {
        dispatch( processingRequest() )
        const newsResponse = await getNewsAndAnalysis(body)

        if(newsResponse.ok){
            dispatch( getNewsAnalysisResult(newsResponse) )
            dispatch( setNewsSearchParameters({ name: 'analysisId', value: newsResponse.analysisid }) )
            dispatch( startGettingHistoricAnalysis(body.tenant) )
        } else {
            showErrorToast('No se encontraron artículos con la keyword ingresada')
        }
        
        dispatch( requestReady() )
    }
}
