import { createSlice } from '@reduxjs/toolkit'
import { processWordCount } from '../../../helpers/utils'

const initialState = {
    status: 'ready', // ready, processing
    failedRequest: false,
    dataKeywordResult: {
        data: null,
        dataProviders: null,
        totalAnalysisAmounts: null,
        totalAnalysisPercentages: null,
        polarityAmounts: null,
        wordCount: {
            showingWords : 'all',
            negativeWords : [],
            positiveWords: [],
            allWords: []
        }
    },
    dataAccountsResult: {
        data: [],
        evolution: [],
        lastReceivedLikes: null
    },
    newsSearchParameters: {
        analysisId: null,
        resultsType: 'Day',
        requestKeyword: '',
        requestType: '',
        requestName: '',
        requestLanguage: '',
        requestAmount: 20,
        maxRequestAmount: null,
        requestSelectedAccount: '',
        requestDate: null,
        requestSocialmedia: 'news',
        isFavorite: false
    }
}

export const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        setNewsParametersAndResultsFromHistory: (state, {payload}) => {
            if( payload.parameters.analysistype === 'keyword' ){

                state.newsSearchParameters.requestKeyword = payload.parameters.keyword
                state.newsSearchParameters.requestType = payload.parameters.analysistype
                state.newsSearchParameters.requestName = payload.parameters.searchname
                state.newsSearchParameters.requestLanguage = payload.parameters.language
                state.newsSearchParameters.requestAmount = payload.parameters.amount
                state.newsSearchParameters.requestDate = payload.requestDate
                state.newsSearchParameters.resultsType = payload.parameters.resultstype
                state.newsSearchParameters.isFavorite = payload.isFavorite
                state.newsSearchParameters.analysisId = payload.analysisId
    
                state.dataKeywordResult.data = payload.results.searchResults.analyzedNews
                state.dataKeywordResult.totalAnalysisAmounts = payload.results.total_numbers.total_amounts
                state.dataKeywordResult.totalAnalysisPercentages = payload.results.total_numbers.total_percentages
                state.dataKeywordResult.polarityAmounts = payload.results.polarity_amounts
                state.dataKeywordResult.wordCount.showingWords = 'all'
                state.dataKeywordResult.wordCount.negativeWords = processWordCount(payload.results.searchResults.wordCount[0]).sortedNegativeWords
                state.dataKeywordResult.wordCount.positiveWords = processWordCount(payload.results.searchResults.wordCount[0]).sortedPositiveWords
                state.dataKeywordResult.wordCount.allWords = processWordCount(payload.results.searchResults.wordCount[0]).allWordsCount
                state.dataKeywordResult.dataProviders = payload.results.searchResults.newsProviders

            } else if( payload.parameters.analysistype === 'account' ){

                state.newsSearchParameters.requestType = payload.parameters.analysistype
                state.newsSearchParameters.requestName = payload.parameters.searchname
                state.newsSearchParameters.requestSelectedAccount = payload.parameters.accounts[payload.parameters.accountselected]
                state.newsSearchParameters.requestDate = payload.requestDate

                state.dataAccountsResult.data = payload.results.accountAnalysisResult
                state.dataAccountsResult.evolution = payload.results.evolution
                state.dataAccountsResult.lastReceivedLikes = payload.results.total_likes
            }
        },
        setNewsSearchParametersFromHistory: (state, {payload}) => {
            state.newsSearchParameters.requestType = payload.analysistype;
            state.newsSearchParameters.requestName = payload.searchname;
        },
        setNewsSearchParameters: (state, {payload}) => {
            state.newsSearchParameters[payload.name] = payload.value;
        },
        setSearchAccounts: (state, {payload}) => {
            state.newsSearchParameters[payload.name][payload.index] = payload.value;
        },
        getNewsAccountResult: (state, {payload}) => {
            state.status = 'ready';
            state.dataAccountsResult.data = payload.data;
            state.dataAccountsResult.evolution = payload.evolution.reverse();
            state.dataAccountsResult.lastReceivedLikes = payload.total_likes;
        },
        processingRequest: (state) => {
            state.status = 'processing';
        },
        finishRequest: (state) => {
            state.status = 'ready';
        },
        changeWordsToShowOnCloud: (state, {payload}) => {
            state.dataKeywordResult.wordCount.showingWords = payload.wordsType;
        },
        cleanNewsSearchParams: (state) => {
            state.newsSearchParameters.resultsType = 'Day';
            state.newsSearchParameters.analysisId = null;
            state.newsSearchParameters.requestAmount = 20;
            state.newsSearchParameters.isFavorite = false;
            state.dataKeywordResult.data = null;
            state.dataKeywordResult.totalAnalysisAmounts = null;
            state.dataKeywordResult.totalAnalysisPercentages = null;
        },
        cleanNewsKeywordSearchParams: (state) => {
            state.newsSearchParameters.requestKeyword = '';
            state.newsSearchParameters.requestLanguage = '';
            state.newsSearchParameters.requestAmount = '';
        },
        getNewsAnalysisResult: (state, {payload}) => {
            state.status = 'ready';
            state.dataKeywordResult.data = payload.data;
            state.dataKeywordResult.dataProviders = payload.newsProviders;
            state.dataKeywordResult.totalAnalysisAmounts = payload.total_analysis_amounts.total_amounts;
            state.dataKeywordResult.totalAnalysisPercentages = payload.total_analysis_amounts.total_percentages;
            state.dataKeywordResult.polarityAmounts = payload.polarity_amounts;
            state.dataKeywordResult.wordCount.negativeWords = processWordCount(payload.wordCount[0]).sortedNegativeWords;
            state.dataKeywordResult.wordCount.positiveWords = processWordCount(payload.wordCount[0]).sortedPositiveWords;
            state.dataKeywordResult.wordCount.allWords = processWordCount(payload.wordCount[0]).allWordsCount
        },
        requestReady: (state) => {
            state.status = 'ready';
            state.failedRequest = true
        }
    },
})

// Action creators are generated for each case reducer function
export const { setNewsSearchParameters, getNewsAnalysisResult, getNewsAccountResult, processingRequest, setSearchAccounts, cleanNewsSearchParams, changeWordsToShowOnCloud, cleanNewsKeywordSearchParams, setNewsParametersAndResultsFromHistory, setNewsSearchParametersFromHistory, requestReady, finishRequest } = newsSlice.actions