import { getUserInformationFromDatabase } from "../../../helpers/user"
import { finishProcessingRequestHistory, getTenantHistory } from "../history/historySlice"
import { setGlobalSettings } from "../settings/settingSlice"
import { setUserInformation, setUserState } from "./userSlice"


export const startGettingUserInformation = (tenantid) => {
    return async( dispatch ) => {
        dispatch( setUserState({ name: 'checkingInformation', value: true }) )
        const { tenantInfo, userInfo, twitterRequests, instagramRequests, historyData, historyCount, twitterPeriodCount, instagramPeriodCount, newsPeriodCount, globalSettings, userEnabledToContinue, planDetails, differenceInDays, favoriteData } = await getUserInformationFromDatabase(tenantid)
        dispatch( getTenantHistory({ historyData, historyCount, favoriteData, twitterPeriodCount, instagramPeriodCount, newsPeriodCount }) )
        dispatch( setGlobalSettings({ 'globalSettings': globalSettings, 'userPlan': tenantInfo.plan }) )
        dispatch( setUserInformation({ tenantInfo, userInfo, twitterRequests, instagramRequests, userEnabledToContinue, planDetails, differenceInDays }) )
        dispatch( setUserState({ name: 'checkingInformation', value: false }) )
        dispatch( finishProcessingRequestHistory() )
    }
}