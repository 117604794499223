import React from 'react'
import { useSelector } from 'react-redux';
import { Outlet } from "react-router-dom";
import SentimentDataIconsInstagram from '../../charts/instagram/sentiments/SentimentDataIconsInstagram';
import AccountDataIcons from '../../charts/twitter/accounts/AccountDataIcons';
import InstagramSearchParameters from './InstagramSearchParameters';
import NavBarResults from '../../NavBarResults';



const ResultScreen = () => {
  
  const { instagramSearchParameters } = useSelector( state => state.instagram )
  
  return (
    <div className='container-fluid pe-0 bg-lightgray'>
      <NavBarResults socialMedia={'instagram'} />
      {
        (instagramSearchParameters.requestType === 'hashtag' || instagramSearchParameters.requestType === 'keyword') && (
          <div className='row d-flex justify-content-center align-items-start w-100 py-2 my-4 mx-0'>
            <div className="col-4 d-flex justify-content-center">
              <InstagramSearchParameters />
            </div>
            <div className="col d-flex justify-content-center align-items-center">
              <SentimentDataIconsInstagram />
            </div>
          </div>
        )
      }

      {
        (instagramSearchParameters.requestType === 'account') && (
          <div className='row d-flex justify-content-center align-items-start w-100 py-2 my-4'>
            <div className="col-4 d-flex justify-content-center">
              <InstagramSearchParameters />
            </div>
            <div className="col-8 d-flex justify-content-center align-items-center">
              <AccountDataIcons />
            </div>
          </div>
        )
      }

      <Outlet />
    </div>
  )
}

export default ResultScreen