import { getHastagResults } from "../../../helpers/instagram"
import { startGettingHistoricAnalysis } from "../history/thunks"
import { finishInstagramRequest } from "./instagramSlice"
import { getHashtagData, processingInstagramRequest, setInstagramSearchParameters } from "./instagramSlice"
import { showErrorToast } from "../../../helpers/utils"


export const startGettingHashtagAnalysis = (body) => {
    return async( dispatch ) => {
        dispatch( processingInstagramRequest(body) )
        const respJson = await getHastagResults(body)

        if( respJson.ok ){
            dispatch( getHashtagData(respJson) )
            dispatch( setInstagramSearchParameters({ name: 'analysisId', value: respJson.analysisid }) )
            dispatch( startGettingHistoricAnalysis(body.tenant) )
        } else {
            dispatch( finishInstagramRequest() )
            showErrorToast('No se encontraron publicaciones con el hashtag ingresado')
        }
    }
}
