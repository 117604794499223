import React from 'react'
import UpdatePlanScreen from './UpdatePlanScreen'

const UpgradePlanScreen = () => {
  return (
    <div className='home pt-3'>
        <UpdatePlanScreen />
    </div>
  )
}

export default UpgradePlanScreen