import React from 'react';
import { Icon } from '@iconify/react';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { useDispatch } from 'react-redux';
import { setUserState } from '../../store/slices/user/userSlice';
import instagramLogo from '../../img/instagram-logo.png';
import 'animate.css';
import { useTranslation } from 'react-i18next';
import NewsIcon from '../NewsIcon';


const SelectPlatform = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <div className="container">
            <div className="row row-cols-auto d-flex justify-content-around align-items-center space-between-icons">
                <div className="col-3 d-flex justify-content-center">
                    <div className='ig-size'>
                        <Link to='/analysis/instagram' onClick={ () => dispatch( setUserState({ name: 'selectPlatform', value: 'instagram' }) ) }>
                            <img src={instagramLogo} alt='Instagram' className='pointer' />
                        </Link>
                    </div>
                </div>
                <div className="col-3 d-flex justify-content-center">
                    <Link to='/analysis/news' className='text-decoration-none' onClick={ () => dispatch( setUserState({ name: 'selectPlatform', value: 'news' }) ) }>
                        <NewsIcon settings={{ location: 'selectplarfotm', mainClass: 'any' }} />
                    </Link>
                </div>
                <div className="col-3 d-flex justify-content-center">
                    {/* <Link to='/analysis/twitter' onClick={ () => dispatch( setUserState({ name: 'selectPlatform', value: 'twitter' }) ) }>
                        <Icon icon="logos:twitter" className='pointer text-dark icon-size35' />
                    </Link> */}
                    <div
                        className="col-3 d-flex justify-content-center" 
                        data-tip={ t('notAvailableForTrial') }
                        data-for='twitterTooltip'
                    >
                        <Icon
                            icon="cib:twitter"
                            className='pointer text-secondary icon-size4'
                        />
                        <ReactTooltip
                            id='twitterTooltip'
                            type='dark'
                            effect='solid'
                        />
                    </div>
                </div>
                <div
                    className="col-3 d-flex justify-content-center" 
                    data-tip={ t('availableFromPro') }
                    data-for='facebookTooltip'
                >
                    <Icon
                        icon="akar-icons:facebook-fill"
                        className='pointer text-secondary icon-size4'
                    />
                    <ReactTooltip
                        id='facebookTooltip'
                        type='dark'
                        effect='solid'
                    />
                </div>
                {/* <Icon icon="logos:facebook" className='pointer text-dark icon-size4' />
                <Icon icon="logos:linkedin-icon" className='pointer text-dark icon-size35' /> */}
            </div>
            
            <div className="row row-cols-auto d-flex justify-content-around align-items-center space-between-icons">
                <div
                    className="col-3 d-flex justify-content-center"
                    data-tip={ t('availableFromBusiness') }
                    data-for='linkedinTooltip'
                >
                    <Icon
                        icon="cib:linkedin"
                        className='pointer text-secondary icon-size35'
                    />
                    <ReactTooltip
                        id='linkedinTooltip'
                        type='dark'
                        effect='solid'
                    />
                </div>
                <div
                    className="col-3 d-flex justify-content-center"
                    data-tip={ t('availableFromEnterprise') }
                    data-for='whatsappTooltip'
                >
                    <Icon
                        icon="dashicons:whatsapp"
                        className='pointer text-secondary icon-size4'
                    />
                    <ReactTooltip
                        id='whatsappTooltip'
                        type='dark'
                        effect='solid'
                    />
                </div>
                <div
                    className="col-3 d-flex justify-content-center"
                    data-tip={ t('availableFromEnterprise') }
                    data-for='youtubeTooltip'
                >
                    <Icon
                        icon="fa:youtube-play"
                        className='pointer text-secondary icon-size32'
                    />
                    <ReactTooltip
                        id='youtubeTooltip'
                        type='dark'
                        effect='solid'
                    />                        
                </div>
                <div
                    className="col-3 d-flex justify-content-center"
                    data-tip={ t('availableFromEnterprise') }
                    data-for='filesTooltip'
                >
                    <Icon
                        icon="fluent:folder-open-24-filled"
                        className='pointer text-secondary icon-size45'
                    />
                    <ReactTooltip
                        id='filesTooltip'
                        type='dark'
                        effect='solid'
                    />                        
                </div>
                {/* <Icon icon="logos:whatsapp-icon" className='pointer text-dark icon-size4' />
                <Icon icon="logos:youtube-icon" className='pointer text-dark icon-size32' />
                <Icon icon="flat-color-icons:opened-folder" className='pointer text-dark icon-size45' />
                <Icon icon="dashicons:database" className='pointer text-darkyellow icon-size45' /> */}
            </div>

            
            <div className="row row-cols-auto d-flex justify-content-start align-items-center space-between-icons">
                <div
                    className="col-3 d-flex justify-content-center"
                    data-tip={ t('availableFromEnterprise') }
                    data-for='databaseTooltip'
                >
                    <Icon
                        icon="dashicons:database"
                        className='pointer text-secondary icon-size45'
                    />
                    <ReactTooltip
                        id='databaseTooltip'
                        type='dark'
                        effect='solid'
                    />
                </div>
                {/* <Icon icon="logos:whatsapp-icon" className='pointer text-dark icon-size4' />
                <Icon icon="logos:youtube-icon" className='pointer text-dark icon-size32' />
                <Icon icon="flat-color-icons:opened-folder" className='pointer text-dark icon-size45' />
                <Icon icon="dashicons:database" className='pointer text-darkyellow icon-size45' /> */}
            </div>
        </div>
    )
}

export default SelectPlatform