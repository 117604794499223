import React from 'react'
import { useTranslation } from 'react-i18next'

const SentimentFormatter = (props) => {
    
    const { t } = useTranslation()
    const socialMedia = props.data.social_media.toLowerCase()

    if( socialMedia === 'twitter' ){

      if( props.data.tweet_analysis.sentiment === 'Positive' ){
          return <div className='sentiment-result'>
                    <span className='text-positive'>{ t('positive') }</span>
                 </div>
      }
      
      if( props.data.tweet_analysis.sentiment === 'Negative' ){
          return <div className='sentiment-result'>
                    <span className='text-negative'>{ t('negative') }</span>
                 </div>
      }
      
      if( props.data.tweet_analysis.sentiment === 'Neutral' ){
          return <div className='sentiment-result'>
                    <span className='text-neutral'>{ t('neutral') }</span>
                 </div>
      }

    } else if( socialMedia === 'instagram' ){

      if( props.data.caption_analysis.sentiment === 'Positive' ){
        return <div className='sentiment-result'>
                  <span className='text-positive'>{ t('positive') }</span>
               </div>
      }
      
      if( props.data.caption_analysis.sentiment === 'Negative' ){
        return <div className='sentiment-result'>
                  <span className='text-negative'>{ t('negative') }</span>
               </div>
      }
      
      if( props.data.caption_analysis.sentiment === 'Neutral' ){
        return <div className='sentiment-result'>
                  <span className='text-neutral'>{ t('neutral') }</span>
               </div>
      }

    }



  return (
    <div></div>
  )
}

export default SentimentFormatter