import { useAuth0 } from '@auth0/auth0-react';
import React from 'react'
import { useTranslation } from 'react-i18next';

const TenantDisabledScreen = () => {
  
  const { logout } = useAuth0();
  const { t } = useTranslation();


  return (
    <div style={{ "height": "100vh" }} className='d-flex flex-column justify-content-center'>
        <div>
          <div className='feelings-logo'></div>
        </div>
        <h5 className='text-center text-mediumblue mt-5'>
            Cuenta no verificada
        </h5>

        <div className='d-flex flex-column text-center mt-4'>
          <span>Antes de ingresar, necesitamos verificar tu usuario.</span>
          <span>Por favor revisa tu email y confirma tu cuenta.</span>
        </div>

        <div className='d-flex justify-content-center'>
          <span
            className='text-lightblue pointer mt-3'
            onClick={ () => logout({ returnTo: window.location.origin }) }
          >
            Cerrar sesión
          </span>
        </div>
    </div>
  )
}

export default TenantDisabledScreen