import React from 'react'
import { useTranslation } from 'react-i18next'

const EnteredParametersFormatter = (props) => {
    
    const { t } = useTranslation()

    if( props.data.analysistype === 'keyword' || props.data.analysistype === 'hashtag' ){
        const enteredConfiguration = JSON.parse(props.data.enteredconfiguration)
        const keyword = enteredConfiguration.keyword
        const language = enteredConfiguration.language

        return <div className='d-flex flex-column params-height'>
                    <div>
                        <span className='pre-param'>{ t('analyzedWord') }: </span>
                        <span className='text-lightblue param'>{keyword}</span>
                    </div>
                    {
                        language && (
                            <div>
                                <span className='pre-param'>{ t('language') }: </span>
                                <span className='text-lightblue param'>{language}</span>
                            </div>
                        )
                    }
               </div>
    }
    

    if( props.data.analysistype === 'account' ){
        const enteredConfiguration = JSON.parse(props.data.enteredconfiguration)
        const accounts = enteredConfiguration.accounts

        return <div className='d-flex flex-column params-height'>
                    <div>
                        <span className='pre-param'>{ t('analyzedAccounts') }: </span>
                        <ul>
                        {
                            accounts.map( (account, index) => (
                                <li key={index} className='text-lightblue param'>@{account}</li>
                            ))
                        }
                        </ul>
                    </div>
               </div>
    }


  return (
    <div></div>
  )
}

export default EnteredParametersFormatter