import React from 'react'
import { useSelector } from 'react-redux';

const PaymentCanceledDisplay = () => {
    
  const { registerStages } = useSelector( state => state.register )


  return (
    <div style={{ "height": "100vh" }} className='d-flex flex-column justify-content-center'>
        <div>
          <div className='feelings-logo'></div>
        </div>
        <h5 className='text-center mt-5'>
            { registerStages.message }
        </h5>
    </div>
  )
}

export default PaymentCanceledDisplay