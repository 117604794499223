import { fetchBackend } from "./fetch"


export const getTweetsAndAnalysis = async(body) => {
    const twitterRequest = await fetchBackend('twitter/search/keyword', body, 'POST')
    const twitterResponse = await twitterRequest.json()
    return twitterResponse
}


export const getHastagResults = async(body) => {
    const instagramRequest = await fetchBackend('instagram/search/hashtag', body, 'POST')
    const instagramResponse = await instagramRequest.json()
    return instagramResponse
}


export const processWordCount = (wordCount) => {
    const sortedNegativeWords = []
    const sortedPositiveWords = []
    const allWordsCount = []
   for (let index = 0; index < wordCount.length; index++) {
    
    
    for (let word in wordCount[index].negativo) {
       
        sortedNegativeWords.push({text: word, value: wordCount[index].negativo[word]});
    }

    for (let word in wordCount[index].positivo) {
        sortedPositiveWords.push({text: word, value: wordCount[index].positivo[word]});
    }

    for(let i in wordCount[index].negativo){
        allWordsCount.push({ text: i, value: wordCount[index].negativo[i] })
    }
    for(let i in wordCount[index].positivo){
        allWordsCount.push({ text: i, value: wordCount[index].positivo[i] })
    }
   }
   sortedNegativeWords.sort((a, b) =>  b.value - a.value);
   sortedPositiveWords.sort((a, b) =>  b.value - a.value);
   allWordsCount.sort((a, b) =>  b.value - a.value);

    return { sortedNegativeWords, sortedPositiveWords, allWordsCount }
}