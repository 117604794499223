import React from 'react'
import { Icon } from '@iconify/react'
import { addAccountInput, removeAccountInput, setSearchAccounts, setTwitterSearchParameters } from '../../../store/slices/twitter/twitterSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const TwitterInputs = () => {

    const { twitterSearchParameters, status } = useSelector( state => state.twitter )
    const { globalSettings } = useSelector( state => state.settings )
    const { historyCount } = useSelector( state => state.history )
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()

    const handleSelectLanguage = () => {
        const name = 'requestLanguage'
        const value = document.querySelector('#language').selectedOptions[0].value;
        dispatch( setTwitterSearchParameters({name, value}) )
    }

    const handleSelectResultsType = () => {
        const name = 'resultsType'
        const value = document.querySelector('#resultsType').selectedOptions[0].value;
        dispatch( setTwitterSearchParameters({name, value}) )
    }

    const handleRemoveInput = () => {
        if( twitterSearchParameters.requestAccounts.length > 1 ){
            dispatch( removeAccountInput() )
        }
    }

    const handleSetTwitterSearchParameters = (e) => {
        const { name, value } = e.target;

        if( name === 'requestAmount' && value > globalSettings.userPlanSettings.max_posts ){
        } else if( name === 'requestAmount' && value < globalSettings.userPlanSettings.max_posts ){
            dispatch( setTwitterSearchParameters({name, value}) )
        } else {
            dispatch( setTwitterSearchParameters({name, value}) )
        }
    }

    const handleSetRequestAccounts = (e, index) => {
        const { name, value } = e.target;
        const accountValue = value[0] === '@' ? value.replace('@','') : value
        dispatch( setSearchAccounts( {index, name, value: accountValue} ) )
    }

    // const handleDateCheckbox = (e) => {
    //     const { checked } = e.target;
    //     document.querySelector('#date-from').disabled = !checked;
    //     document.querySelector('#date-to').disabled = !checked;
    // }

    const handleAddAccountInputs = () => {
        if(twitterSearchParameters.requestAccounts.length < 5){
            dispatch( addAccountInput() )
        }
    }

    const handleSelectAccount = (e, index) => {
        const { name, value } = e.target;
        dispatch( setTwitterSearchParameters({name, value}) )
        dispatch( setTwitterSearchParameters({name: 'requestSelectedAccountIndex', value: index}) )
    }

    useEffect(() => {
        const tweetsAmount = globalSettings.userPlanSettings.max_posts
        dispatch( setTwitterSearchParameters({name: 'requestType', value: 'keyword'}) )
        dispatch( setTwitterSearchParameters({name: 'maxRequestAmount', value: tweetsAmount}) )
        i18n.resolvedLanguage === 'es'
            ? dispatch( setTwitterSearchParameters({name: 'requestLanguage', value: 'es'}) )
            : dispatch( setTwitterSearchParameters({name: 'requestLanguage', value: 'en'}) )
        
        // document.querySelector('#date-from').disabled = true;
        // document.querySelector('#date-to').disabled = true;
    }, [])
    

  return (
    <div>
        <ul className="nav nav-pills" role="tablist">
            <li className="nav-item">
                <div
                    className={ twitterSearchParameters.requestType === 'keyword' ? 'nav-link active' : 'nav-link pointer' }
                    data-toggle="pill"
                    onClick={ () => dispatch( setTwitterSearchParameters({name: 'requestType', value: 'keyword'}) ) }
                >
                    { t('sentimentAnalysis') }
                </div>
            </li>
            <li className="nav-item">
                <div
                    className={ twitterSearchParameters.requestType === 'account' ? 'nav-link active' : 'nav-link pointer' }
                    data-toggle="pill"
                    onClick={ () => dispatch( setTwitterSearchParameters({name: 'requestType', value: 'account'}) ) }
                >
                    { t('comparativeAnalysis') }
                </div>
            </li>
        </ul>


        <div className="tab-content">
            <div className={ twitterSearchParameters.requestType === 'keyword' ? 'container tab-pane active' : 'd-none' }>
                <div className="row mb-4">
                    <div className="col-6 d-flex flex-column justify-content-end">
                        <label htmlFor='search-name'>
                            <small className='label-font'>{ t('analysisName') }</small>
                        </label>
                        <div className="d-flex align-items-center">
                            <input
                                autoComplete='off'
                                id='search-name'
                                className='form-control form-control-sm params-input'
                                type="text"
                                maxLength={100}
                                placeholder={ t('analysisNamePlaceholder') }
                                name='requestName'
                                value={ twitterSearchParameters.requestName }
                                onChange={ handleSetTwitterSearchParameters }
                                disabled={ status === 'processing' }
                            />
                        </div>
                    </div>
                    
                    <div className="col-3 d-flex flex-column justify-content-end">
                        <label className='label-font small-label-title' htmlFor="search-amount">
                            <small className=''>{ t('numberOfTweets') }</small>
                        </label>
                        <input
                            autoComplete='off'
                            id='search-amount'
                            className='form-control form-control-sm params-input'
                            type="number"
                            min={100}
                            max={ twitterSearchParameters.maxRequestAmount }
                            name='requestAmount'
                            value={ twitterSearchParameters.requestAmount }
                            onChange={ handleSetTwitterSearchParameters }
                            disabled={ status === 'processing' }
                        />
                    </div>
                    
                    <div className="col-3 d-flex flex-column justify-content-end">
                        <label className='label-font' htmlFor="language">
                            <small>{ t('language') }</small>
                        </label>
                        <select
                            className='form-control form-control-sm params-input'
                            name="requestLanguage"
                            id="language"
                            onChange={ handleSelectLanguage }
                            disabled={ status === 'processing' }
                        >
                            { i18n.resolvedLanguage === 'es' ? (
                                    <>
                                        <option value="es" defaultValue>{ t('spanish') }</option>
                                        <option value="en">{ t('english') }</option>
                                    </>
                                ) : (
                                    <>
                                        <option value="en" defaultValue>{ t('english') }</option>
                                        <option value="es">{ t('spanish') }</option>
                                    </>
                                )
                            }
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <input
                            autoComplete='off'
                            type="text"
                            className="form-control custom-input"
                            placeholder={ t('mainInputPlaceholder') }
                            name='requestKeyword'
                            value={ twitterSearchParameters.requestKeyword }
                            onChange={ handleSetTwitterSearchParameters }
                            disabled={ status === 'processing' }
                        />
                    </div>
                </div>

                <div className="row d-flex justify-content-between mt-4">
                    {/* <div className="d-flex justify-content-start align-items-center d-none">
                        <label className='label-font' htmlFor="select-date">
                            <small>Seleccionar un rango de fecha</small>
                        </label>
                        <input type="checkbox" name="select-date" id="select-date" className='ms-2' onChange={ handleDateCheckbox } />
                    </div>
                    <div className="col-3">
                        <label className='label-font d-none' htmlFor="date-from">
                            <small>Desde</small>
                        </label>
                        <input id='date-from' className='form-control form-control-sm params-input d-none' type="date" />
                    </div>
                    <div className="col-3">
                        <label className='label-font d-none' htmlFor="date-to">
                            <small>Hasta</small>
                        </label>
                        <input id='date-to' className='form-control form-control-sm params-input d-none' type="date" />
                    </div> */}

                    <div className="col-8 d-flex flex-column align-items-start">
                        <small className='text-mediumblue specification-font'>
                            { t('tweetsAccordingPlan') }: <span className='text-lightblue'>{ globalSettings.userPlanSettings.max_posts }</span>
                        </small>
                        <small className='text-mediumblue specification-font'>
                            { t('totalDoneAnalyzes') }: <span className='text-lightblue'>{ historyCount } { t('outOf') } { globalSettings.userPlanSettings.max_requests }</span>
                        </small>
                        <small className='text-mediumblue specification-font'>
                            { t('remainingAnalysis') }: <span className='text-lightblue'>{ globalSettings.userPlanSettings.max_requests - historyCount }</span>
                        </small>
                    </div>
                    
                    <div className="col-3 d-flex flex-column justify-content-end">
                        <label className='label-font small-label-title' htmlFor="language">
                            <small>{ t('resultsTypeTitle') }</small>
                        </label>
                        <select
                            className='form-control form-control-sm params-input'
                            name="requestLanguage"
                            id="resultsType"
                            onChange={ handleSelectResultsType }
                            disabled={ status === 'processing' }
                        >
                            <option value="recent" defaultValue>{ t('recent') }</option>
                            <option value="popular">{ t('popular') }</option>
                            <option value="mixed">{ t('both') }</option>
                        </select>
                    </div>
                </div>
            </div>


            <div className={ twitterSearchParameters.requestType === 'account' ? 'container tab-pane active' : 'd-none' }>
                <div className="row d-flex justify-content-between mb-2">

                    <div className="col-6">
                        <label htmlFor='search-account-name'>
                            <small className='label-font'>{ t('analysisName') }</small>
                        </label>
                        <div className="d-flex align-items-center">
                            <input
                                autoComplete='off'
                                id='search-account-name'
                                className='form-control form-control-sm params-input'
                                type="text"
                                maxLength={100}
                                placeholder={ t('analysisNamePlaceholder') }
                                name='requestName'
                                value={ twitterSearchParameters.requestName }
                                onChange={ handleSetTwitterSearchParameters }
                                disabled={ status === 'processing' }
                            />
                        </div>
                    </div>

                    <div className="col-3 d-flex flex-column align-items-center justify-content-center">
                        <div className='pointer' onClick={ handleAddAccountInputs }>
                            <small className='label-font me-2'>{ t('addAccount') }</small>
                            <Icon icon="akar-icons:circle-plus-fill" className='icon-size15' />
                        </div>
                        <div className='pointer' onClick={ handleRemoveInput }>
                            <small className='label-font me-2'>{ t('removeAccount') }</small>
                            <Icon  icon="fa6-solid:trash-can" className='icon-size11' />
                        </div>
                    </div>
                </div>

                <div className="row">
                {
                    twitterSearchParameters.requestSelectedAccountIndex === null && (
                        <div className='col-12 d-flex justify-content-end mb-1'>
                            <small className='text-lightblue pe-4'>
                                <strong>
                                    { t('selectAccount') }
                                </strong>
                                <Icon icon="fa-solid:hand-point-down" className='ms-2 hand-icon' />
                            </small>
                        </div>
                    )
                }
                </div>

                {
                    twitterSearchParameters.requestAccounts.map( (account, index) => (
                        <div className="row mb-2" key={index}>
                            <div className="col-12 input-group">
                                <span className="input-group-text pre-account-input">@</span>
                                <input
                                    autoComplete='off'
                                    type="text"
                                    className="form-control account-input"
                                    placeholder="Seidor_Analytic"
                                    name='requestAccounts'
                                    value={ account }
                                    onChange={ (e) => handleSetRequestAccounts(e, index) }
                                    disabled={ status === 'processing' }
                                />
                                <div className="input-group-text d-flex flex-column justify-content-center align-items-center account-checkbox">
                                    <input
                                        type="radio"
                                        name="requestSelectedAccount"
                                        value={ account }
                                        id={`isMyAccount${index}`}
                                        onChange={ (e) => handleSelectAccount(e, index) }
                                        disabled={ status === 'processing' }
                                        className='mb-1' />
                                    <label htmlFor={`isMyAccount${index}`}>
                                        <p className='text-91'>{ t('mainAccount1') }</p>
                                        <p className='text-91'>{ t('mainAccount2') }</p>
                                    </label>
                                </div>
                            </div>
                        </div>
                    ))
                }


                <div className="row mt-4">
                    <div className="col-8 d-flex flex-column align-items-start">
                        <small className='text-mediumblue specification-font'>
                            { t('totalDoneAnalyzes') }: <span className='text-lightblue'>{ historyCount } { t('outOf') } { globalSettings.userPlanSettings.max_requests }</span>
                        </small>
                        <small className='text-mediumblue specification-font'>
                        { t('remainingAnalysis') }: <span className='text-lightblue'>{ globalSettings.userPlanSettings.max_requests - historyCount }</span>
                        </small>
                    </div>
                </div>
                

                {/* <div className="row mt-4 d-none">
                    <div className="col-3">
                        <label className='label-font' htmlFor="date-from">
                            <small>Desde</small>
                        </label>
                        <input id='date-from' className='form-control form-control-sm params-input' type="date" />
                    </div>
                    <div className="col-3">
                        <label className='label-font' htmlFor="date-to">
                            <small>Hasta</small>
                        </label>
                        <input id='date-to' className='form-control form-control-sm params-input' type="date" />
                    </div>
                </div> */}
            </div>
        </div>
    </div>
  )
}

export default TwitterInputs