import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import SentimentAnalysisResultLink from '../SentimentAnalysisResultLink';
import { useTranslation } from 'react-i18next';
import AnalysisTypeFormatter, { FavoriteFormatter, SocialMediaFormatter } from '../DataTableFormatter';
import EnteredParametersFormatter from '../EnteredParametersFormatter';
import DateFormatter from '../DateFormatter';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../styles/table.css'


const HistoryAnalysisTable = () => {
    
    const { historicalAnalysis } = useSelector( state => state.history )
    const { t } = useTranslation()
  
    const gridRef = useRef(); // Optional - for accessing Grid's API
    // Each Column Definition results in one Column.
    const [columnDefs, setColumnDefs] = useState([
        {
            field: 'datecreated',
            headerName: t('analysisDate'),
            cellRenderer: DateFormatter
        },
        {
            field: 'analysistype',
            headerName: t('analysisType'),
            cellRenderer: AnalysisTypeFormatter
        },
        {
            field: 'isfavorite',
            headerName: t('favorite'),
            cellRenderer: FavoriteFormatter,
            minWidth: 120
        },
        {
            field: 'searchname',
            headerName: t('analysisName')
        },
        {
            field: 'socialmedia',
            headerName: t('socialMedia'),
            cellRenderer: SocialMediaFormatter
        },
        {
            field: 'enteredconfiguration',
            headerName: t('enteredParameters'),
            wrapText: true,
            autoHeight: true,
            cellRenderer: EnteredParametersFormatter
        },
        {
            field: 'analysisid',
            headerName: t('results'),
            cellRenderer: SentimentAnalysisResultLink
        }
    ]);
    
    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo( ()=> ({ sortable: true, filter: true }) );

    
    const onFirstDataRendered = useCallback(() => {
        const allColumnIds = [];
        gridRef.current.columnApi.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }, []);


    return (
        <div className="ag-theme-alpine" style={{width: "100%", height: '80vh'}}>
            <AgGridReact
                ref={gridRef}
                rowData={historicalAnalysis}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                pagination={true}
                onFirstDataRendered={onFirstDataRendered}
            />
        </div>
    )
}

export default HistoryAnalysisTable