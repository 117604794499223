import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import { AgGridReact } from 'ag-grid-react';
import DateFormatter from '../../DateFormatter';
import SentimentFormatter from '../../SentimentFormatter';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../../styles/table.css'


class deliveredMessageIndicator {
    init(params) {
        const element = document.createElement('a');
        const tweetId = 'Ver Post'
        element.innerText = tweetId
        element.href = `${params.data.permalink}`
        element.target = '_blank'
        this.eGui = element;
    }
    getGui() {
        return this.eGui;
    }
}




const InstagramSentimentTable = () => {
    
    const { dataKeywordResult } = useSelector( state => state.instagram )
    const { t } = useTranslation()
    
    const gridRef = useRef(); // Optional - for accessing Grid's API
    const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row

    // Each Column Definition results in one Column.
    const [columnDefs, setColumnDefs] = useState([
        {
            field: 'created_date_raw',
            headerName: 'Fecha de Publicacion',
            cellRenderer: DateFormatter
        },
        {
            field: 'caption',
            headerName: 'Posts',
            resizable: true,
            maxWidth: 500
        },
        {
            field: 'caption_analysis.sentiment',
            headerName: 'Sentimiento',
            cellRenderer: SentimentFormatter
        },
        {
            field: 'created_date_raw',
            headerName: 'Fecha de Publicacion',
            cellRenderer: DateFormatter
        },
        {
            field: 'like_count',
            headerName: 'Likes',
            minWidth: 100
        },
        {
            field: 'comments_count',
            headerName: 'Comentarios',
            minWidth: 100
        },

        {
            field: 'caption_analysis.polarity',
            headerName: 'Polaridad'
        },
        {
            field: 'permalink',
            headerName: 'Link Publicacion',
            cellRenderer: deliveredMessageIndicator,
            minWidth:150
        }
    ]);
    
    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo( ()=> ({ sortable: true, filter: true, resizable: true
        
        }) );

  
    // Example load data from sever
    useEffect(() => {
        setRowData(dataKeywordResult.data)
    }, []);

    
    const autoSizeAll = useCallback((skipHeader) => {
        const allColumnIds = [];
            gridRef.current.columnApi.getColumns().forEach((column) => {
                allColumnIds.push(column.getId());
            });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }, []);


    const onBtnExport = () => {
        const sheetData = dataKeywordResult.data
        const wb = XLSX.utils.book_new()
        const ws = XLSX.utils.json_to_sheet(sheetData)
        XLSX.utils.book_append_sheet(wb, ws, "MySheet1")
        XLSX.writeFile(wb, "Feelings-sentiment-analysis.xlsx");
    }

    
    const onFirstDataRendered = useCallback((params) => {
        // gridRef.current.api.sizeColumnsToFit();
        const allColumnIds = [];
        gridRef.current.columnApi.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }, []);


    return (
        <div>
            <div className='mt-5'>
                <h5 className='text-center sentiment-result-charts-title mb-0'>
                    { t('sentimentTable') }
                </h5>
                <h6 className='text-center sentiment-result-charts-subtitle'>
                    { t('tableDetail') }
                </h6>
            </div>

            <div className='d-flex justify-content-end'>
                <button
                    className='btn btn-sm btn-mediumblue'
                    onClick={ onBtnExport }
                >
                    <Icon icon="mdi:microsoft-excel" className='icon-size2 me-2' />
                    { t('exportTable') }
                </button>
            </div>
            
            <div className="ag-theme-alpine" style={{width: "100%", height: 600}}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    pagination={true}
                    onFirstDataRendered={onFirstDataRendered}
                />
            </div>
        </div>
    )
}

export default InstagramSentimentTable