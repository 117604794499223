import React, { useMemo } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
)


const options = {
    responsive: true,
    fill: true,
    plugins: {
      legend: false,
      title: {
        display: false
      },
    },
}

const RetweetsEvolutionLineChart = () => {
  
    const { dataAccountsResult } = useSelector( state => state.twitter )
    const { t } = useTranslation()

    const dateRawArray = dataAccountsResult.evolution.map( row => ({ date: row.created_at, retweets: row.retweets }) )
    const [linesToShow, setLinesToShow] = useState('months')



    const getAllMinutes = () => {
      const arrayAllData = dateRawArray.map( row => {
        const myDate = new Date(row.date)
        const month = `${myDate.getMonth()+1}-${myDate.getFullYear()}`
        const date = `${myDate.getDate()}-${myDate.getMonth()+1}-${myDate.getFullYear()}`
        const hour = myDate.getHours().toString().length === 1 ? `${date} 0${myDate.getHours()}` : `${date} ${myDate.getHours()}`
        const retweets = row.retweets
        return { hour: `${hour}`, date, month, retweets }
      }).reverse()
      
      
      let retweetsPerDate = []
      arrayAllData.forEach(function (a) {
        if (!this[a.date]) {
            this[a.date] = { date: a.date, retweets: 0 };
            retweetsPerDate.push(this[a.date]);
        }
        this[a.date].retweets += a.retweets;
      }, []);
      
      let retweetsPerMonth = []
      arrayAllData.forEach(function (a) {
        if (!this[a.month]) {
            this[a.month] = { month: a.month, retweets: 0 };
            retweetsPerMonth.push(this[a.month]);
        }
        this[a.month].retweets += a.retweets;
      }, []);

      return { retweetsPerDate, retweetsPerMonth }
    }
    
    
    const retweetsPerDayScores = getAllMinutes().retweetsPerDate.map( row => row.retweets )
    const retweetsPerDaylabels = getAllMinutes().retweetsPerDate.map( row => row.date )
    
    const retweetsPerMonthScores = getAllMinutes().retweetsPerMonth.map( row => row.retweets )
    const retweetsPerMonthlabels = getAllMinutes().retweetsPerMonth.map( row => row.month )

    
    const retweetsPerDayData = useMemo(function(){
      return {
          datasets: [
            {
              label: "retweets",
              data: retweetsPerDayScores,
              borderWidth: 0.7,
              borderColor: "rgb(38, 60, 122)",
              backgroundColor: "rgb(38, 60, 122, 0.5)"
          }
        ],
          labels: retweetsPerDaylabels
      }
    }, [])
  
    const retweetsPerMonthData = useMemo(function(){
      return {
          datasets: [
            {
              data: retweetsPerMonthScores,
              borderWidth: 0.7,
              borderColor: "rgb(38, 60, 122)",
              backgroundColor: "rgb(38, 60, 122, 0.5)"
          }
        ],
          labels: retweetsPerMonthlabels
      }
    }, [])




  return (
    <div className='chart-styles pt-2'>
        <h5 className='chart-title mb-0'>{ t('retweets') }</h5>
        <small className='chart-subtitle'>{ t('retweetsEvolution') }</small>
        <div className='d-flex justify-content-center'>
            <div
              className={ linesToShow === 'days' ? 'badge m-badge bg-mediumblue pointer' : 'badge m-badge bg-91 pointer' }
              onClick={ () => setLinesToShow('days') }
            >{ t('day') }</div>
            <div
              className={ linesToShow === 'months' ? 'badge m-badge bg-mediumblue pointer' : 'badge m-badge bg-91 pointer' }
              onClick={ () => setLinesToShow('months') }
            >{ t('month') }</div>
        </div>

        <Bar
          data={ linesToShow === 'days' ? retweetsPerDayData : retweetsPerMonthData }
          options={options}
        />
    </div>
  )
}

export default RetweetsEvolutionLineChart