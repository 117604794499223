import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { setParametersAndResultsFromHistoryInstagram } from '../store/slices/instagram/instagramSlice';
import { cleanAccountSearchParams, cleanKeywordSearchParams, setParametersAndResultsFromHistory } from '../store/slices/twitter/twitterSlice';
import { cleanNewsKeywordSearchParams, setNewsParametersAndResultsFromHistory } from '../store/slices/news/newsSlice';


const SentimentAnalysisResultLink = (props) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const analysistype = JSON.parse(props.data.enteredconfiguration).analysistype
    const socialmedia = JSON.parse(props.data.enteredconfiguration).socialmedia


    const handleSelectTwitterAnalysis = () => {
        const parameters = JSON.parse(props.data.enteredconfiguration)
        const analysisId = props.data.analysisid
        const isFavorite = JSON.parse(props.data.isfavorite)
        const results = JSON.parse(props.data.results)
        const hour = new Date(props.data.datecreated).getHours()
        const min =  new Date(props.data.datecreated).getMinutes().toString().length === 1 ? `0${new Date(props.data.datecreated).getMinutes()}` : new Date(props.data.datecreated).getMinutes()
        const requestDate = `${props.data.datecreated_format} ${hour}:${min}hs`

        dispatch( cleanKeywordSearchParams() )
        dispatch( cleanAccountSearchParams() )
        dispatch( setParametersAndResultsFromHistory( {parameters, results, requestDate, isFavorite, analysisId} ) )
    }

    
    const handleSelectNewsAnalysis = () => {
      const parameters = JSON.parse(props.data.enteredconfiguration)
      const analysisId = props.data.analysisid
      const isFavorite = JSON.parse(props.data.isfavorite)
      const results = JSON.parse(props.data.results)
      const hour = new Date(props.data.datecreated).getHours()
      const min =  new Date(props.data.datecreated).getMinutes().toString().length === 1 ? `0${new Date(props.data.datecreated).getMinutes()}` : new Date(props.data.datecreated).getMinutes()
      const requestDate = `${props.data.datecreated_format} ${hour}:${min}hs`

      dispatch( cleanNewsKeywordSearchParams() )
      dispatch( setNewsParametersAndResultsFromHistory( {parameters, results, requestDate, isFavorite, analysisId} ) )
  }
    

    const handleSelectInstagramAnalysis = () => {
      const parameters = JSON.parse(props.data.enteredconfiguration)
      const analysisId = props.data.analysisid
      const isFavorite = JSON.parse(props.data.isfavorite)
      const results = JSON.parse(props.data.results)
      const hour = new Date(props.data.datecreated).getHours()
      const min =  new Date(props.data.datecreated).getMinutes().toString().length === 1 ? `0${new Date(props.data.datecreated).getMinutes()}` : new Date(props.data.datecreated).getMinutes()
      const requestDate = `${props.data.datecreated_format} ${hour}:${min}hs`
      
      // dispatch( cleanKeywordSearchParams() )
      // dispatch( cleanAccountSearchParams() )
      dispatch( setParametersAndResultsFromHistoryInstagram( {parameters, results, requestDate, isFavorite, analysisId} ) )
    }


    if( socialmedia === 'twitter' ){
      return (
        <Link
            className='text-decoration-none see-tweet-link text-mediumblue'
            to={ analysistype === 'keyword' ? '/analysis/twitter/charts-sentiment' : '/analysis/twitter/charts-data' }
            onClick={ handleSelectTwitterAnalysis }
        >
            { t('seeAnalysis') }
        </Link>
      )
    }

    
    if( socialmedia === 'instagram' ){
      return (
        <Link
            className='text-decoration-none see-tweet-link text-mediumblue'
            to={ (analysistype === 'hashtag' || analysistype === 'keyword') ? '/analysis/instagram/charts-sentiment' : '/analysis/instagram/charts-data' }
            onClick={ handleSelectInstagramAnalysis }
        >
            { t('seeAnalysis') }
        </Link>
      )
    }

    
    if( socialmedia === 'news' ){
      return (
        <Link
            className='text-decoration-none see-tweet-link text-mediumblue'
            // to={ '/history' }
            to={ analysistype === 'keyword' ? '/analysis/news/charts-sentiment' : '/analysis/news/charts-data' }
            onClick={ handleSelectNewsAnalysis }
        >
            { t('seeAnalysis') }
        </Link>
      )
    }

  return (
    <div className='text-decoration-none see-tweet-link text-mediumblue'>
        { t('seeAnalysis') }
    </div>
  )
}

export default SentimentAnalysisResultLink