import { createSlice } from '@reduxjs/toolkit'
import { processWordCount } from '../../../helpers/instagram';


const initialState = {
    status: 'ready', // ready, processing
    dataKeywordResult: {
        data: [],
        caption: null,
        commentsCount: 0,
        id: null,
        like_count: 0,
        mediaType:null,
        mediaUrl: null,
        permalink:null,
        timestamp: null,
        wordCount: {
            showingWords : 'all',
            negativeWords : [],
            positiveWords: [],
            allWords: []
        }
    },
    dataAccountsResult: [],
    instagramSearchParameters: {
        requestLanguage: '',
        requestType: 'hashtag',
        requestName: '',
        requestSocialMedia: 'instagram',
        requestAmount: 100,
        maxRequestAmount: null,
        requestDate: null,
        hashtags: [],
        analysisId: null,
        isFavorite: false
    }
}

export const instagramSlice = createSlice({
    name: 'instagram',
    initialState,
    reducers: {
        setInstagramSearchParameters: (state, {payload}) => {
            state.instagramSearchParameters[payload.name] = payload.value;
        },
        setSearchAccounts: (state, {payload}) => {
            state.instagramSearchParameters[payload.name][payload.index] = payload.value;
        },
        addAccountInput: (state) => {
            state.instagramSearchParameters.requestAccounts = [...state.instagramSearchParameters.requestAccounts, '']
        },
        removeAccountInput: (state) => {
            state.instagramSearchParameters.requestAccounts = state.instagramSearchParameters.requestAccounts.slice(0, state.instagramSearchParameters.requestAccounts.length-1)
        },
        processingInstagramRequest: (state) => {
            state.status = 'processing';
        },
        finishInstagramRequest: (state) => {
            state.status = 'ready';
        },
        getHashtagData: (state,{payload})=>{
            state.status = 'ready';
            state.dataKeywordResult.data = payload.data;
            state.dataKeywordResult.permalink=payload.data.permalink;
            state.dataKeywordResult.totalAnalysisAmounts = payload.total_analysis_amounts.total_amounts;
            state.dataKeywordResult.totalAnalysisPercentages = payload.total_analysis_amounts.total_percentages;
            state.dataKeywordResult.polarityAmounts = payload.polarity_amounts;
            state.dataKeywordResult.wordCount.allWords = processWordCount(payload.wordCount).allWordsCount
            state.dataKeywordResult.wordCount.negativeWords = processWordCount(payload.wordCount).sortedNegativeWords;
            state.dataKeywordResult.wordCount.positiveWords = processWordCount(payload.wordCount).sortedPositiveWords;
        },
        changeWordsToShowOnCloud: (state, {payload}) => {
            state.dataKeywordResult.wordCount.showingWords = payload.wordsType;
        },
        cleanInstagramSearchParams: (state) => {
            state.dataAccountsResult = [];
            state.dataKeywordResult.data = [];
        },
        setParametersAndResultsFromHistoryInstagram: (state, {payload}) => {

            state.instagramSearchParameters.requestType = payload.parameters.analysistype
            state.instagramSearchParameters.requestName = payload.parameters.searchname
            state.instagramSearchParameters.hashtags = [payload.parameters.keyword]
            state.instagramSearchParameters.requestDate = payload.requestDate
            state.instagramSearchParameters.isFavorite = payload.isFavorite
            state.instagramSearchParameters.analysisId = payload.analysisId
    
            state.status = 'ready';
            state.dataKeywordResult.data = payload.results.sentimentAnalysisResult.analyzedCaptions;
            state.dataKeywordResult.totalAnalysisAmounts = payload.results.total_numbers.total_amounts;
            state.dataKeywordResult.totalAnalysisPercentages = payload.results.total_numbers.total_percentages
            state.dataKeywordResult.polarityAmounts = payload.results.polarity_amounts
            state.dataKeywordResult.wordCount.showingWords = 'all'
            state.dataKeywordResult.wordCount.negativeWords = processWordCount(payload.results.sentimentAnalysisResult.wordCount).sortedNegativeWords
            state.dataKeywordResult.wordCount.positiveWords = processWordCount(payload.results.sentimentAnalysisResult.wordCount).sortedPositiveWords
            state.dataKeywordResult.wordCount.allWords = processWordCount(payload.results.sentimentAnalysisResult.wordCount).allWordsCount

        }
    },
})

// Action creators are generated for each case reducer function
export const { setInstagramSearchParameters, changeWordsToShowOnCloud, processingInstagramRequest, finishInstagramRequest, addAccountInput, removeAccountInput, setSearchAccounts, cleanInstagramSearchParams, getHashtagData, setParametersAndResultsFromHistoryInstagram } = instagramSlice.actions