import React from 'react'
import { useSelector } from 'react-redux'
import NewsResultScreen from './NewsResultScreen';
import NewsSearchDashboard from './NewsSearchDashboard';


const NewsScreen = () => {

    const { dataKeywordResult } = useSelector( state => state.news )

  return (
    <div className="home">
        {
            dataKeywordResult.data ? (
                <NewsResultScreen />
            ) : (
                <NewsSearchDashboard />
            )
        }
    </div>
  )
}

export default NewsScreen