import React from 'react'
import { useSelector } from 'react-redux'
import TwitterResultScreen from './TwitterResultScreen';
import TwitterSearchDashboard from './TwitterSearchDashboard';


const TwitterScreen = () => {

    const { dataKeywordResult, dataAccountsResult } = useSelector( state => state.twitter )


  return (
    <div className="home">
        {
            dataKeywordResult.data || dataAccountsResult.data.length > 0 ? (
                <TwitterResultScreen />
            ) : (
                <TwitterSearchDashboard />
            )
        }
    </div>
  )
}

export default TwitterScreen