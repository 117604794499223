import React from 'react'
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import AcercaDe from './AcercaDe';
import Configuraciones from './Configuraciones';
import DelayRequestNotification from './DelayRequestNotification';
import '../../styles/modal.css'


Modal.setAppElement('#root');

const ParentModal = () => {

    const { openModal, modalSelected } = useSelector( store => store.modal )

  return (
    <Modal
        isOpen={ openModal }
        closeTimeoutMS={ 200 }
        className={ modalSelected === 'delayRequestNotification' ? 'modal-notification' : 'modal-usersettings' }
        overlayClassName="modal-fondo"
    >
      {
           (modalSelected === 'settings' && <Configuraciones/>)
        || (modalSelected === 'about' && <AcercaDe/>)
        || (modalSelected === 'delayRequestNotification' && <DelayRequestNotification/>)
        || (modalSelected === null && '')
      }
    </Modal>
  )
}

export default ParentModal