import React from 'react'
import { setInstagramSearchParameters } from '../../../store/slices/instagram/instagramSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const SearchInputInstagram = () => {

    const { instagramSearchParameters, status } = useSelector( state => state.instagram)
    const { globalSettings } = useSelector( state => state.settings )
    const { historyCount } = useSelector( state => state.history )
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation()

    const handleSetInstagramSearchParameters = (e) => {
        const { name, value } = e.target;
        dispatch( setInstagramSearchParameters({name, value}) )
    }
    
    // const handleSelectLanguage = () => {
    //     const name = 'requestLanguage'
    //     const value = document.querySelector('#language').selectedOptions[0].value;
    //     dispatch( setInstagramSearchParameters({name, value}) )
    // }

    useEffect(() => {
        const igAmount = globalSettings.userPlanSettings.max_posts
        dispatch( setInstagramSearchParameters({name: 'maxRequestAmount', value: igAmount}) )
        i18n.resolvedLanguage === 'es'
            ? dispatch( setInstagramSearchParameters({name: 'requestLanguage', value: '-'}) )
            : dispatch( setInstagramSearchParameters({name: 'requestLanguage', value: '-'}) )
    }, [])
    
    
  return (
    <div>
        <ul className="nav nav-pills" role="tablist">
            <li className="nav-item">
                <div
                    className={ 'nav-link active' } 
                    data-toggle="pill"
                    onClick={ () => dispatch( setInstagramSearchParameters({name: 'requestType', value: 'hashtag'}) ) }
                >
                    Hashtag
                </div>
            </li>
        </ul>


        <div className="tab-content">
            <div className={  'container tab-pane active' }>
                <div className="row mb-4">
                    <div className="col-6 d-flex flex-column justify-content-end">
                        <label htmlFor='search-name'>
                            <small className='label-font'>{ t('analysisName') }</small>
                        </label>
                        <div className="d-flex align-items-center">
                            <input
                                disabled= { status === 'processing'}
                                autoComplete='off'
                                id='search-name'
                                className='form-control form-control-sm params-input'
                                type="text"
                                placeholder={ t('analysisNamePlaceholder') }
                                name='requestName'
                                value={ instagramSearchParameters.requestName }
                                onChange={ handleSetInstagramSearchParameters }
                            />
                        </div>
                    </div>
                    
                    <div className="col-3 d-flex flex-column justify-content-end">
                        <label className='label-font small-label-title' htmlFor="search-amount">
                            <small className=''>{ t('numberOfPosts') }</small>
                        </label>
                        <input
                            autoComplete='off'
                            id='search-amount'
                            className='form-control form-control-sm params-input'
                            type="number"
                            min={100}
                            max={ instagramSearchParameters.maxRequestAmount }
                            name='requestAmount'
                            value={ instagramSearchParameters.requestAmount }
                            onChange={ handleSetInstagramSearchParameters }
                            disabled={ status === 'processing' }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <input
                            disabled= { status === 'processing'}
                            type="text"
                            className="form-control custom-input mb-4"
                            placeholder="seidor"
                            name='hashtags'
                            value={ instagramSearchParameters.hashtags }
                            onChange={ handleSetInstagramSearchParameters }
                        />
                    </div>
                </div>

                <div className="col-8 d-flex flex-column align-items-start">
                    <small className='text-mediumblue specification-font'>
                        { t('postsAccordingPlan') }: <span className='text-lightblue'>{ globalSettings.userPlanSettings.max_posts }</span>
                    </small>
                    <small className='text-mediumblue specification-font'>
                        { t('totalDoneAnalyzes') }: <span className='text-lightblue'>{ historyCount } { t('outOf') } { globalSettings.userPlanSettings.max_requests }</span>
                    </small>
                    <small className='text-mediumblue specification-font'>
                        { t('remainingAnalysis') }: <span className='text-lightblue'>{ globalSettings.userPlanSettings.max_requests - historyCount }</span>
                    </small>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SearchInputInstagram