import React, { useEffect } from 'react'
import countries from 'i18n-iso-countries';
import { DateTime } from 'luxon'
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux'
import PasswordStrengthBar from 'react-password-strength-bar';
import LoginButton from './LoginButton'
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import ProductDisplay from './ProductDisplay'
import { setNewUserInformation, setRegisterStage } from '../store/slices/register/registerSlice';
import { getCountriesList, validarEmail, validarPassword } from '../helpers/register';
import { startRegisterNewUser } from '../store/slices/register/thunks';
import SuccessDisplay from './SuccessDisplay';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/newuserpage.css'


// const timeZone = DateTime.now().zoneName
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));


const CustomSignUpForm = () => {
  
  const { newUserInformation, registerStages } = useSelector( state => state.register )
  const { planSelected } = useSelector( state => state.plan )
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const emailVerificationInput = document.querySelector('#newUserEmailVerify');
  const passwordVerificationInput = document.querySelector('#newUserPasswordVerify');


  // Capturamos la información del formulario de registro
  const handleUserInformation = (e) => {
    const { name, value } = e.target;

    if( name === 'userEmail' && value === newUserInformation.userEmailVerify ){
      dispatch( setRegisterStage({ type: 'showErrorMessage', value: false }) )
    }
    if( name === 'userPassword' && value === newUserInformation.userPasswordVerify ){
      dispatch( setRegisterStage({ type: 'showPasswordErrorMessage', value: false }) )
    }
    if( name === 'userPassword' && value.length === 0 ){
      dispatch( setRegisterStage({ type: 'showPasswordRules', value: false }) )
    }

    dispatch( setNewUserInformation({name, value}) )
  }


  const handleSelectCountry = (payload) => {
    dispatch( setNewUserInformation({ name: 'userCountry', value: { code: payload.value, value: payload.label } }) )
  }
  

  // Verifica la coincidencia del Email y Contraseña cuando otro input tiene el foco
  const emailAndPasswordVerified = () => {
    if(newUserInformation.userEmail === newUserInformation.userEmailVerify){
      dispatch( setRegisterStage({ type: 'showErrorMessage', value: false }) )
    } else {
      dispatch( setRegisterStage({ type: 'showErrorMessage', value: true }) )
      emailVerificationInput.focus()
    }

    if(newUserInformation.userPassword === newUserInformation.userPasswordVerify){
      dispatch( setRegisterStage({ type: 'showPasswordErrorMessage', value: false }) )
    } else {
      dispatch( setRegisterStage({ type: 'showPasswordErrorMessage', value: true }) )
      passwordVerificationInput.focus()
    }
  }


  // Manejo del form submit
  const handleSubmitRegisterForm = async(e) => {
      e.preventDefault();
      dispatch( setRegisterStage({ type: 'loadingButton', value: true }) )
      const allPlans = ['trial', 'personal', 'pro', 'business']

      if(!allPlans.includes(planSelected.toLowerCase())){
        toast.warn( t('wrongPlan') , {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
        dispatch( setRegisterStage({ type: 'loadingButton', value: false }) )
        return
      }

      // Validar que la confirmación del email sea igual al campo "Email"
      if(newUserInformation.userEmail !== newUserInformation.userEmailVerify){
        dispatch( setRegisterStage({ type: 'showErrorMessage', value: true }) )
        emailVerificationInput.focus()
        return
      }

      // Validar que la confirmación del password sea igual al campo "Password"
      if(newUserInformation.userPassword !== newUserInformation.userPasswordVerify){
        dispatch( setRegisterStage({ type: 'showPasswordErrorMessage', value: true }) )
        passwordVerificationInput.focus()
        return
      }

      // No permitir campo "País" vacío
      if(!newUserInformation.userCountry){
        toast.warn( t('mustSelectCountry') , {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
        dispatch( setRegisterStage({ type: 'loadingButton', value: false }) )
        return
      }

      // Validar que sea un mail válido y no tenga tildes
      await validarEmail( newUserInformation.userEmail )
      
      const passwordStrengthValidation = validarPassword(newUserInformation.userPassword)
      if( !passwordStrengthValidation.passwordOk ){
        toast.warn( t('mustSelectCountry') , {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
        dispatch( setRegisterStage({ type: 'loadingButton', value: false }) )
        dispatch( setRegisterStage({ type: 'showPasswordRules', value: true }) )
        return
      }
      

      // Armado de datos a guardar
      const tenantid = newUserInformation.userEmail.replace('@', '_');
      const data = {
          newUserInformation,
          tenantid,
          fecha: DateTime.now(),
          pago: false,
          terminosaceptados: registerStages.termsAndConditionsChecked,
          avisoslegales: false,
          language: i18n.resolvedLanguage
      }

      // Guardado en BD | Tabla tenants
      dispatch( startRegisterNewUser(data) )
  }


  useEffect(() => {
    const countriesArray = getCountriesList(i18n.resolvedLanguage)
    dispatch( setRegisterStage({ type: 'countryOptions', value: countriesArray }) )
    dispatch( setNewUserInformation({ name: 'userLanguage', value: i18n.resolvedLanguage }) )
    dispatch( setNewUserInformation({ name: 'userSettings', value: JSON.stringify({ language: i18n.resolvedLanguage }) }) )
  }, [])
  


  return registerStages.message !== '' ? (
    <SuccessDisplay />
  ) : (
    <>
        <div className='feelings-logo'></div>

        <div className='d-flex flex-column justify-content-center align-items-center'>
          <h2 className='signUpPageTitle text-darkgray mb-4'>{t('userSignUp')}</h2>

          <ProductDisplay />

          {/* Redirect a Login page */}
          <small className='mb-4 color-login d-flex'>
              {t('alreadySignedUp')} <LoginButton />
          </small>


          <form className='newUserForm' onSubmit={ handleSubmitRegisterForm }>
            <label htmlFor="newUserName">{t('name')}</label>
            <input
              autoComplete='off'
              className='form-control mb-2 text-darkgray'
              type="text"
              name="userName"
              id="newUserName"
              placeholder={ t('namePlaceholder') }
              value={ newUserInformation.userName }
              onChange={ handleUserInformation }
              onFocus={ emailAndPasswordVerified }
              required
            />


            <label htmlFor="newUserLastname">{t('lastname')}</label>
            <input
              autoComplete='off'
              className='form-control mb-2 text-darkgray'
              type="text"
              name="userLastname"
              id="newUserLastname"
              placeholder={ t('lastnamePlaceholder') }
              value={ newUserInformation.userLastname }
              onChange={ handleUserInformation }
              onFocus={ emailAndPasswordVerified }
              required
            />


            <label htmlFor="newUserEmail">{t('email')}</label>
            <input
              autoComplete='off'
              className='form-control mb-2 text-darkgray'
              type="email"
              name="userEmail"
              id="newUserEmail"
              placeholder={ t('emailPlaceholder') }
              value={ newUserInformation.userEmail }
              onChange={ handleUserInformation }
              required
            />
            
            <label htmlFor="newUserEmailVerify" className='mb-0'>{t('confirmEmail')}</label>
            {
              registerStages.showErrorMessage ? (
                <div className="d-block">
                    <small className='d-flex align-items-center text-danger'>
                        <i className='bx bx-x x-size'></i>
                        {t('emailsMustBeTheSame')}
                    </small>
                </div>
              ) : null
            }
            <input
              autoComplete='off'
              className='form-control mb-2 text-darkgray'
              type="email"
              name="userEmailVerify"
              id="newUserEmailVerify"
              placeholder={ t('confirmEmailPlaceholder') }
              value={ newUserInformation.userEmailVerify }
              onChange={ handleUserInformation }
              required
            />
            

            <label htmlFor="newUserPassword">{t('password')}</label>
            <input
              autoComplete='off'
              className='form-control mb-2 text-darkgray'
              type="password"
              name="userPassword"
              id="newUserPassword"
              placeholder={ t('passwordPlaceholder') }
              value={ newUserInformation.userPassword }
              onChange={ handleUserInformation }
              required
            />
            <PasswordStrengthBar
              className='mt-2'
              password={ newUserInformation.userPassword }
              minLength={1}
              onChangeScore={(score, feedback) => {
                if(score >= 3 || newUserInformation.userPassword.length === 0){
                  dispatch( setRegisterStage({ type: 'showPasswordRules', value: false }) )
                } else {
                  dispatch( setRegisterStage({ type: 'showPasswordRules', value: true }) )
                }
              }}
            />
            {/* Requisitos de la contraseña */}
            <div className={ registerStages.showPasswordRules ? 'border py-2 px-3 mt-2 mb-4' : 'd-none' }>
              <small>{ t('passwordRequirementsTitle') }:</small>
              <ul className='small-font mb-0'>
                <li>{ t('passwordRequirementsItem1') }</li>
                <li>{ t('passwordRequirementsItem2') }:
                  <ul>
                    <li>{ t('passwordRequirementsItem2a') }</li>
                    <li>{ t('passwordRequirementsItem2b') }</li>
                    <li>{ t('passwordRequirementsItem2c') }</li>
                    <li>{ t('passwordRequirementsItem2d') }</li>
                  </ul>
                </li>
              </ul>
            </div>


            <label htmlFor="newUserPasswordVerify" className='mb-0'>{t('confirmPassword')}</label>
            {
              registerStages.showPasswordErrorMessage ? (
                <div className="d-block">
                    <small className='d-flex align-items-center text-danger'>
                        <i className='bx bx-x x-size'></i>
                        {t('passwordsMustBeTheSame')}
                    </small>
                </div>
              ) : null
            }
            <input
              autoComplete='off'
              className='form-control mb-2 text-darkgray'
              type="password"
              name="userPasswordVerify"
              id="newUserPasswordVerify"
              placeholder={ t('confirmPasswordPlaceholder') }
              value={ newUserInformation.userPasswordVerify }
              onChange={ handleUserInformation }
              required
            />


            <label htmlFor="newUserCompany">{t('company')}</label>
            <input
              autoComplete='off'
              className='form-control mb-2 text-darkgray'
              type="text"
              name="userCompany"
              id="newUserCompany"
              placeholder={ t('companyPlaceholder') }
              value={ newUserInformation.userCompany }
              onChange={ handleUserInformation }
              onFocus={ emailAndPasswordVerified }
              required
            />

            <label htmlFor="newUserPhone">{t('phone')}</label>
            <input
              autoComplete='off'
              className='form-control mb-2 text-darkgray'
              type="tel"
              name="userPhone"
              id="newUserPhone"
              placeholder={ t('phonePlaceholder') }
              value={ newUserInformation.userPhone }
              onChange={ handleUserInformation }
              onFocus={ emailAndPasswordVerified }
              required
            />

            {/* Input País */}
            <label htmlFor="newUserCountry">{t('country')}</label>
            <Select
              // defaultValue={ selectedCountry }
              onChange={ handleSelectCountry }
              options={ registerStages.countryOptions }
              placeholder={t('countryPlaceholder')}
            />

            <div className='mt-5'>
              <div className='d-flex'>
                <input type="checkbox" className='mt-1 me-2' name='conditionsandpolicy' id='conditionsandpolicy' onChange={ (e) => dispatch( setRegisterStage({ type: 'termsAndConditionsChecked', value: e.target.checked }) ) } />
                <span className='termsandpolicy'>
                {t('termsAndConditionsCheck')} <a href={process.env.REACT_APP_TERMS_URL} target='_blank' rel="noopener noreferrer" className='pointer text-decoration-none text-lightblue'>{t('serviceConditions')}</a> {t('and')} <a href={process.env.REACT_APP_PRIVACY_URL} target='_blank' rel="noopener noreferrer" className='pointer text-decoration-none text-lightblue'>{t('privacyPolicy')}</a>
                </span>
              </div>
            </div>


            {/* Guardado de info en BD */}
            <button
              type="submit"
              className='btn btn-primary w-100 btn-sm my-5'
              disabled={ registerStages.loadingButton || !registerStages.termsAndConditionsChecked }
            >
              {
                registerStages.loadingButton ? (
                  <div>
                    <span className='text-light'>{t('processing')}...</span>
                    <div className="spinner-border text-light loading-button ms-2" role="status">
                        <span className="visually-hidden">...</span>
                    </div>
                  </div>
                ) : t('signUp')
              }
            </button>
          </form>
        </div>
        
        <ToastContainer />
    </>
  )
}

export default CustomSignUpForm