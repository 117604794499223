import { Icon } from '@iconify/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { startDeletingFavorite, startSavingFavorite } from '../store/slices/history/thunks'
import { useState } from 'react'
import { finishProcessingRequestHistory } from '../store/slices/history/historySlice'
import NewsIcon from './NewsIcon'

export const AnalysisTypeFormatter = (props) => {
    const { t } = useTranslation()

    if( props.data.analysistype === 'keyword' || props.data.analysistype === 'hashtag' ){
        return <strong className='text-lightblue'>{ t('sentimentAnalysis') }</strong>
    }
    
    if( props.data.analysistype === 'account' ){
        return <strong className='text-lightblue'>{ t('comparativeAnalysis') }</strong>
    }
}

export const FavoriteFormatter = (props) => {
  
  const { status } = useSelector( state => state.history )
  const isFavorite = JSON.parse(props.data.isfavorite)
  const dispatch = useDispatch()
  const [selectedId, setSelectedId] = useState(null)
  
  
  const handleDeleteFavorite = async() => {
    const analysisid = await props.data.analysisid
    setSelectedId(analysisid)
    const tenantid = await props.data.tenantid
    const socialmedia = await props.data.socialmedia
    dispatch( startDeletingFavorite(analysisid, tenantid, socialmedia) )
    dispatch( finishProcessingRequestHistory() )
  }

  const handleSaveFavorite = async() => {
    const analysisid = await props.data.analysisid
    setSelectedId(analysisid)
    const tenantid = await props.data.tenantid
    const socialmedia = await props.data.socialmedia
    dispatch( startSavingFavorite(analysisid, tenantid, socialmedia) )
    dispatch( finishProcessingRequestHistory() )
  }


  return (
    <div>
      {
        status === 'processing' && selectedId === props.data.analysisid ? (
            <div className="spinner-border history-favorite-loader" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
        ) : (
          (isFavorite && <Icon id='unsaveFavoriteIcon' icon="ic:round-star"
                  className='history-favorite-result' onClick={ handleDeleteFavorite } />)
          || (!isFavorite && <Icon id='saveFavoriteIcon' icon="ic:round-star-outline"
                  className='history-not-favorite-result' onClick={ handleSaveFavorite } />)
        )
      }
    </div>
  )
}

export const SocialMediaFormatter = (props) => {
  if( props.data.socialmedia === 'twitter' ){
      return <span className='d-flex justify-content-center align-items-center mt-1 pe-4'>
                <i className='bx bxl-twitter twitter-history-icon'></i>
             </span>
  }
  
  if( props.data.socialmedia === 'instagram' ){
      return <span className='d-flex justify-content-center align-items-center mt-1 pe-4'>
                <i className='bx bxl-instagram instagram-history-icon'></i>
             </span>
  }
  
  if( props.data.socialmedia === 'news' ){
      return <span className='d-flex justify-content-center align-items-center mt-1 pe-4'>
                <NewsIcon settings={{ location: 'history', mainClass: 'news-myicon-history' }} />
             </span>
  }
}

export default AnalysisTypeFormatter