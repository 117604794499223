import { toast } from "react-toastify";

// Separador de miles
export const nfObject = new Intl.NumberFormat('es-ES');


export const getTimeFormat = async(datePublished) => {
    const date = new Date(datePublished).toLocaleDateString()
    const hour = new Date(datePublished).getHours().toString().length === 1
                                                        ? `0${new Date(datePublished).getHours()}`
                                                        : new Date(datePublished).getHours()
    const min = new Date(datePublished).getMinutes().toString().length === 1
                                                        ? `0${new Date(datePublished).getMinutes()}`
                                                        : new Date(datePublished).getMinutes()
    const dateAndHour = `${date}, ${hour}:${min}hs`
    return dateAndHour
}

export const showSuccessToast = (text) => {
    toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
}

export const showWarnToast = (text) => {
    toast.warn(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
}

export const showErrorToast = (text) => {
    toast.error(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
}


export const processWordCount = (wordCount) => {
    const sortedNegativeWords = []
    const sortedPositiveWords = []
    const allWordsCount = []

    for (let word in wordCount.negativo) {
        sortedNegativeWords.push({text: word, value: wordCount.negativo[word]});
    }
    sortedNegativeWords.sort((a, b) =>  b.value - a.value);

    for (let word in wordCount.positivo) {
        sortedPositiveWords.push({text: word, value: wordCount.positivo[word]});
    }
    sortedPositiveWords.sort((a, b) =>  b.value - a.value);

    for(let i in wordCount.negativo){
        allWordsCount.push({ text: i, value: wordCount.negativo[i] })
    }
    for(let i in wordCount.positivo){
        allWordsCount.push({ text: i, value: wordCount.positivo[i] })
    }
    allWordsCount.sort((a, b) =>  b.value - a.value);

    return { sortedNegativeWords, sortedPositiveWords, allWordsCount }
}