import React from 'react'
import { handleDarkMode } from '../helpers/sidebar'
import feelingsLogo from '../img/feelings-logo-blanco.png'
import feelingsIsotipo from '../img/feelings-isotipo-blanco.png'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'
import { closeSidebar, openSidebar } from '../store/slices/sidebar/sidebarSlice'
import { Link } from "react-router-dom";
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import 'animate.css';
import '../styles/sidebar.css'
import '../styles/userinfo-card.css'


const Sidebar = () => {

    const { user } = useAuth0();
    const { toggleSidebar } = useSelector( state => state.sidebar )
    const { plan } = useSelector( state => state.user )
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const handleSidebarState = () => {
        toggleSidebar ? dispatch( closeSidebar() ) : dispatch( openSidebar() )
    }

    useEffect(() => {
        document.querySelector('#sidebar-toggle-arrow').click()
    }, [])
    

  return (
    <nav className="sidebar close">
        <header>
            <Link to="/">
                <div className={ toggleSidebar ? 'sidebar-logo' : 'sidebar-isotipo' }>
                {
                    toggleSidebar ? (
                        <img src={ feelingsLogo } width='222px' alt="Feelings" />
                    ) : (
                        <img src={ feelingsIsotipo } width='60px' alt="Feelings" />
                    )
                }
                </div>
            </Link>

            <i id='sidebar-toggle-arrow' className='bx bx-chevron-right toggle' onClick={ handleSidebarState }></i>
        </header>

        <div className="menu-bar">
            <div className="menu">

                <div className='d-flex justify-content-center my-4'>
                    <span className={ toggleSidebar ? 'customer-plan font13' : 'customer-plan font10'}>{ plan.toUpperCase() }</span>
                </div>

                <ul className="menu-links">
                    <li className="nav-link">
                        <Link to='/'>
                            <i className='bx bxs-dashboard icon'></i>
                            <span className="text nav-text">{ t('home') }</span>
                        </Link>
                    </li>

                    <li className="nav-link">
                        <Link to="/analysis">
                            <i className='bx bx-search icon'></i>
                            <span className="text nav-text">{ t('search') }</span>
                        </Link>
                    </li>

                    <li className="nav-link">
                        <Link to='/history'>
                            <i className='bx bx-history icon'></i>
                            <span className="text nav-text">{ t('history') }</span>
                        </Link>
                    </li>

                    <li className="nav-link d-none">
                        <Link to='/analysis'>
                            <i className='bx bx-pie-chart-alt icon'></i>
                            <span className="text nav-text">{ t('schedules') }</span>
                        </Link>
                    </li>

                    <li className="nav-link">
                        <a href={process.env.REACT_APP_HELP_URL} target='_blank' rel="noopener noreferrer" className='pointer text-decoration-none'>
                            <i className='bx bx-help-circle icon'></i>
                            <span className="text nav-text">{ t('help') }</span>
                        </a>
                    </li>

                </ul>
            </div>


            <div className="bottom-content">

                <label htmlFor="user-info">
                    <li className='pointer user-menu'>
                        <img src={user.picture} className='profile-img icon-img' alt='' />
                        <span className='prof-name-font'>{user.name}</span>
                    </li>
                </label>
                

                <li className="mode d-flex justify-content-start">
                    {
                        toggleSidebar && (
                            <>
                                <div className="sun-moon">
                                    <i className='bx bx-moon icon-mode moon'></i>
                                    <i className='bx bx-sun icon sun'></i>
                                </div>
                                <span className="text mode-text">Dark mode</span>
                            </>
                        )
                    }

                    <div className="toggle-switch" onClick={ handleDarkMode }>
                        <span className="switch"></span>
                    </div>
                </li>
                
            </div>
        </div>
    </nav>
  )
}

export default Sidebar