import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../store/slices/modal/modalSlice';
import { Link } from 'react-router-dom';


const UserBoxInfo = () => {
    
    const { t } = useTranslation();
    const { logout, user } = useAuth0();
    const { toggleSidebar } = useSelector( state => state.sidebar )
    const dispatch = useDispatch();


    const handleOpenUserModal = (e) => {
        // Botón seleccionado por el usuario
        const optionSelected = e.target.id;
        dispatch( openModal(optionSelected) )
    }
    


  return (
    <div>
        <input type="checkbox" className="user-info d-none" name="" id="user-info"/>
        <div className={ !toggleSidebar ? 'card' : 'card card-sidebaropen' }>
            <label htmlFor="user-info" className='pointer ms-auto'>
                <i className='bx bx-x'></i>
            </label>
            <div className="card-information">
                <img className="profile-img" src={user.picture} alt='' />
                <h6 className="card__title mt-2 mb-0">
                    {user.name}
                </h6>
                <p className="mb-0 mt-1">
                    {user.email}
                </p>
            </div>
    
            <div className="horizontal-line"></div>

            <div
                className="d-flex align-items-center card-actions"
                id='settings'
                value='settings'
                onClick={ handleOpenUserModal }
            >
                <div className='d-flex align-items-center'>
                    <i className='bx bx-cog me-2' id='settings' onClick={ handleOpenUserModal }></i>
                    <p className="mb-0" id='settings' onClick={ handleOpenUserModal }>
                        {t('settings')}
                    </p>
                </div>
            </div>
            
            <div
                className="d-flex align-items-center justify-content-start card-actions"
                id='about'
                onClick={ handleOpenUserModal }
            >
                <i className='bx bx-info-circle me-2' id='about' onClick={ handleOpenUserModal }></i>
                <p className="mb-0" id='about' onClick={ handleOpenUserModal }>
                    {t('about')}
                </p>
            </div>
            
            <Link
                to='/upgrade-plan'
                className="d-flex align-items-center justify-content-start card-actions text-decoration-none"
            >
                <i className='bx bx-user-pin me-2'></i>
                <p className="mb-0">
                    {t('changePlan')}
                </p>
            </Link>
    
            <div className="horizontal-line"></div>
                
            <div
                className="d-flex flex-row align-items-center justify-content-start card-actions"
                onClick={ () => logout({ returnTo: window.location.origin }) }
            >
                <i className='bx bx-log-out me-2'></i>
                <span className="text">
                    {t('logout')}
                </span>
            </div>
        </div>
    </div>
  )
}

export default UserBoxInfo