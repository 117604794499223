import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    status: 'ready', // ready, processing
    allPlansAvailable: [],
    planSelected: ''
}

export const planSlice = createSlice({
    name: 'plan',
    initialState,
    reducers: {
        getPlans: (state, {payload}) => {
            state.allPlansAvailable = payload
            state.status = 'ready';
        },
        startProcessingRequestPlan: (state) => {
            state.status = 'processing';
        },
        startSelectPlan: (state, {payload}) => {
            state.status = 'ready';
            state.planSelected = payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { getPlans, startProcessingRequestPlan, startSelectPlan } = planSlice.actions