import React from 'react'
import { useTranslation } from 'react-i18next';

const Loader = () => {
    
  const { t } = useTranslation();


  return (
    <div className='loader'>
        <span className='text-lightblue loader-text me-3'>
            {t('loading')}...
        </span>
        <div className="spinner-grow text-lightblue" role="status">
            <span className="visually-hidden">...</span>
        </div>
    </div>
  )
}

export default Loader