import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


const InstagramSearchParameters = () => {

    const { t } = useTranslation()
    const { instagramSearchParameters } = useSelector( state => state.instagram )

  return (
    <div className='d-flex flex-column'>
        <div className="d-flex flex-column align-items-center mb-3">
            <h5 className='sentiment-result-charts-title mb-0'>{ t('searchParameters') }</h5>
            <small className='sentiment-result-charts-subtitle'>{ t('searchParametersConfig') }</small>
        </div>

        <div className='search-parameters-container'>
            <div className='d-flex flex-column search-parameters-name'>
                <h4 className='search-parameters mb-0'>{ instagramSearchParameters.requestName }</h4>
                <small className='search-parameters-description'>{ t('analysisName') }</small>
            </div>

            <div className='d-flex'>
            {
                instagramSearchParameters.hashtags.length > 0 && (
                    <div className='d-flex flex-column search-parameters-info'>
                        <h4 className='search-parameters mb-0'>{ instagramSearchParameters.hashtags}</h4>
                        <small className='search-parameters-description'>{ t('analizedHashtag') }</small>
                    </div>
                )
            }
{/* 
            {
                instagramSearchParameters.requestAccounts[0] !== '' && (
                    <div className='d-flex flex-column'>
                        {
                            instagramSearchParameters.requestAccounts.map( (account, index) => (
                                <div key={index} className='d-flex flex-column search-parameters-info'>
                                    <h4 className='search-parameters mb-0'>{ account }</h4>
                                    <small className='search-parameters-description'>Cuenta</small>
                                </div>
                            ))
                        }
                    </div>
                )
            } */}

            {/* {
                instagramSearchParameters.requestAmount && (
                    <div className='d-flex flex-column search-parameters-info'>
                        <h4 className='search-parameters mb-0'>{ instagramSearchParameters.requestAmount }</h4>
                        <small className='search-parameters-description'>Cantidad solicitada</small>
                    </div>
                )
            } */}
            </div>

            <div className='d-flex'>
                <div className='d-flex flex-column search-parameters-info'>
                    <h4 className='search-parameters mb-0'>{ instagramSearchParameters.requestType }</h4>
                    <small className='search-parameters-description'>{ t('analysisType') }</small>
                </div>
                {/* {
                    instagramSearchParameters.requestLanguage && (
                        <div className='d-flex flex-column search-parameters-info'>
                            <h4 className='search-parameters mb-0'>
                                { instagramSearchParameters.requestLanguage }
                            </h4>
                            <small className='search-parameters-description'>Idioma</small>
                        </div>
                    )
                } */}

                {/* {
                    instagramSearchParameters.requestAccounts[0] !== '' && (
                        <div className='d-flex flex-column search-parameters-info'>
                            <h4 className='search-parameters mb-0'>{ instagramSearchParameters.requestAccounts.length }</h4>
                            <small className='search-parameters-description'>Cantidad de cuentas</small>
                        </div>
                    )
                } */}
            </div>

            <div className="d-flex">
                {
                    instagramSearchParameters.requestDate && (
                        <div className='d-flex flex-column search-parameters-info'>
                            <h6 className='search-parameters mb-0 w-100'>
                                { instagramSearchParameters.requestDate }
                            </h6>
                            <small className='search-parameters-description'>
                            { t('analysisDate') }
                            </small>
                        </div>
                    )
                }
            </div>
        </div>
    </div>
  )
}

export default InstagramSearchParameters