import React from 'react'
import { useSelector } from 'react-redux';
import { Outlet } from "react-router-dom";
import AccountDataIcons from '../../charts/twitter/accounts/AccountDataIcons';
import SentimentDataIcons from '../../charts/twitter/sentiments/SentimentDataIcons';
import NavBarResults from '../../NavBarResults';
import TwitterSearchParameters from './TwitterSearchParameters';


const TwitterResultScreen = () => {
  
  const { twitterSearchParameters } = useSelector( state => state.twitter )
  
  return (
    <div className='container-fluid pe-0 bg-lightgray'>
      <NavBarResults socialMedia={'twitter'} />

      {
        (twitterSearchParameters.requestType === 'keyword') && (
          <div className='row d-flex w-100 py-2 my-4 mx-0'>
            <div className="col-4 d-flex justify-content-center">
              <TwitterSearchParameters />
            </div>
            <div className="col d-flex flex-column">
              <SentimentDataIcons />
            </div>
          </div>
        )
      }

      {
        (twitterSearchParameters.requestType === 'account') && (
          <div className='row d-flex justify-content-center align-items-start w-100 py-2 my-4 mx-0'>
            <div className="col-4 d-flex justify-content-center">
              <TwitterSearchParameters />
            </div>
            <div className="col d-flex justify-content-center align-items-center">
              <AccountDataIcons />
            </div>
          </div>
        )
      }

      <Outlet />
    </div>
  )
}

export default TwitterResultScreen