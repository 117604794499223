import React from 'react'
import twitterVerified from '../img/twitter_verified.png'

const VerifiedFormatter = (props) => {
    
    const verified = props.data.is_verified

    if( verified ){
        return <div className='d-flex justify-content-center align-items-center my-2'>
                  <img src={twitterVerified} width={25} alt="" />
               </div>
    }

  return (
    <div className='d-flex justify-content-center align-items-center'>-</div>
  )
}

export default VerifiedFormatter