import React from 'react'
import { setSearchAccounts, setNewsSearchParameters } from '../../../store/slices/news/newsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const NewsSearchInput = () => {

    const { newsSearchParameters, status } = useSelector( state => state.news )
    const { globalSettings } = useSelector( state => state.settings )
    const { historyCount } = useSelector( state => state.history )
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const handleSelectLanguage = () => {
        const name = 'requestLanguage'
        const value = document.querySelector('#language').selectedOptions[0].value;
        dispatch( setNewsSearchParameters({name, value}) )
    }

    const handleSelectResultsType = () => {
        const name = 'resultsType'
        const value = document.querySelector('#resultsType').selectedOptions[0].value;
        dispatch( setNewsSearchParameters({name, value}) )
    }


    const handleSetNewsSearchParameters = (e) => {
        const { name, value } = e.target;

        if( name === 'requestAmount' && value > 100 ){
            console.log('No se pueden solicitar más de 100 artículos de noticias por request')
        } else {
            dispatch( setNewsSearchParameters({name, value}) )
        }
    }


    useEffect(() => {
        dispatch( setNewsSearchParameters({name: 'requestType', value: 'keyword'}) )
    }, [])
    

  return (
    <div>
        <ul className="nav nav-pills" role="tablist">
            <li className="nav-item">
                <div
                    className={ newsSearchParameters.requestType === 'keyword' ? 'nav-link' : 'nav-link pointer' }
                    data-toggle="pill"
                    onClick={ () => dispatch( setNewsSearchParameters({name: 'requestType', value: 'keyword'}) ) }
                >
                    { t('sentimentAnalysis') }
                </div>
            </li>
        </ul>


        <div className="tab-content">
            <div className={ newsSearchParameters.requestType === 'keyword' ? 'container tab-pane active' : 'd-none' }>
                <div className="row mb-4">
                    <div className="col-6 d-flex flex-column justify-content-end">
                        <label htmlFor='search-name'>
                            <small className='label-font'>{ t('analysisName') }</small>
                        </label>
                        <div className="d-flex align-items-center">
                            <input
                                autoComplete='off'
                                id='search-name'
                                className='form-control form-control-sm params-input'
                                type="text"
                                maxLength={100}
                                placeholder={ t('analysisNamePlaceholder') }
                                name='requestName'
                                value={ newsSearchParameters.requestName }
                                onChange={ handleSetNewsSearchParameters }
                                disabled={ status === 'processing' }
                            />
                        </div>
                    </div>
                    
                    <div className="col-3 d-flex flex-column justify-content-end">
                        <label className='label-font small-label-title' htmlFor="search-amount">
                            <small className=''>{ t('numberOfarticles') }</small>
                        </label>
                        <input
                            autoComplete='off'
                            id='search-amount'
                            className='form-control form-control-sm params-input'
                            type="number"
                            min={10}
                            max={100}
                            placeholder={ t('analysisNamePlaceholder') }
                            name='requestAmount'
                            value={ newsSearchParameters.requestAmount }
                            onChange={ handleSetNewsSearchParameters }
                            disabled={ status === 'processing' }
                        />
                    </div>
                    
                    {/* <div className="col-3 d-flex flex-column justify-content-end">
                        <label className='label-font' htmlFor="language">
                            <small>{ t('language') }</small>
                        </label>
                        <select
                            className='form-control form-control-sm params-input'
                            name="requestLanguage"
                            id="language"
                            onChange={ handleSelectLanguage }
                            disabled={ status === 'processing' }
                        >
                            { i18n.resolvedLanguage === 'es' ? (
                                    <>
                                        <option value="es" defaultValue>{ t('spanish') }</option>
                                        <option value="en">{ t('english') }</option>
                                    </>
                                ) : (
                                    <>
                                        <option value="en" defaultValue>{ t('english') }</option>
                                        <option value="es">{ t('spanish') }</option>
                                    </>
                                )
                            }
                        </select>
                    </div> */}
                </div>

                <div className="row">
                    <div className="col-12">
                        <input
                            autoComplete='off'
                            type="text"
                            className="form-control custom-input"
                            placeholder={ t('mainInputPlaceholder') }
                            name='requestKeyword'
                            value={ newsSearchParameters.requestKeyword }
                            onChange={ handleSetNewsSearchParameters }
                            disabled={ status === 'processing' }
                        />
                    </div>
                </div>

                <div className="row d-flex justify-content-between mt-4">
                    {/* <div className="d-flex justify-content-start align-items-center d-none">
                        <label className='label-font' htmlFor="select-date">
                            <small>Seleccionar un rango de fecha</small>
                        </label>
                        <input type="checkbox" name="select-date" id="select-date" className='ms-2' onChange={ handleDateCheckbox } />
                    </div>
                    <div className="col-3">
                        <label className='label-font d-none' htmlFor="date-from">
                            <small>Desde</small>
                        </label>
                        <input id='date-from' className='form-control form-control-sm params-input d-none' type="date" />
                    </div>
                    <div className="col-3">
                        <label className='label-font d-none' htmlFor="date-to">
                            <small>Hasta</small>
                        </label>
                        <input id='date-to' className='form-control form-control-sm params-input d-none' type="date" />
                    </div> */}

                    <div className="col-8 d-flex flex-column align-items-start">
                        <small className='text-mediumblue specification-font'>
                            { t('newsAccordingPlan') }: <span className='text-lightblue'>100</span>
                        </small>
                        <small className='text-mediumblue specification-font'>
                            { t('totalDoneAnalyzes') }: <span className='text-lightblue'>{ historyCount } { t('outOf') } { globalSettings.userPlanSettings.max_requests }</span>
                        </small>
                        <small className='text-mediumblue specification-font'>
                            { t('remainingAnalysis') }: <span className='text-lightblue'>{ globalSettings.userPlanSettings.max_requests - historyCount }</span>
                        </small>
                    </div>
                    
                    <div className="col-3 d-flex flex-column justify-content-end">
                        <label className='label-font small-label-title' htmlFor="language">
                            <small>{ t('ancientNews') }</small>
                        </label>
                        <select
                            className='form-control form-control-sm params-input'
                            name="requestLanguage"
                            id="resultsType"
                            defaultValue={'Day'}
                            onChange={ handleSelectResultsType }
                            disabled={ status === 'processing' }
                        >
                            <option value="Day">{ t('day') }</option>
                            <option value="Week">{ t('week') }</option>
                            <option value="Month">{ t('month') }</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NewsSearchInput