import { fetchBackend } from "./fetch"


export const getTenantSettings = async(tenantid) => {
    const resp = await fetchBackend(`tenant-settings/${tenantid}`, 'GET')
    const respJson = await resp.json()
    const settingsData = await JSON.parse(respJson.data.setting).productos

    return settingsData
}


export const checkInstagramCredentialsExpiration = async(globalSettings) => {
    const todayDate = new Date()
    const instagramTokenExpirationDate = new Date(globalSettings.instagram_credentials.expiration_date)
    const body = {'global_settings': globalSettings}
    let igTokenUpdated = false

    if(todayDate > instagramTokenExpirationDate){
        // Renovar token de instagram si expiró
        const renewTokenRequest = await fetchBackend('instagram/token/renew', body, 'POST')
        const renewTokenResponse = await renewTokenRequest.json()
        
        igTokenUpdated = (renewTokenResponse.affectedRows === 1)
    }

    return igTokenUpdated
}
