import React from 'react'
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../../Navbar'
import NewsInputs from './NewsInputs';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { openModal } from '../../../store/slices/modal/modalSlice';
import '../../../styles/inputs.css'


const NewsSearchDashboard = () => {
    
    const { status } = useSelector( state => state.news )
    const dispatch = useDispatch();
    const { t } = useTranslation();

    
    const handleOpenUserModal = async() => {
        dispatch( openModal('delayRequestNotification') )
    }


  return (
    <>
        <Navbar />

        <div className="container-fluid mt-4">
            <div className="row">
                <h1 className='text-mediumblue text-center mb-0'>{ t('newsAnalysis') }</h1>
                <p className='text-center subtitle'>{ t('sentimentOrComparativeAnalysis') }</p>
               
                <div className="col-12 d-flex justify-content-center align-items-center mt-4">
                    <NewsInputs/>
                </div>
                
                <div className="col-12">
                    <div className='d-flex justify-content-center align-items-center'>
                        <button
                            id='delayRequestNotification'
                            className='btn btn-mediumblue mx-auto mb-1'
                            onClick={ handleOpenUserModal }
                            // disabled={ status === 'processing' || ((newsSearchParameters.requestKeyword === '' || newsSearchParameters.requestName === '' || newsSearchParameters.requestLanguage === '' || newsSearchParameters.requestAmount === '') && newsSearchParameters.requestType === 'keyword') || (newsSearchParameters.requestAccounts.some( account => account === '' ) && newsSearchParameters.requestType === 'account') || (newsSearchParameters.requestType === 'account' && newsSearchParameters.requestSelectedAccountIndex === null) || (globalSettings.userPlanSettings.max_requests - historyCount.historicalAnalysisResults === 0) }
                        >
                            {
                                status === 'processing'
                                ? (
                                    <div>
                                        <span className='me-2'>{ t('processing') }...</span>
                                        <div className="spinner-grow spinner-grow-sm" role="status">
                                            <span className="visually-hidden">...</span>
                                        </div>
                                    </div>
                                )
                                : (
                                    <div id='delayRequestNotification'>
                                        <span
                                            id='delayRequestNotification'
                                            className='me-2'
                                        >
                                            { t('search') }
                                        </span>
                                        <Icon icon="bi:search" />
                                    </div>
                                )
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
        
        <ToastContainer />
    </>
  )
}

export default NewsSearchDashboard