import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


const NewsSearchParameters = () => {
    
    const { newsSearchParameters } = useSelector( state => state.news )
    const { t } = useTranslation()


  return (
    <div className='d-flex flex-column'>
        <div className="d-flex flex-column align-items-center mb-3">
            <h5 className='sentiment-result-charts-title mb-0'>{ t('searchParameters') }</h5>
            <small className='sentiment-result-charts-subtitle'>{ t('searchParametersConfig') }</small>
        </div>

        <div className='search-parameters-container'>
            <div className='d-flex flex-column search-parameters-name'>
                <h4 className='search-parameters mb-0'>{ newsSearchParameters.requestName }</h4>
                <small className='search-parameters-description'>{ t('analysisName') }</small>
            </div>

            <div className='d-flex'>
            {
                newsSearchParameters.requestKeyword && (
                    <div className='d-flex flex-column search-parameters-keyword'>
                        <h4 className='search-parameters mb-0'>{ newsSearchParameters.requestKeyword }</h4>
                        <small className='search-parameters-description'>{ t('analyzedWord') }</small>
                    </div>
                )
            }
            </div>
            
            <div className="d-flex">
            {
                newsSearchParameters.requestAmount && (
                    <div className='d-flex flex-column search-parameters-info'>
                        <h4 className='search-parameters mb-0'>{ newsSearchParameters.requestAmount }</h4>
                        <small className='search-parameters-description'>{ t('articlesRequested') }</small>
                    </div>
                )
            }
            
            {
                newsSearchParameters.resultsType && (
                    <div className='d-flex flex-column search-parameters-info'>
                        <h4 className='search-parameters mb-0'>{ t(newsSearchParameters.resultsType.toLowerCase()) }</h4>
                        <small className='search-parameters-description'>{ t('resultsType') }</small>
                    </div>
                )
            }
            </div>

            <div className='d-flex'>
                <div className='d-flex flex-column search-parameters-info'>
                    <h4 className='search-parameters mb-0'>{ newsSearchParameters.requestType }</h4>
                    <small className='search-parameters-description'>{ t('analysisType') }</small>
                </div>
                {
                    newsSearchParameters.requestLanguage && (
                        <div className='d-flex flex-column search-parameters-info'>
                            <h4 className='search-parameters mb-0'>
                                {
                                    (newsSearchParameters.requestLanguage === 'es' && t('spanish'))
                                    || t('english')
                                }
                            </h4>
                            <small className='search-parameters-description'>{ t('language') }</small>
                        </div>
                    )
                }
            </div>

            <div className="d-flex">
                {
                    newsSearchParameters.requestDate && (
                        <div className='d-flex flex-column search-parameters-info'>
                            <h6 className='search-parameters mb-0 w-100'>
                                { newsSearchParameters.requestDate }
                            </h6>
                            <small className='search-parameters-description'>
                            { t('analysisDate') }
                            </small>
                        </div>
                    )
                }
            </div>
        </div>
    </div>
  )
}

export default NewsSearchParameters