import { checkBadResponse, createNewUserInDB, registerUserWithEmailAndPassword } from "../../../helpers/register"
import { setRegisterStage } from "./registerSlice"



export const startRegisterNewUser = (data) => {
    return async( dispatch ) => {
        let auth0SignUp;
        const respJson = await createNewUserInDB(data)
        await checkBadResponse(respJson)

        if( respJson.ok ){
            // Alta en Auth0
            auth0SignUp = await registerUserWithEmailAndPassword(data)
        } else {
            dispatch( setRegisterStage({ type: 'loadingButton', value: false }) )
        }

        // Si el plan es pago, redirigir a Stripe
        if( auth0SignUp.ok && data.newUserInformation.selectedPlan === 'trial' ){
            if(data.newUserInformation.userlanguage === 'es'){
                dispatch( setRegisterStage({ type: 'message', value: 'Gracias por registrarte' }) )
            } else {
                dispatch( setRegisterStage({ type: 'message', value: 'Thank you for registering' }) )
            }
        } else if( auth0SignUp.ok && data.newUserInformation.selectedPlan !== 'trial' ){
            const paymentButton = document.querySelector('#stripeButton')
            await paymentButton.click();
        } else {
            dispatch( setRegisterStage({ type: 'loadingButton', value: false }) )
        }
        
        return respJson
    }
}
