import React from 'react'
import { useSelector } from 'react-redux';
import { Outlet } from "react-router-dom";
import SentimentDataIcons from '../../charts/news/sentiments/SentimentDataIcons';
import NavBarResults from '../../NavBarResults';
import NewsSearchParameters from './NewsSearchParameters';


const NewsResultScreen = () => {
  
  const { newsSearchParameters } = useSelector( state => state.news )
  
  return (
    <div className='container-fluid pe-0 bg-lightgray'>
      <NavBarResults socialMedia={'news'} />

      {
        (newsSearchParameters.requestType === 'keyword') && (
          <div className='row d-flex w-100 py-2 my-4 mx-0'>
            <div className="col-4 d-flex justify-content-center">
              <NewsSearchParameters />
            </div>
            <div className="col d-flex flex-column">
              <SentimentDataIcons />
            </div>
          </div>
        )
      }

      <Outlet />
    </div>
  )
}

export default NewsResultScreen