import React from 'react'
import { useTranslation } from 'react-i18next';
import HistoryAnalysisTable from '../tables/HistoryAnalysisTable';

const History = () => {

  const { t } = useTranslation();

  return (
    <div className='home'>
        <h2 className='text-mediumblue ms-5 mt-3 mb-0'>{ t('analysisHistory') }</h2>
        <p className='page-subtitle'>
          { t('searchDoneUpToNow') }
        </p>
        
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 mt-4">
                    <HistoryAnalysisTable />
                </div>
            </div>
        </div>
    </div>
  )
}

export default History