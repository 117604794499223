import React from 'react'
import LikesEvolutionLineChart from '../../charts/twitter/accounts/LikesEvolutionLineChart';
import AccountsResultTable from '../../tables/twitter/AccountsResultTable';
import RetweetsEvolutionLineChart from '../../charts/twitter/accounts/RetweetsEvolutionLineChart';
import ComparativeAccountsbarChart from '../../charts/twitter/accounts/ComparativeAccountsbarChart';
import AccountsRankingTable from '../../tables/twitter/AccountsRankingTable';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


const TwitterAccountsResult = () => {

    const { twitterSearchParameters } = useSelector( state => state.twitter )
    const { t } = useTranslation()

    
  return (
    <>
        <div className="container-fluid">
            <div className="row w-100 mb-5">
                <div className='mb-2'>
                    <h3 className='text-center selected-account-charts-title mb-0'>
                        { t('accountAnalysis') }
                    </h3>
                    <h5 className='text-center selected-account-charts-subtitle'>
                        { t('accountAnalysisResult') } @{ twitterSearchParameters.requestSelectedAccount }
                    </h5>
                </div>
                <div className="col-6 py-2">
                    <LikesEvolutionLineChart />
                </div>
                <div className="col-6 py-2">
                    <RetweetsEvolutionLineChart />
                </div>
            </div>
            <div className="row w-100">
                <div className='mb-2'>
                    <h3 className='text-center selected-account-charts-title mb-0'>
                        { t('comparativeAnalysis') }
                    </h3>
                    <h5 className='text-center selected-account-charts-subtitle'>
                        { t('comparativeAnalysisResult') }
                    </h5>
                </div>
                <div className="col-6 py-2">
                    <ComparativeAccountsbarChart />
                </div>
                <div className="col-6 py-2">
                    <AccountsRankingTable />
                </div>
            </div>

            <div className="row">
                <AccountsResultTable />
            </div>
        </div>
    </>
  )
}

export default TwitterAccountsResult