import { getTenantSettings } from "../../../helpers/settings"
import { getPlans, startProcessingRequestPlan } from "./planSlice"


export const startGettingAllPlans = (tenantid) => {
    return async( dispatch ) => {
        dispatch( startProcessingRequestPlan() )
        const settingsJson = await getTenantSettings(tenantid)
        dispatch( getPlans(settingsJson) )
    }
}
