import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react'
import NewsIcon from '../NewsIcon'
import { startDeletingFavorite } from '../../store/slices/history/thunks';
import '../../styles/home.css'
import { Link } from 'react-router-dom';
import { setParametersAndResultsFromHistoryInstagram } from '../../store/slices/instagram/instagramSlice';
import { cleanAccountSearchParams, cleanKeywordSearchParams, setParametersAndResultsFromHistory } from '../../store/slices/twitter/twitterSlice';
import { useTranslation } from 'react-i18next';
import { cleanNewsKeywordSearchParams, setNewsParametersAndResultsFromHistory } from '../../store/slices/news/newsSlice';

const FavoritesAnalysisCards = () => {

  const { favoritesAnalysis, status } = useSelector( state => state.history )
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [selectedId, setSelectedId] = useState(null)

  
  const handleDeleteFavorite = async(history) => {
    setSelectedId(history.analysisid)
    dispatch( startDeletingFavorite(history.analysisid, history.tenantid, history.socialmedia) )
  }

  
  const handleSelectTwitterAnalysis = async(history) => {
    const parameters = await JSON.parse(history.enteredconfiguration)
    const analysisId = await history.analysisid
    const isFavorite = await JSON.parse(history.isfavorite)
    const results = await JSON.parse(history.results)
    const hour = new Date(history.datecreated).getHours()
    const min =  new Date(history.datecreated).getMinutes().toString().length === 1 ? `0${new Date(history.datecreated).getMinutes()}` : new Date(history.datecreated).getMinutes()
    const requestDate = `${history.datecreated_format} ${hour}:${min}hs`

    dispatch( cleanKeywordSearchParams() )
    dispatch( cleanAccountSearchParams() )
    dispatch( setParametersAndResultsFromHistory( {parameters, results, requestDate, isFavorite, analysisId} ) )
}

  
const handleSelectNewsAnalysis = async(history) => {
    const parameters = await JSON.parse(history.enteredconfiguration)
    const analysisId = await history.analysisid
    const isFavorite = await JSON.parse(history.isfavorite)
    const results = await JSON.parse(history.results)
    const hour = new Date(history.datecreated).getHours()
    const min =  new Date(history.datecreated).getMinutes().toString().length === 1 ? `0${new Date(history.datecreated).getMinutes()}` : new Date(history.datecreated).getMinutes()
    const requestDate = `${history.datecreated_format} ${hour}:${min}hs`

    dispatch( cleanNewsKeywordSearchParams() )
    dispatch( setNewsParametersAndResultsFromHistory( {parameters, results, requestDate, isFavorite, analysisId} ) )
}


const handleSelectInstagramAnalysis = async(history) => {
  const parameters = await JSON.parse(history.enteredconfiguration)
  const analysisId = await history.analysisid
  const isFavorite = await JSON.parse(history.isfavorite)
  const results = await JSON.parse(history.results)
  const hour = new Date(history.datecreated).getHours()
  const min =  new Date(history.datecreated).getMinutes().toString().length === 1 ? `0${new Date(history.datecreated).getMinutes()}` : new Date(history.datecreated).getMinutes()
  const requestDate = `${history.datecreated_format} ${hour}:${min}hs`

  dispatch( setParametersAndResultsFromHistoryInstagram( {parameters, results, requestDate, isFavorite, analysisId} ) )
}

  return (
    <div className='home'>
        <h1 className='page-title'>{ t('favoritesAnalysis') }</h1>
        <p className='page-subtitle'>{ t('favoritesAnalysisSubtitle') }</p>

        <div className="container-fluid">
            <div className="row pb-5">
                {
                    favoritesAnalysis.map( history => (
                        <div key={history.analysisid} className='col-4 d-flex justify-content-center mt-4'>
                            <div className="favorite-card">
                                <div className='header-card'>
                                    <div className='d-flex'>
                                        <div className='card-icon-corner'>
                                            {
                                                (history.socialmedia === 'twitter' && <Icon icon="logos:twitter" className='twitter-card-icon' />)
                                                || (history.socialmedia === 'instagram' && <i className='bx bxl-instagram instagram-history-icon'></i>)
                                                || (history.socialmedia === 'news' && <NewsIcon settings={{ location: 'history', mainClass: 'news-myicon-history' }}/>)
                                            }
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <span className='mt-2 mb-0 card-title'>
                                                { history.searchname }
                                            </span>
                                            <small className="text-91 card-detail">
                                                { t('analysisMadeOn') } { history.datecreated_format }
                                            </small>
                                            <small className="text-91 card-detail">
                                                { t('at') } {new Date(history.datecreated).getHours()}:{new Date(history.datecreated).getMinutes().toString().length === 1 ? `0${new Date(history.datecreated).getMinutes()}` : new Date(history.datecreated).getMinutes()}hs
                                            </small>
                                        </div>
                                    </div>

                                    {
                                        status === 'processing' && history.analysisid === selectedId ? (
                                            <div className="spinner-border favorite-loader mt-2" role="status">
                                                <span className="visually-hidden">...</span>
                                            </div>
                                        ) : (
                                            <Icon
                                                id='unsaveFavoriteIcon'
                                                icon="ic:round-star"
                                                className='favorite-result'
                                                onClick={ () => handleDeleteFavorite(history) }
                                            />
                                        )
                                    }
                                </div>
                                
                                <div className="body-card">
                                    <div>
                                        <span className='data-header'>{ t('analysisType') }: </span>
                                        <span className='data-response'>
                                            { history.analysistype }
                                        </span>
                                    </div>
                                    <div>
                                        <span className='data-header'>{ t('analyzedWord') }: </span>
                                        <span className='data-response'>
                                            { JSON.parse(history.enteredconfiguration).keyword }
                                        </span>
                                    </div>
                                    <div>
                                        <span className='data-header'>{ t('language') }: </span>
                                        <span className='data-response'>
                                            {
                                                ((JSON.parse(history.enteredconfiguration).language === 'es') && t('spanish'))
                                                || ((JSON.parse(history.enteredconfiguration).language === 'en') && t('english'))
                                                || '-'
                                            }
                                        </span>
                                    </div>
                                    <div>
                                        <span className='data-header'>{ t('itemsRequested') }: </span>
                                        <span className='data-response'>
                                            { JSON.parse(history.enteredconfiguration).amount }
                                        </span>
                                    </div>
                                    <div>
                                        <span className='data-header'>{ history.socialmedia === 'news' ? t('antiquity') : t('resultsType') }: </span>
                                        <span className='data-response'>
                                            {
                                                JSON.parse(history.enteredconfiguration).resultstype
                                                ? t(JSON.parse(history.enteredconfiguration).resultstype.toLowerCase())
                                                : '-'
                                            }
                                        </span>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-end align-items-end'>
                                {
                                    history.socialmedia === 'twitter' && (
                                        <Link
                                            className='footer-card text-decoration-none mt-4'
                                            to={ history.analysistype === 'keyword' ? '/analysis/twitter/charts-sentiment' : '/analysis/twitter/charts-data' }
                                            onClick={ () => handleSelectTwitterAnalysis(history) }
                                        >
                                            <div>
                                                <small>{ t('seeAnalysis') }</small>
                                                <div className="underline-footer"></div>
                                            </div>
                                        </Link>
                                    )
                                }

                                {
                                    history.socialmedia === 'instagram' && (
                                        <Link
                                            className='footer-card text-decoration-none mt-4'
                                            to={ history.analysistype === 'keyword' ? '/analysis/instagram/charts-sentiment' : '/analysis/instagram/charts-data' }
                                            onClick={ () => handleSelectInstagramAnalysis(history) }
                                        >
                                            <div>
                                                <small>{ t('seeAnalysis') }</small>
                                                <div className="underline-footer"></div>
                                            </div>
                                        </Link>
                                    )
                                }
                                
                                {
                                    history.socialmedia === 'news' && (
                                        <Link
                                            className='footer-card text-decoration-none mt-4'
                                            to={ history.analysistype === 'keyword' ? '/analysis/news/charts-sentiment' : '/analysis/news/charts-data' }
                                            onClick={ () => handleSelectNewsAnalysis(history) }
                                        >
                                            <div>
                                                <small>{ t('seeAnalysis') }</small>
                                                <div className="underline-footer"></div>
                                            </div>
                                        </Link>
                                    )
                                }
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    </div>
  )
}

export default FavoritesAnalysisCards