import React from 'react'
import LineChartInstagram from '../../charts/instagram/sentiments/LineChartInstagram'
import UniqueBarChartInstagram from '../../charts/instagram/sentiments/UniqueBarChartInstagram'
import WordCloudInstagram from '../../charts/instagram/sentiments/WordCloudInstagram'
import MultilineChartInsatgram from '../../charts/instagram/sentiments/MultilineChartInstagram'
import PositiveWordsBarChartInstagram from '../../charts/instagram/sentiments/PositiveWordsBarChartInstagram'
import NegativeWordsBarChartInstagram from '../../charts/instagram/sentiments/NegativeWordsBarChartInstagram'
import { useTranslation } from 'react-i18next'

const InstagramSentimentCharts = () => {
  
  const { t } = useTranslation()

  return (
    <>
      <div className="row d-flex w-100">
        <div className='my-2'>
            <h5 className='text-center sentiment-result-charts-title mb-0'>
                { t('sentimentAnalysis') }
            </h5>
            <h6 className='text-center sentiment-result-charts-subtitle'>
                { t('sentimentAnalysisResult') }
            </h6>
        </div>
        
        <div className="col-6 py-2">
          <UniqueBarChartInstagram />
        </div>
        <div className="col-6 py-2">
          <LineChartInstagram />
        </div>
        <div className="col-7 py-2">
          <MultilineChartInsatgram />
        </div> 
        <div className="col-5 py-2">
            <WordCloudInstagram />
          </div>
          <div className="col-6 py-2">
            <PositiveWordsBarChartInstagram />
          </div>
          <div className="col-6 py-2">
            <NegativeWordsBarChartInstagram />
          </div>
      </div>
    </>
  )
}

export default InstagramSentimentCharts