// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.twitter-card-container{
    padding-bottom: 20px;
}

.twitter-card{
    border: 1px solid #000;
    width: 350px;
    padding: 10px;
}

.twitter-card-header{
    display: flex;
    margin-bottom: 10px;
}

.twitter-card-header img{
    width: 50px;
    margin-right: 10px;
}

.twitter-card-header .account-info{
    display: flex;
    flex-direction: column;
}


.tweet-tag {
    background: #cccccc;
    border-radius: 50px;
    font-size: 11px;
    margin: 0;
    color: #fff;
    padding: 1px 8px;
    text-transform: uppercase;
}
.tweet-tag-neutral {
    background-color: #efb810;
}
.tweet-tag-positive {
    background-color: #42ab49;
}
.tweet-tag-negative {
    background-color: #d1052a;
}

div .MuiCardHeader-avatar.css-1ssile9-MuiCardHeader-avatar{
    margin-right: 6px;
}`, "",{"version":3,"sources":["webpack://./src/styles/twitter-card.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;;AAGA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;IACf,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".twitter-card-container{\r\n    padding-bottom: 20px;\r\n}\r\n\r\n.twitter-card{\r\n    border: 1px solid #000;\r\n    width: 350px;\r\n    padding: 10px;\r\n}\r\n\r\n.twitter-card-header{\r\n    display: flex;\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.twitter-card-header img{\r\n    width: 50px;\r\n    margin-right: 10px;\r\n}\r\n\r\n.twitter-card-header .account-info{\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n\r\n.tweet-tag {\r\n    background: #cccccc;\r\n    border-radius: 50px;\r\n    font-size: 11px;\r\n    margin: 0;\r\n    color: #fff;\r\n    padding: 1px 8px;\r\n    text-transform: uppercase;\r\n}\r\n.tweet-tag-neutral {\r\n    background-color: #efb810;\r\n}\r\n.tweet-tag-positive {\r\n    background-color: #42ab49;\r\n}\r\n.tweet-tag-negative {\r\n    background-color: #d1052a;\r\n}\r\n\r\ndiv .MuiCardHeader-avatar.css-1ssile9-MuiCardHeader-avatar{\r\n    margin-right: 6px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
