import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import NewsCard from './NewsCard';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../../styles/table.css'


const NewsSentimentCards = () => {
    
    const { dataKeywordResult } = useSelector( state => state.news )
    const { t } = useTranslation()
  

    return (
        <div>
            <div className='my-5'>
                <h5 className='text-center sentiment-result-charts-title mb-0'>
                    { t('newsArticles') }
                </h5>
                <h6 className='text-center sentiment-result-charts-subtitle'>
                    { t('tableDetail') }
                </h6>
            </div>
            
            <div className='container-fluid pb-5'>
                <div className="row">
                {
                    dataKeywordResult.data.map( article => (
                        <div key={article.url} className="col-4 d-flex justify-content-center pb-3">
                            <NewsCard cardInfo={article}/>
                        </div>
                    ))
                }
                </div>
            </div>
        </div>
    )
}

export default NewsSentimentCards