import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import FollowersFormatter, { EmittedLikesFormatter, FollowingFormatter, PostsFormatter } from '../../NumberFormatter';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const AccountsResultTable = () => {
    
    const { dataAccountsResult, twitterSearchParameters } = useSelector( state => state.twitter )
    const { t } = useTranslation()
          
    const gridRef = useRef();
    const [rowData, setRowData] = useState();
    
    const [columnDefs, setColumnDefs] = useState([
        {
            field: 'screen_name',
            headerName: t('account')
        },
        {
            field: 'posts_count',
            headerName: t('posts'),
            cellRenderer: PostsFormatter
        },
        {
            field: 'amigos_totales',
            headerName: t('following'),
            cellRenderer: FollowingFormatter
        },
        {
            field: 'followers_count',
            headerName: t('followers'),
            cellRenderer: FollowersFormatter
        },
        {
            field: 'likes_totales',
            headerName: t('likesEmitted'),
            cellRenderer: EmittedLikesFormatter
        },
        {
            field: 'alcance',
            headerName: t('scope')
        },
        {
            field: 'efectividad',
            headerName: t('effective')
        }
    ]);
    
    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo( ()=> ({ sortable: true }) );

  
    // Example load data from sever
    useEffect(() => {
        setRowData(dataAccountsResult.data)
    }, []);


    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    
    const rowClassRules = useMemo(() => {
        return {
            'account-selected': (params) => {
                const name = params.data.screen_name.toLowerCase();
                return name === twitterSearchParameters.requestSelectedAccount.toLowerCase();
            }
        };
    }, []);

    
    return (
        <div className='mt-5'>
            <h5 className='chart-title mb-0'>{ t('detail') }</h5>
            <h6 className='text-center text-mediumblue'>{ t('detailTableData') }</h6>
            
            <div className="ag-theme-alpine" style={{width: "100%", height: 280, marginTop: "10px", marginBottom: "20px"}}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    onFirstDataRendered={onFirstDataRendered}
                    rowClassRules={rowClassRules}
                />
            </div>
        </div>
    )
}

export default AccountsResultTable