import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startSelectPlan } from '../store/slices/plans/planSlice';
import { apiBaseUrl } from '../helpers/fetch';
import { startGettingAllPlans } from '../store/slices/plans/thunks';
import { setNewUserInformation } from '../store/slices/register/registerSlice';
import { useTranslation } from 'react-i18next';


const ProductDisplay = () => {

    const { planSelected } = useSelector( state => state.plan )
    const { newUserInformation } = useSelector( state => state.register )
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const handleSelectPlan = (plan) => {
        const name = 'selectedPlan'
        const value = plan.toLowerCase()
        dispatch( startSelectPlan(plan) )
        dispatch( setNewUserInformation({name, value}) )
    }
    
    useEffect(() => {
        dispatch( startGettingAllPlans('cloud_seidoranalytics.com') )
    }, [])
    
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
    
        if (query.get('plan')) {
            let plan = query.get('plan')
            plan = plan.charAt(0).toUpperCase() + plan.slice(1)
            handleSelectPlan(plan)
        } else {
            let plan = 'trial'
            plan = plan.charAt(0).toUpperCase() + plan.slice(1)
            handleSelectPlan(plan)
        }
    }, [])

  return (
    <section className='my-4'>
        <div className="product-font d-flex align-items-center mx-1 icon-emphasize py-1 px-2">
            <span>{ t('registeringForPlan') }</span>
            <h5 className='mb-0 ms-2'>{planSelected.toUpperCase()}</h5>
        </div>
        <form action={`${apiBaseUrl}/stripe/create-subscription`} method="POST">
            <input type="hidden" name="plan_selected" value={ planSelected.toLowerCase() } />
            <input type="hidden" name="user_country_code" value={ newUserInformation.userCountry.code } />
            <input type="hidden" name="user_email" value={ newUserInformation.userEmail.toLowerCase() } />
            <button id="stripeButton" type="submit" className='d-none'>
                { t('pay') }
            </button>
        </form>
    </section>
  )
}

export default ProductDisplay