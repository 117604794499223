import React from 'react'
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../../Navbar'
import InstagramInputs from './InstagramInputs';
import { useTranslation } from 'react-i18next';
import { openModal } from '../../../store/slices/modal/modalSlice';
import '../../../styles/inputs.css'


const InstagramDashboardBusqueda = () => {
    
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { status, instagramSearchParameters } = useSelector( state => state.instagram )
    
    const handleOpenUserModal = () => {
        dispatch( openModal('delayRequestNotification') )
    }


  return (
    <>
        <Navbar />

        <div className="container-fluid mt-4">
            <div className="row">
                <h1 className='text-mediumblue text-center mb-0'>{ t('instagramAnalysis') }</h1>
                <p className='text-center subtitle'>{ t('sentimentAnalysis') }</p>

                <div className="col-12 d-flex justify-content-center align-items-center mt-4">
                    <InstagramInputs />
                </div>
                
                <div className="col-12">
                    <div className='d-flex justify-content-center align-items-center'>
                        <button
                            className='btn btn-mediumblue mx-auto mb-1'
                            onClick={ handleOpenUserModal }
                            disabled={ status === 'processing' || (instagramSearchParameters.hashtags.length === 0) || (instagramSearchParameters.requestName === '') }
                        >
                            {
                                status === 'processing'
                                ? (
                                    <div  >
                                        <span className='me-2'>{ t('processing') }...</span>
                                        <div className="spinner-grow spinner-grow-sm" role="status">
                                            <span className="visually-hidden">...</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <span className='me-2'>{ t('search') }</span>
                                        <Icon icon="bi:search" />
                                    </div>
                                )
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default InstagramDashboardBusqueda