import { useAuth0 } from '@auth0/auth0-react';
import React from 'react'
import { useTranslation } from 'react-i18next';

const TenantDisabledScreen = () => {
  
  const { logout } = useAuth0();
  const { t } = useTranslation();
  

  return (
    <div style={{ "height": "100vh" }} className='d-flex flex-column justify-content-center'>
        <div>
          <div className='feelings-logo'></div>
        </div>
        <h5 className='text-center text-mediumblue mt-5'>
            { t('bannedTenantTitle') }
        </h5>

        <div className='d-flex flex-column text-center w-50 mx-auto mt-4'>
          <span>{ t('bannedTenantText1') }</span>
          <span>{ t('bannedTenantText2') } <a className='text-decoration-none' href="mailto:cloud@seidoranalytics.com" target="_blank" rel="noopener noreferrer"><strong>cloud@seidoranalytics.com</strong></a></span>
          <span>{ t('bannedTenantText3') }</span>
        </div>

        <div className='d-flex justify-content-center'>
          <span
            className='text-mediumblue pointer mt-4'
            onClick={ () => logout({ returnTo: window.location.origin }) }
          >
            <strong>Cerrar sesión</strong>
          </span>
        </div>
    </div>
  )
}

export default TenantDisabledScreen