import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    status: 'processing', // ready, processing
    historicalAnalysis: [],
    favoritesAnalysis: [],
    historyCount: 0,
    twitterPeriodCount: 0,
    instagramPeriodCount: 0,
    newsPeriodCount: 0
}

export const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        startProcessingRequestHistory: (state) => {
            state.status = 'processing'
        },
        finishProcessingRequestHistory: (state) => {
            state.status = 'ready'
        },
        getTenantHistory: (state, {payload}) => {
            state.favoritesAnalysis = payload.favoriteData
            state.historicalAnalysis = payload.historyData
            state.historyCount = payload.historyCount
            state.instagramPeriodCount = payload.instagramPeriodCount
            state.newsPeriodCount = payload.newsPeriodCount
            state.twitterPeriodCount = payload.twitterPeriodCount
            state.twitterPeriodCount = payload.twitterPeriodCount
            state.instagramPeriodCount = payload.instagramPeriodCount
            state.newsPeriodCount = payload.newsPeriodCount
        },
    },
})

// Action creators are generated for each case reducer function
export const { startProcessingRequestHistory, getTenantHistory, finishProcessingRequestHistory } = historySlice.actions