import React from 'react';
import { Icon } from '@iconify/react';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { nfObject } from '../../../../helpers/utils';
import NewsIcon from '../../../NewsIcon';


const SentimentDataIcons = () => {
    
  const { dataKeywordResult } = useSelector( state => state.news )
  const { t } = useTranslation()

  
  return (
    <div className="container-fluid p-0">
        <div className="d-flex flex-column align-items-center mb-2">
            <h5 className='sentiment-result-charts-title mb-0'>{ t('globalResult') }</h5>
            <small className='sentiment-result-charts-subtitle'>{ t('sumaryResult') }</small>
        </div>

        <div className="row row-cols-auto d-flex justify-content-center py-2">
            <div className="col d-flex">
                <div className="icon-result d-flex justify-content-around align-items-center sentimental-icons pb-1">
                    <div data-tip={ t('totalArticlesFound') } data-for='articlesTooltip'>
                        <NewsIcon settings={{ location: 'sentimenticon', mainClass: 'any' }} />
                    </div>
                    <ReactTooltip
                        id='articlesTooltip'
                        type='dark'
                        effect='solid'
                    />
                    
                    <div className='d-flex flex-column align-items-center sentimental-icons-data'>
                        <span>{ nfObject.format(dataKeywordResult.data.length) }</span>
                        <small className='sentimental-data-description mt-1'>{ t('articles') }</small>
                    </div>
                </div>
            </div>

            <div className="col d-flex">
                <div className="icon-result d-flex justify-content-around align-items-center sentimental-icons">
                    <div data-tip={ t('orgPublished') } data-for='orgTooltip'>
                        <Icon icon="clarity:building-line" className='news-icon text-secondary me-3' />
                    </div>
                    <ReactTooltip
                        id='orgTooltip'
                        type='dark'
                        effect='solid'
                    />

                    <div className='d-flex flex-column align-items-center sentimental-icons-data'>
                        <small className='sentimental-data-description mb-1'>{ t('from') }</small>
                        <span className=''>{ nfObject.format(dataKeywordResult.dataProviders.length) }</span>
                        <small className='sentimental-data-description mt-1'>{ t('portals') }</small>
                    </div>
                </div>
            </div>
        </div>

        <div className="row row-cols-auto d-flex justify-content-center py-2">
            <div className="col d-flex">
                <div className="icon-result d-flex justify-content-center align-items-center sentimental-icons">
                    <div data-tip={ t('positiveNewsTooltip') } data-for='positivesTooltip'>
                        <Icon icon="subway:like" className='positive' />
                    </div>
                    <ReactTooltip
                        id='positivesTooltip'
                        type='dark'
                        effect='solid'
                    />
                    
                    <div className='d-flex flex-column ms-3'>
                        <div className='d-flex flex-column align-items-center sentimental-icons-data'>
                            <span>
                                { nfObject.format(dataKeywordResult.totalAnalysisAmounts.positive_amount) }
                            </span>
                            <small className='sentimental-data-description'>{ t('positives') }</small>
                        </div>

                        <span className='sentimental-icons-data d-flex justify-content-center'>
                            {dataKeywordResult.totalAnalysisPercentages.positive_percent}%
                        </span>
                    </div>
                </div>
            </div>

            <div className="col d-flex">
                <div className="icon-result d-flex justify-content-center align-items-center sentimental-icons">
                    <div data-tip={ t('neutralNewsTooltip') } data-for='neutralsTooltip'>
                        <Icon icon="subway:like" className='neutral' />
                    </div>
                    <ReactTooltip
                        id='neutralsTooltip'
                        type='dark'
                        effect='solid'
                    />

                    <div className='d-flex flex-column ms-3'>
                        <div className='d-flex flex-column align-items-center sentimental-icons-data'>
                            <span>
                                { nfObject.format(dataKeywordResult.totalAnalysisAmounts.neutral_amount) }
                            </span>
                            <small className='sentimental-data-description'>{ t('neutrals') }</small>
                        </div>

                        <span className='sentimental-icons-data d-flex justify-content-center'>
                            {dataKeywordResult.totalAnalysisPercentages.neutral_percent}%
                        </span>
                    </div>
                </div>
            </div>

            <div className="col d-flex">
                <div className="icon-result d-flex justify-content-center align-items-center sentimental-icons">
                    <div data-tip={ t('negativeNewsTooltip') } data-for='negativesTooltip'>
                        <Icon icon="subway:like" className='negative' />
                    </div>
                    <ReactTooltip
                        id='negativesTooltip'
                        type='dark'
                        effect='solid'
                    />

                    <div className='d-flex flex-column ms-3'>
                        <div className='d-flex flex-column align-items-center sentimental-icons-data'>
                            <span>
                                { nfObject.format(dataKeywordResult.totalAnalysisAmounts.negative_amount) }
                            </span>
                            <small className='sentimental-data-description'>{ t('negatives') }</small>
                        </div>

                        <span className='sentimental-icons-data d-flex justify-content-center'>
                            {dataKeywordResult.totalAnalysisPercentages.negative_percent}%
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SentimentDataIcons