import { deleteResultAsFavoriteInDB, getHistoryByTenant, saveResultAsFavoriteInDB } from "../../../helpers/history"
import { showWarnToast } from "../../../helpers/utils"
import { setInstagramSearchParameters } from "../instagram/instagramSlice"
import { setNewsSearchParameters } from "../news/newsSlice"
import { setTwitterSearchParameters } from "../twitter/twitterSlice"
import { finishProcessingRequestHistory, getTenantHistory, startProcessingRequestHistory } from "./historySlice"


export const startGettingHistoricAnalysis = (tenantid) => {
    return async( dispatch ) => {
        dispatch( startProcessingRequestHistory() )
        const { historyData, historyCount, favoriteData, instagramPeriodCount, newsPeriodCount, twitterPeriodCount } = await getHistoryByTenant(tenantid)
        dispatch( getTenantHistory({ historyData, historyCount, favoriteData, instagramPeriodCount, newsPeriodCount, twitterPeriodCount }) )
        dispatch( finishProcessingRequestHistory() )
    }
}


export const startDeletingFavorite = (analysisId, tenantid, socialmedia) => {
    return async( dispatch ) => {
        dispatch( startProcessingRequestHistory() )
        const deleteFavoriteRequest = await deleteResultAsFavoriteInDB(analysisId)
        if(deleteFavoriteRequest.ok){
            (socialmedia === 'twitter' && dispatch( setTwitterSearchParameters({ name: 'isFavorite', value: false }) ))
            || (socialmedia === 'instagram' && dispatch( setInstagramSearchParameters({ name: 'isFavorite', value: false }) ))
            || (socialmedia === 'news' && dispatch( setNewsSearchParameters({ name: 'isFavorite', value: false }) ))
            dispatch( startGettingHistoricAnalysis(tenantid) )
        } else {
            showWarnToast('No se ha podido eliminar de favoritos, intente nuevamente más tarde')
        }
    }
}


export const startSavingFavorite = (analysisId, tenantid, socialmedia) => {
    return async( dispatch ) => {
        dispatch( startProcessingRequestHistory() )
        const saveFavoriteRequest = await saveResultAsFavoriteInDB(analysisId)

        if(saveFavoriteRequest.ok){
            (socialmedia === 'twitter' && dispatch( setTwitterSearchParameters({ name: 'isFavorite', value: true }) ))
            || (socialmedia === 'instagram' && dispatch( setInstagramSearchParameters({ name: 'isFavorite', value: true }) ))
            || (socialmedia === 'news' && dispatch( setNewsSearchParameters({ name: 'isFavorite', value: true }) ))
            dispatch( startGettingHistoricAnalysis(tenantid) )
        } else {
            showWarnToast('No se ha podido guardar en favoritos, intente nuevamente más tarde')
        }
    }
}