// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.validations-page{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.validations-page .logo-size img{
    width: 300px !important;
}

.validations-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.remaining-icon{
    font-size: 20px;
    margin-right: 13px;
    margin-left: 3px;
}
.total-check-icon{
    font-size: 25px;
    margin-left: 3px;
}
.check-validation-icon{
    font-size: 25px;
    margin-right: 11px;
}
.validation-values{
    color: #66B6FF;
    font-size: 18px;
    font-weight: 600;
}
.span-validations{
    color: #727272;
    margin-right: 7px;
    font-weight: 500;
}
.text-72{
    color: #727272;
}`, "",{"version":3,"sources":["webpack://./src/styles/validations.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,cAAc;AAClB","sourcesContent":[".validations-page{\r\n    height: 100vh;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.validations-page .logo-size img{\r\n    width: 300px !important;\r\n}\r\n\r\n.validations-details{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.remaining-icon{\r\n    font-size: 20px;\r\n    margin-right: 13px;\r\n    margin-left: 3px;\r\n}\r\n.total-check-icon{\r\n    font-size: 25px;\r\n    margin-left: 3px;\r\n}\r\n.check-validation-icon{\r\n    font-size: 25px;\r\n    margin-right: 11px;\r\n}\r\n.validation-values{\r\n    color: #66B6FF;\r\n    font-size: 18px;\r\n    font-weight: 600;\r\n}\r\n.span-validations{\r\n    color: #727272;\r\n    margin-right: 7px;\r\n    font-weight: 500;\r\n}\r\n.text-72{\r\n    color: #727272;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
