import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { checkingAuthentication } from '../../store/auth/thunks'
import Loader from './Loader'
import NewUserRegisterScreen from './NewUserRegisterScreen'
import ResetPasswordScreen from './ResetPasswordScreen'

const RedirectUserScreen = () => {

    const [resetPasswordRequested, setResetPasswordRequested] = useState(false)
    const [showRegisterScreen, setShowRegisterScreen] = useState(false)
    const { loginWithRedirect } = useAuth0();
    const dispatch = useDispatch();

    useEffect(() => {
        if(window.location.href.includes('reset-password')){
            setResetPasswordRequested(true)
        } else if(window.location.href.includes('plan=')){
            setShowRegisterScreen(true)
        } else {
            loginWithRedirect();
            dispatch( checkingAuthentication() )
        }
    }, [])
    

    if(resetPasswordRequested){
        return <ResetPasswordScreen />
    }
    
    if(showRegisterScreen){
        return <NewUserRegisterScreen />
    }


  return (
    <Loader />
  )
}

export default RedirectUserScreen