import React, { useEffect, useState } from 'react'
import CustomSignUpForm from '../CustomSignUpForm';
import '../../styles/icons.css'
import SuccessDisplay from '../SuccessDisplay';
import { setRegisterStage } from '../../store/slices/register/registerSlice';
import { useDispatch, useSelector } from 'react-redux';
import PaymentCanceledDisplay from '../PaymentCanceledDisplay';
import { t } from 'i18next';



const NewUserRegisterScreen = () => {
  const { registerStages } = useSelector( state => state.register )
    const dispatch = useDispatch();
    const [success, setSuccess] = useState(false);
    const [canceled, setCanceled] = useState(false);
    const [sessionId, setSessionId] = useState('');
  
    useEffect(() => {
      // Check to see if this is a redirect back from Stripe subscription created
      const query = new URLSearchParams(window.location.search);
  
      if (query.get('success')) {
        dispatch( setRegisterStage({ type: 'message', value: t('paymentSuccess') }) )
        setSuccess(true);
        setSessionId(query.get('session_id'));
      }
  
      if (query.get('canceled')) {
        setCanceled(true)
        dispatch( setRegisterStage({ type: 'message', value: t('paymentCanceled') }) )
      }
    }, [sessionId]);
  


    if (!success && registerStages.message === '') {
      return (
          <CustomSignUpForm />
        )
    } else if (canceled) {
      return <PaymentCanceledDisplay />;
    } else {
      return <SuccessDisplay />;
    }
}

export default NewUserRegisterScreen