import React, { useEffect } from 'react'
import { Icon } from '@iconify/react';
import {NavLink, Link} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cleanTwitterSearchParams } from '../store/slices/twitter/twitterSlice';
import { cleanInstagramSearchParams } from '../store/slices/instagram/instagramSlice';
import { cleanNewsSearchParams } from '../store/slices/news/newsSlice';
import { useTranslation } from 'react-i18next';
import { startDeletingFavorite, startSavingFavorite } from '../store/slices/history/thunks';
import '../styles/icons.css'


const NavBarResults = ({socialMedia}) => {
    
  const searchParameters = useSelector( state => state[socialMedia].twitterSearchParameters || state[socialMedia].instagramSearchParameters || state[socialMedia].newsSearchParameters )
  const { status } = useSelector( state => state.history )
  const { tenantid } = useSelector( state => state.user )
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if(searchParameters.requestType === 'keyword' || searchParameters.requestType === 'hashtag'){
      document.querySelector('#analysisResult').click()
    } else {
      document.querySelector('#dataAccountResult').click()
    }
  }, [])

  
  const handleDeleteFavorite = async() => {
    document.querySelector('#favorite-checkbox').checked = true
    dispatch( startDeletingFavorite(searchParameters.analysisId, tenantid, socialMedia) )
  }

  const handleSaveFavorite = async() => {
      document.querySelector('#favorite-checkbox').checked = true
      dispatch( startSavingFavorite(searchParameters.analysisId, tenantid, socialMedia) )
  }

  const handleCleanSearchParameters = () => {
    dispatch( cleanTwitterSearchParams() )
    dispatch( cleanInstagramSearchParams() )
    dispatch( cleanNewsSearchParams() )
  }


  return (
    <nav className="navbar navbar-white bg-lightgray">
        <div className="container-fluid justify-content-between ms-4">
          <div className='d-flex align-items-end'>
            <h4 className='result-title text-secondary mb-0 me-2'>{ t('results') }</h4>
            {
                (searchParameters.requestType === 'keyword' || searchParameters.requestType === 'hashtag') && (
                    <>
                        <NavLink
                            id='analysisResult'
                            to={`/analysis/${socialMedia}/charts-sentiment`}
                            className={(navData) => (navData.isActive ? 'active-link mx-2' : 'text-decoration-none not-active-link text-secondary mx-2')}
                        >
                            <span className='navbar-result'>{ t('sentimentAnalysis') }</span>
                        </NavLink>
                        
                        <NavLink
                            id='tableResult'
                            to={`/analysis/${socialMedia}/table-sentiment`}
                            className={(navData) => (navData.isActive ? 'active-link mx-2' : 'text-decoration-none not-active-link text-secondary mx-2')}
                        >
                            <span className='navbar-result'>
                              {
                                socialMedia === 'news' ? t('newsArticles') : t('sentimentTable')
                              }
                            </span>
                        </NavLink>
                        
                        {
                          (socialMedia === 'twitter' || socialMedia === 'instagram') && (
                            <NavLink
                                id='cardsResult'
                                to={`/analysis/${socialMedia}/cards-results`}
                                className={(navData) => (navData.isActive ? 'active-link mx-2' : 'text-decoration-none not-active-link text-secondary mx-2')}
                            >
                                <span className='navbar-result'>{ t('cardsView') }</span>
                            </NavLink>
                          )
                        }
                    </>
                )
            }
            {
                searchParameters.requestType === 'account' && (
                    <>
                        <NavLink
                            id='dataAccountResult'
                            to='/analysis/twitter/charts-data'
                            className={(navData) => (navData.isActive ? 'active-link mx-2' : 'text-decoration-none not-active-link text-secondary mx-2')}
                        >
                            <span className='navbar-result'>{ t('statistics') }</span>
                        </NavLink>
                    </>
                )
            }
          </div>

          <div>
            <input
              readOnly
              type="checkbox"
              className='d-none'
              id="favorite-checkbox"
              checked={searchParameters.isFavorite}
            />
            {
              status === 'processing' ? (
                <div className="spinner-border favorite-loader" role="status">
                    <span className="visually-hidden">...</span>
                </div>
              ) : (
                <>
                  <Icon
                    id='unsaveFavoriteIcon'
                    icon="ic:round-star"
                    className='favorite-result'
                    onClick={ handleDeleteFavorite }
                  />
                  <Icon
                    id='saveFavoriteIcon'
                    icon="ic:round-star-outline"
                    className='not-favorite-result'
                    onClick={ handleSaveFavorite }
                  />
                </>
              )
            }

            <Link to={`/analysis/${socialMedia}`}>
              <Icon
                  icon="akar-icons:arrow-back-thick-fill"
                  className='pointer text-lightblue icon-size15'
                  onClick={ handleCleanSearchParameters }
              />
            </Link>
          </div>
        </div>

    </nav>
  )
}

export default NavBarResults