const apiBaseUrl = process.env.REACT_APP_API_BASE_URL


const fetchBackend = ( endpoint, data, method = 'GET' ) => {

    const url = `${ apiBaseUrl }/${ endpoint }`;

    if ( method === 'GET' ) {
        return fetch( url );
    } else {
        return fetch( url, {
            method,
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify( data )
        });
    }
}



export {
    fetchBackend,
    apiBaseUrl
}