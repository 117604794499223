import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import TwitterCard from './TwitterCard'
import Masonry from 'react-masonry-css'


const TwitterCardsResults = () => {
    
    const { t } = useTranslation()
    const { dataKeywordResult } = useSelector( state => state.twitter )


  return (
    <div className='container-fluid'>
        <div className='mt-2 mb-4'>
            <h5 className='text-center sentiment-result-charts-title mb-0'>
                {t('cardsView')}
            </h5>
            <h6 className='text-center sentiment-result-charts-subtitle'>
                {t('cardsViewSubtitle')}
            </h6>
        </div>
        <Masonry
            breakpointCols={3}
            className='my-masonry-grid'
            columnClassName='my-masonry-grid_column'
        >
            {
                dataKeywordResult.data.map( (tweetData, index) => (
                    <div key={index}>
                        <TwitterCard tweetData={tweetData} mediaIndex={index} />
                    </div>
                ))
            }
        </Masonry>
    </div>
  )
}

export default TwitterCardsResults