import React from 'react'
import { useTranslation } from 'react-i18next'
import LineChart from './LineChart'
import MultilineChart from './MultilineChart'
import NegativeWordsBarChart from './NegativeWordsBarChart'
import PositiveWordsBarChart from './PositiveWordsBarChart'
import UniqueBarChart from './UniqueBarChart'
import WordCloud from './WordCloud'


const NewsSentimentCharts = () => {
  
  const { t } = useTranslation()


  return (
    <>
      <div className="row d-flex w-100">
        <div className='my-2'>
            <h3 className='text-center sentiment-result-charts-title mb-0'>
                { t('sentimentAnalysis') }
            </h3>
            <h6 className='text-center sentiment-result-charts-subtitle'>
                { t('sentimentAnalysisResult') }
            </h6>
        </div>

        <div className="col-6 py-2">
          <UniqueBarChart />
        </div>
        <div className="col-6 py-2">
          <LineChart />
        </div>
        <div className="col-7 py-2">
          <MultilineChart />
        </div>
        <div className="col-5 py-2">
          <WordCloud />
        </div>
        <div className="col-6 py-2">
          <PositiveWordsBarChart />
        </div>
        <div className="col-6 py-2">
          <NegativeWordsBarChart />
        </div>
      </div>
    </>
  )
}

export default NewsSentimentCharts