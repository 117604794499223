import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import FollowersFormatter, { EmittedLikesFormatter, FollowingFormatter, PostsFormatter } from '../../NumberFormatter';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const AccountsRankingTable = () => {
    
    const { dataAccountsResult, twitterSearchParameters } = useSelector( state => state.twitter )
    const { t } = useTranslation()
          
    const gridRef = useRef(); // Optional - for accessing Grid's API
    const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
    const [comparativeDataSelected, setComparativeDataSelected] = useState('account-posts')
    // Each Column Definition results in one Column.
    const [columnDefs, setColumnDefs] = useState([
        {
            field: 'screen_name',
            headerName: t('account')
        },
        {
            field: 'posts_count',
            headerName: t('posts')
        }
    ]);
    
    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo( ()=> ({ sortable: true }) );

  
    // Example load data from sever
    useEffect(() => {
        setRowData(dataAccountsResult.data)
    }, []);


    const handleSetTableData = (name, title, color) => {
        setColumnDefs([
            {
                field: 'screen_name',
                headerName: 'Cuenta'
            },
            {
                field: name,
                headerName: title,
                cellRenderer: (name === 'posts_count' && PostsFormatter)
                           || (name === 'followers_count' && FollowersFormatter)
                           || (name === 'amigos_totales' && FollowingFormatter)
                           || (name === 'likes_totales' && EmittedLikesFormatter),
                sort: 'desc'
            }
        ])
        setComparativeDataSelected(color)
    }

    
    const onFirstDataRendered = useCallback(() => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    
    const rowClassRules = useMemo(() => {
        return {
            'account-selected': (params) => {
                const name = params.data.screen_name.toLowerCase();
                return name === twitterSearchParameters.requestSelectedAccount.toLowerCase();
            }
        };
    }, []);

    
    return (
        <div style={{ height: "100%" }}>
            <h5 className='chart-title mb-0'>{ t('ranking') }</h5>
            <small className='chart-subtitle'>{ t('rankingData') }</small>

            <div className='row row-cols-auto d-flex justify-content-center px-3'>
                <div
                    className={ comparativeDataSelected === 'account-posts' ? 'badge m-badge bg-mediumblue pointer mb-1' : 'badge m-badge bg-91 pointer mb-1'}
                    onClick={ () => handleSetTableData('posts_count', 'Posteos', 'account-posts') }>
                    { t('posts') }
                </div>
                <div
                    className={ comparativeDataSelected === 'account-likes' ? 'badge m-badge bg-mediumblue pointer mb-1' : 'badge m-badge bg-91 pointer mb-1'}
                    onClick={ () => handleSetTableData('likes_totales', 'Likes emitidos', 'account-likes') }>
                    { t('likes') }
                </div>
                <div
                    className={ comparativeDataSelected === 'account-followers' ? 'badge m-badge bg-mediumblue pointer mb-1' : 'badge m-badge bg-91 pointer mb-1'}
                    onClick={ () => handleSetTableData('followers_count', 'Followers', 'account-followers') }>
                    { t('followers') }
                </div>
                <div
                    className={ comparativeDataSelected === 'account-following' ? 'badge m-badge bg-mediumblue pointer mb-1' : 'badge m-badge bg-91 pointer mb-1'}
                    onClick={ () => handleSetTableData('amigos_totales', 'Following', 'account-following') }>
                    { t('following') }
                </div>
                <div
                    className={ comparativeDataSelected === 'account-alcance' ? 'badge m-badge bg-mediumblue pointer mb-1' : 'badge m-badge bg-91 pointer mb-1'}
                    onClick={ () => handleSetTableData('alcance', 'Alcance', 'account-alcance') }>
                    { t('scope') }
                </div>
                <div
                    className={ comparativeDataSelected === 'account-efectividad' ? 'badge m-badge bg-mediumblue pointer mb-1' : 'badge m-badge bg-91 pointer mb-1'}
                    onClick={ () => handleSetTableData('efectividad', 'Efectividad', 'account-efectividad') }>
                    { t('effective') }
                </div>
            </div>

            <div className="ag-theme-alpine" style={{ width: "100%",  height: "80%" }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    onFirstDataRendered={onFirstDataRendered}
                    rowClassRules={rowClassRules}
                />
            </div>
        </div>
    )
}

export default AccountsRankingTable