import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
)


const options = {
    indexAxis: 'y',
    responsive: true,
    plugins: {
      legend: false,
      title: {
        display: false
      },
    },
}



const NegativeWordsBarChartInstagram = () => {
    
    const { dataKeywordResult } = useSelector( state => state.instagram )
    const { t } = useTranslation()

    const firstNegativeWords = []
    for(let i in dataKeywordResult.wordCount.negativeWords){
      if( i < 10 ){
        firstNegativeWords.push(dataKeywordResult.wordCount.negativeWords[i])
      }
    }
    
    const scores = firstNegativeWords.map( word => word.value )
    const labels = firstNegativeWords.map( word => word.text )

    
    const data = useMemo(function(){
        return {
            datasets: [
              {
                label: t('amount'),
                data: scores,
                borderWidth: 2,
                borderColor: '#d1052a',
                backgroundColor: 'rgb(209, 5, 42, 0.7)',
              }
            ],
            labels
        }
    }, [])


  return (
    <div className='chart-styles'>
        <h5 className='chart-title mb-0'>{ t('negativeWords') }</h5>
        <small className='chart-subtitle'>{ t('negativeWordsSubtitle') }</small>

        <Bar data={data} options={options} />
    </div>
  )
}

export default NegativeWordsBarChartInstagram