import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
)


const options = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: false
    },
  },
  scales: {
    y: {
      type: 'linear',
      display: true,
      position: 'left',
    }
  },
}




const MultilineChart = () => {

    const { dataKeywordResult } = useSelector( state => state.twitter )
    const dateRawArray = dataKeywordResult.data.map( row => ({ date: row.created_date_raw, sentiment: row.tweet_analysis.sentiment }) )
    const { t } = useTranslation()
    const [linesToShow, setLinesToShow] = useState('minutes')

    
    const getAllMinutes = () => {
      const arrayAllMinutes = dateRawArray.map( row => {
        const myDate = new Date(row.date)
        const min = myDate.getMinutes().toString().length === 1 ? `0${myDate.getMinutes()}` : myDate.getMinutes()
        const hour = myDate.getHours().toString().length === 1 ? `0${myDate.getHours()}` : myDate.getHours()
        const sentiment = row.sentiment
        return { minute: `${hour}:${min}hs`, hour: `${hour}hs`, sentiment }
      }).reverse()

      const minutosSinRepetidos = []
      let minuteSentiments = []
      for(let i of arrayAllMinutes){
        if( !minutosSinRepetidos.includes(i.minute) ){
          minutosSinRepetidos.push(i.minute)
          switch (i.sentiment) {
            case 'Positive':
              minuteSentiments.push({ minute: i.minute, 'Positive': 0, 'Negative': 0, 'Neutral': 0 })
              break;
            case 'Negative':
              minuteSentiments.push({ minute: i.minute, 'Positive': 0, 'Negative': 0, 'Neutral': 0 })
              break;
            case 'Neutral':
              minuteSentiments.push({ minute: i.minute, 'Positive': 0, 'Negative': 0, 'Neutral': 0 })
              break;
            default: break;
          }
        }
      }
      for(let i of arrayAllMinutes){
        minuteSentiments.map( row => row.minute === i.minute ? row[i.sentiment]++ : null )
      }

      const horasSinRepetidos = []
      let hourSentiments = []
      for(let i of arrayAllMinutes){
        if( !horasSinRepetidos.includes(i.hour) ){
          horasSinRepetidos.push(i.hour)
          switch (i.sentiment) {
            case 'Positive':
              hourSentiments.push({ hour: i.hour, 'Positive': 0, 'Negative': 0, 'Neutral': 0 })
              break;
            case 'Negative':
              hourSentiments.push({ hour: i.hour, 'Positive': 0, 'Negative': 0, 'Neutral': 0 })
              break;
            case 'Neutral':
              hourSentiments.push({ hour: i.hour, 'Positive': 0, 'Negative': 0, 'Neutral': 0 })
              break;
            default: break;
          }
        }
      }
      for(let i of arrayAllMinutes){
        hourSentiments.map( row => row.hour === i.hour ? row[i.sentiment]++ : null )
      }

      return { minutosSinRepetidos, horasSinRepetidos, minuteSentiments, hourSentiments }
    }


    const totalScoresHours = getAllMinutes().hourSentiments.map( row => row.Positive + row.Negative + row.Neutral )
    const positiveScoresHours = getAllMinutes().hourSentiments.map( row => row.Positive )
    const negativeScoresHours = getAllMinutes().hourSentiments.map( row => row.Negative )
    const neutralScoresHours = getAllMinutes().hourSentiments.map( row => row.Neutral )
    const labelsHours = getAllMinutes().horasSinRepetidos

    const totalScoresMinutes = getAllMinutes().minuteSentiments.map( row => row.Positive + row.Negative + row.Neutral )
    const positiveScoresMinutes = getAllMinutes().minuteSentiments.map( row => row.Positive )
    const negativeScoresMinutes = getAllMinutes().minuteSentiments.map( row => row.Negative )
    const neutralScoresMinutes = getAllMinutes().minuteSentiments.map( row => row.Neutral )
    const labelsMinutes = getAllMinutes().minutosSinRepetidos


    const minutesData = useMemo(function(){
        return {
            datasets: [
              {
                label: t('positives'),
                data: positiveScoresMinutes,
                fill: false,
                pointRadius: 0,
                tension: 0,
                backgroundColor: 'rgb(66, 171, 73, 0.6)',
                borderWidth: 2,
                borderColor: '#42ab49'
              },
              {
                label: t('negatives'),
                data: negativeScoresMinutes,
                fill: false,
                pointRadius: 0,
                tension: 0,
                backgroundColor: 'rgb(209, 5, 42, 0.6)',
                borderWidth: 2,
                borderColor: '#d1052a',
              },
              {
                label: t('neutrals'),
                data: neutralScoresMinutes,
                fill: false,
                pointRadius: 0,
                tension: 0,
                backgroundColor: 'rgb(239, 184, 16, 0.6)',
                borderWidth: 2,
                borderColor: '#efb810'
              },
              {
                label: t('totalItems'),
                data: totalScoresMinutes,
                fill: false,
                pointRadius: 0,
                tension: 0,
                borderColor: '#0089e4',
                borderWidth: 2,
                backgroundColor: 'rgb(0, 137, 228, 0.4)',
              },
            ],
            labels: labelsMinutes
        }
    }, [])

    
    const hoursData = useMemo(function(){
      return {
          datasets: [
            {
              label: t('positives'),
              data: positiveScoresHours,
              fill: false,
              pointRadius: 0,
              tension: 0,
              borderWidth: 2,
              borderColor: '#42ab49'
            },
            {
              label: t('negatives'),
              data: negativeScoresHours,
              fill: false,
              pointRadius: 0,
              tension: 0,
              borderWidth: 2,
              borderColor: '#d1052a',
            },
            {
              label: t('neutrals'),
              data: neutralScoresHours,
              fill: false,
              pointRadius: 0,
              tension: 0,
              borderWidth: 2,
              borderColor: '#efb810'
            },
            {
              label: t('totalItems'),
              data: totalScoresHours,
              fill: false,
              pointRadius: 0,
              tension: 0,
              borderColor: '#0089e4',
              borderWidth: 2,
            },
          ],
          labels: labelsHours
      }
    }, [])


  return (
    <div className='chart-styles'>
      <h5 className='chart-title mb-0'>{ t('sentimentsInTime') }</h5>
      <small className='chart-subtitle'>{ t('amountInTime') }</small>

      <div className='d-flex justify-content-center mb-2'>
          <div
            className={ linesToShow === 'hours' ? 'badge m-badge bg-mediumblue pointer' : 'badge m-badge bg-91 pointer' }
            onClick={ () => setLinesToShow('hours') }
          >{ t('hours') }</div>
          <div
            className={ linesToShow === 'minutes' ? 'badge m-badge bg-mediumblue pointer' : 'badge m-badge bg-91 pointer' }
            onClick={ () => setLinesToShow('minutes') }
          >{ t('minutes') }</div>
      </div>
      <Line
        data={ linesToShow === 'hours' ? hoursData : minutesData }
        options={options} />
    </div>
  )
}

export default MultilineChart