import React from 'react'
import { useSelector } from 'react-redux'
import { Icon } from '@iconify/react';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { nfObject } from '../../../../helpers/utils';


const AccountDataIcons = () => {

    const { dataAccountsResult, twitterSearchParameters } = useSelector( state => state.twitter )
    const selectedAccountData = dataAccountsResult.data[twitterSearchParameters.requestSelectedAccountIndex]
    const { t } = useTranslation()



  return (
    <div className="container-fluid p-0">
        <div className="row account-result-title m-0">
            <h3 className='text-center account-title m-0'>{ t('mainAccount') }: <span className='account-name'>@{ twitterSearchParameters.requestSelectedAccount }</span></h3>
        </div>

        <div className="row row-cols-auto d-flex justify-content-center py-2">
            <div className="col d-flex mb-2 px-1">
                <div className="icon-result d-flex justify-content-around align-items-center sentimental-icons">
                    <div data-tip={ t('tweetsAmount') } data-for='twitterTooltip'>
                        <Icon icon="akar-icons:twitter-fill" className='tweets me-2' />
                    </div>
                    <ReactTooltip
                        id='twitterTooltip'
                        type='dark'
                        effect='solid'
                    />
                    
                    <div className='d-flex flex-column align-items-center sentimental-icons-data'>
                        <span className=''>{nfObject.format(selectedAccountData.posts_count)}</span>
                        <small className='sentimental-data-description mt-1'>{ t('tweets') }</small>
                    </div>
                </div>
            </div>

            <div className="col d-flex mb-2 px-1">
                <div className="icon-result d-flex justify-content-around align-items-center sentimental-icons">
                    <div data-tip={ t('followersAmount') } data-for='followersTooltip'>
                        <Icon icon="fa:group" className='account-icon me-2' />
                    </div>
                    <ReactTooltip
                        id='followersTooltip'
                        type='dark'
                        effect='solid'
                    />

                    <div className='d-flex flex-column align-items-center sentimental-icons-data'>
                        <span className=''>{nfObject.format(selectedAccountData.followers_count)}</span>
                        <small className='sentimental-data-description mt-1'>{ t('followers') }</small>
                    </div>
                </div>
            </div>
            
            <div className="col d-flex mb-2 px-1">
                <div className="icon-result d-flex justify-content-around align-items-center sentimental-icons">
                    <div data-tip={ t('followingAmount') } data-for='followersTooltip'>
                        <Icon icon="fa:group" className='account-icon me-2' />
                    </div>
                    <ReactTooltip
                        id='followersTooltip'
                        type='dark'
                        effect='solid'
                    />

                    <div className='d-flex flex-column align-items-center sentimental-icons-data'>
                        <span className=''>{nfObject.format(selectedAccountData.amigos_totales)}</span>
                        <small className='sentimental-data-description mt-1'>{ t('following') }</small>
                    </div>
                </div>
            </div>
            
            <div className="col d-flex mb-2 px-1">
                <div className="icon-result d-flex justify-content-center align-items-center sentimental-icons">
                    <div data-tip={ t('lastReceivedLikes') } data-for='positivesTooltip'>
                        <Icon icon="ant-design:like-filled" className='account-icon me-2' />
                    </div>
                    <ReactTooltip
                        id='positivesTooltip'
                        type='dark'
                        effect='solid'
                    />
                    
                    <div className='d-flex flex-column'>
                        <div className='d-flex flex-column align-items-center sentimental-icons-data'>
                            <span>
                                {nfObject.format(dataAccountsResult.lastReceivedLikes)}
                            </span>
                            <div className='d-flex align-items-center justify-content-center'>
                                <small className='sentimental-data-description mt-1'>{ t('receivedLikes') }</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col d-flex mb-2 px-1">
                <div className="icon-result d-flex justify-content-center align-items-center sentimental-icons">
                    <div data-tip={ t('lastEmittedLikes') } data-for='positivesTooltip'>
                        <Icon icon="ant-design:like-filled" className='account-icon me-2' />
                    </div>
                    <ReactTooltip
                        id='positivesTooltip'
                        type='dark'
                        effect='solid'
                    />
                    
                    <div className='d-flex flex-column'>
                        <div className='d-flex flex-column align-items-center sentimental-icons-data'>
                            <span>
                                {nfObject.format(selectedAccountData.likes_totales)}
                            </span>
                            <div className='d-flex align-items-center justify-content-center'>
                                <small className='sentimental-data-description mt-1'>{ t('likesEmitted') }</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col d-flex mb-2 px-1">
                <div className="icon-result d-flex justify-content-center align-items-center sentimental-icons">
                    <div data-tip={ t('scope') } data-for='neutralsTooltip'>
                        <Icon icon="fluent:target-arrow-24-filled" className='account-icon me-2' />
                    </div>
                    <ReactTooltip
                        id='neutralsTooltip'
                        type='dark'
                        effect='solid'
                    />

                    <div className='d-flex flex-column'>
                        <div className='d-flex flex-column align-items-center sentimental-icons-data'>
                            <span>
                                { selectedAccountData.alcance }%
                            </span>
                            <small className='sentimental-data-description mt-1'>{ t('scope') }</small>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col d-flex mb-2 px-1">
                <div className="icon-result d-flex justify-content-center align-items-center sentimental-icons">
                    <div data-tip={ t('effective') } data-for='negativesTooltip'>
                        <Icon icon="ic:outline-speed" className='account-icon me-2' />
                    </div>
                    <ReactTooltip
                        id='negativesTooltip'
                        type='dark'
                        effect='solid'
                    />

                    <div className='d-flex flex-column'>
                        <div className='d-flex flex-column align-items-center sentimental-icons-data'>
                            <span>
                                { selectedAccountData.efectividad }
                            </span>
                            <small className='sentimental-data-description mt-1'>{ t('effective') }</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AccountDataIcons