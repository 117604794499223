import { useAuth0 } from '@auth0/auth0-react';
import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startGettingUserInformation } from '../../store/slices/user/thunk';
import { setUserState } from '../../store/slices/user/userSlice';
import Loader from './Loader';
import feelingsLogo from '../../img/feelings-logo.png'
import { DateTime } from 'luxon'
import { cancelOldSubscription } from '../../helpers/subscription';
import TenantDisabledScreen from './TenantDisabledScreen';
import TenantNotVerifiedScreen from './TenantNotVerifiedScreen';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import '../../styles/validations.css'

const AppValidations = () => {
    
    const { checkingInformation, plan, tenantActive, userEnabledToContinue, trialRemainingDays } = useSelector( state => state.user )
    const { historyCount, twitterPeriodCount, instagramPeriodCount, newsPeriodCount } = useSelector( state => state.history )
    const { globalSettings } = useSelector( state => state.settings )
    const { user } = useAuth0();
    const dispatch = useDispatch();
    const { t } = useTranslation()

    useEffect(() => {
        const tenantid = user.email.replace('@', '_')
        dispatch( startGettingUserInformation( tenantid ) )
    }, [])
      
    useEffect(() => {
        // Check to see if this is a redirect back from Stripe checkout
        const query = new URLSearchParams(window.location.search);
    
        if (query.get('updatesuccess')) {
            const oldSubscription = localStorage.getItem('subscriptionId')
            cancelOldSubscription(oldSubscription)

            localStorage.clear('subscriptionId')
        }
      }, []);
    

    if( checkingInformation ){
        return <Loader/>
    }
    
    if( !user.email_verified ){
        return <TenantNotVerifiedScreen />
    }

    if( !tenantActive ){
        return <TenantDisabledScreen />
    }


  return (
    <div className='validations-page'>
        <div className='logo-size'>
            <img src={ feelingsLogo } alt="Feelings" />
        </div>

        <div className='validations-details'>
            <h3 className='text-lightblue my-4'>{ t('welcome') }</h3>
            <span>
                { t('yourAbstract') } <strong className='text-mediumblue'>{DateTime.now().toLocaleString()}</strong>
            </span>
            <span>
                { t('youHavePlan') } <strong className='text-mediumblue'>{plan.toUpperCase()}</strong>
            </span>
            {
                plan === 'trial' && (trialRemainingDays >= 0) && (
                    <small className='mt-2'>
                        { t('trialRemainingDays') }: <strong className='text-mediumblue ms-2'>{ trialRemainingDays }</strong>
                    </small>
                )
            }
            
            {
                plan === 'trial' && (trialRemainingDays < 0) && (
                    <small className='mt-2 text-mediumblue'>
                        { t('trialExpired') }
                    </small>
                )
            }
        </div>

        <div className="container mt-4 mb-4">
            <div className='row d-flex justify-content-center'>
                <div className="col-5 d-flex flex-column align-items-center">
                    <h5 className='text-lightblue'>{ t('doneAnalysis') }</h5>
                    <ul className='text-mediumblue ps-0'>
                        <li className='d-flex align-items-end justify-content-start'>
                            <Icon icon="material-symbols:check-small-rounded" className='check-validation-icon text-mediumblue' />
                            <span className='span-validations'>Twitter:</span>
                            <span className='validation-values'>{ twitterPeriodCount }</span>
                        </li>
                        <li className='d-flex align-items-end justify-content-start'>
                            <Icon icon="material-symbols:check-small-rounded" className='check-validation-icon text-mediumblue' />
                            <span className='span-validations'>Instagram:</span>
                            <span className='validation-values'>{ instagramPeriodCount }</span>
                        </li>
                        <li className='d-flex align-items-end justify-content-start'>
                            <Icon icon="material-symbols:check-small-rounded" className='check-validation-icon text-mediumblue' />
                            <span className='span-validations'>{t('news')}:</span>
                            <span className='validation-values'>{ newsPeriodCount }</span>
                        </li>
                        <li className='d-flex align-items-end justify-content-start text-72'>
                            <Icon icon="material-symbols:playlist-add-check-rounded" className='total-check-icon text-mediumblue me-2'/>
                            <span className='span-validations'>{ t('totalDoneAnalyzes') }:</span>
                            <span className='validation-values me-1'>{ historyCount }</span> { t('outOf') } <span className='validation-values ms-1'>{ globalSettings.userPlanSettings.max_requests }</span>
                        </li>
                        <li className='d-flex align-items-end justify-content-start'>
                            <Icon icon="ic:twotone-network-check" className='remaining-icon text-mediumblue' />
                            <span className='span-validations'>{ t('remainingAnalysis') }:</span>
                            <span className='validation-values'>{ globalSettings.userPlanSettings.max_requests - historyCount }</span>
                        </li>
                    </ul>
                </div>
                
            </div>
        </div>

        {
            userEnabledToContinue && (
                <button
                    className='btn btn-sm btn-lightblue'
                    onClick={ () => dispatch( setUserState({ name: 'userValidated', value: true }) ) }
                    disabled={ (globalSettings.userPlanSettings.max_requests - historyCount === 0) || !tenantActive }
                >
                    { t('continue') }
                </button>
            )
        }

        {
            !userEnabledToContinue && (plan !=='trial') && (
                <div className='col-3 d-flex flex-column'>
                    <small className='mb-4 text-center text-mediumblue'>
                        { t('userDisabledToContinue') }
                    </small>
                    
                    <button
                        className='btn btn-sm btn-lightblue'
                        onClick={ () => dispatch( setUserState({ name: 'updatePlanPage', value: true }) ) }
                    >
                        { t('upgradePlan') }
                    </button>
                </div>
            )
        }

        {
            plan === 'trial' && ((trialRemainingDays < 0) || (globalSettings.userPlanSettings.max_requests - historyCount <= 0)) && (
                <div className='col-3 d-flex flex-column'>
                    <small className='mb-4 text-center text-mediumblue'>
                        { t('upgradeToContinue') }
                    </small>
                    
                    <button
                        className='btn btn-sm btn-lightblue btn-block'
                        onClick={ () => dispatch( setUserState({ name: 'updatePlanPage', value: true }) ) }
                    >
                        { t('updatePlan') }
                    </button>
                </div>
            )
        }
    </div>
  )
}

export default AppValidations