import React from 'react'
import '../../../styles/news.css'
import { useTranslation } from 'react-i18next'

const NewsCard = ({cardInfo}) => {
    
    const { t } = useTranslation()

    const getTimeFormat = () => {
        const date = new Date(cardInfo.datePublished).toLocaleDateString()
        const hour = new Date(cardInfo.datePublished).getHours().toString().length === 1
                                                            ? `0${new Date(cardInfo.datePublished).getHours()}`
                                                            : new Date(cardInfo.datePublished).getHours()
        const min = new Date(cardInfo.datePublished).getMinutes().toString().length === 1
                                                            ? `0${new Date(cardInfo.datePublished).getMinutes()}`
                                                            : new Date(cardInfo.datePublished).getMinutes()
        const dateAndHour = `${date}, ${hour}:${min}hs`
        return dateAndHour
    }


  return (
    <div className="news-card-container">
        <div className="news-card">
            <div className="news-card-header">
                {
                    cardInfo.image ? (
                        <img src={ cardInfo.image.thumbnail.contentUrl.replace('&pid=News', '') } alt="" />
                    ) : (
                        <div className='news-false-image'>
                            <span className='m-0'>{ cardInfo.provider[0].name }</span>
                        </div>
                    )
                }
            </div>
            <div className="news-card-body">
                <span className={`news-tag news-tag-${cardInfo.news_analysis.sentiment.toLowerCase()}`}>
                    { cardInfo.news_analysis.sentiment }
                </span>
                <h4>{ cardInfo.title.replace(/[^\wáéíóúÁÉÍÓÚñÑüÜa-zA-Z0-9\\, .:-_@“”]/g, '')/* .replace(' :', ':').replace(' ”', '”').replace(' .', '.') */ }</h4>
                <p>
                    { cardInfo.description }
                </p>
                <div className="news-user">
                    {
                        cardInfo.provider[0].image ? (
                            <img src={ cardInfo.provider[0].image.thumbnail.contentUrl.replace('&pid=news', '') } alt="" />
                        ) : (
                            <div className='news-avatar'>
                                <span className='m-0'>{ cardInfo.provider[0].name[0] }</span>
                            </div>
                        )
                    }
                    <div className="news-user-info">
                        <h6>{ cardInfo.provider[0].name }</h6>
                        <small>{ getTimeFormat() }</small>
                    </div>
                </div>
                <div className='news-link ms-auto'>
                    <a href={ cardInfo.url } target='_blank' rel='noreferrer'>{ t('goToArticle') }</a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NewsCard