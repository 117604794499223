import React from 'react'

const NewsIcon = ({settings}) => {


    if(settings.location === 'appvalidations'){
        return (
          <div className='d-flex justify-content-center align-items-center'>
              <div className='news-myicon validationicon-news'>
                  <span>NEWS</span>
                  <div className='news-bar'></div>
                  <div className='news-bar'></div>
              </div>
          </div>
        )
    }

    if(settings.location === 'navbar'){
        return (
          <div style={{ 'height': '32px' }} className='d-flex justify-content-center align-items-center'>
              <div className={settings.mainClass}>
                  <span>NEWS</span>
                  <div className='news-bar'></div>
                  <div className='news-bar'></div>
                  <div className='news-bar'></div>
                  <div className='news-bar'></div>
              </div>
          </div>
        )
    }

    if(settings.location === 'sentimenticon'){
        return (
          <div className='d-flex justify-content-center align-items-center me-3'>
              <div className='news-myicon sentimenticon-news'>
                  <span>NEWS</span>
                  <div className='news-bar'></div>
                  <div className='news-bar'></div>
                  <div className='news-bar'></div>
                  <div className='news-bar'></div>
              </div>
          </div>
        )
    }
    
    if(settings.location === 'history'){
        return (
          <div className='d-flex justify-content-center align-items-center'>
              <div className='news-myicon sentimenticon-history'>
                  <span>NEWS</span>
                  <div className='news-bar'></div>
                  <div className='news-bar'></div>
              </div>
          </div>
        )
    }


  return (
    <div style={{ 'height': '64px' }} className='d-flex justify-content-center align-items-center'>
        <div className='news-myicon'>
            <span>NEWS</span>
            <div className='news-bar'></div>
            <div className='news-bar'></div>
            <div className='news-bar'></div>
            <div className='news-bar'></div>
        </div>
    </div>
  )
}

export default NewsIcon