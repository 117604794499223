import React from 'react'
import { useSelector } from 'react-redux'
import InstagramResults from './InstagramResults';
import InstagramDashboardBusqueda from './InstagramDashboardBusqueda'


const InstagramScreen = () => {

    const { dataKeywordResult } = useSelector( state => state.instagram )


  return (
    <div className="home">
        {
            dataKeywordResult.data.length > 0  ? (
                <InstagramResults />
            ) : (
                <InstagramDashboardBusqueda />
            )
        }
    </div>
  )
}

export default InstagramScreen