import { fetchBackend } from "./fetch"


export const getTweetsAndAnalysis = async(body) => {
    const tweetsRequest = await fetchBackend('twitter/search/keyword', body, 'POST')
    const tweetsResponse = await tweetsRequest.json()
    return tweetsResponse
}


export const getAccountAndAnalysis = async(body) => {
    const accountsRequest = await fetchBackend('twitter/search/account', body, 'POST')
    const accountsResponse = await accountsRequest.json()
    return accountsResponse
}
