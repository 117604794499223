import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


const TwitterSearchParameters = () => {
    
    const { twitterSearchParameters } = useSelector( state => state.twitter )
    const { t } = useTranslation()


  return (
    <div className='d-flex flex-column'>
        <div className="d-flex flex-column align-items-center mb-3">
            <h5 className='sentiment-result-charts-title mb-0'>{ t('searchParameters') }</h5>
            <small className='sentiment-result-charts-subtitle'>{ t('searchParametersConfig') }</small>
        </div>

        <div className='search-parameters-container'>
            <div className='d-flex flex-column search-parameters-name'>
                <h4 className='search-parameters mb-0'>{ twitterSearchParameters.requestName }</h4>
                <small className='search-parameters-description'>{ t('analysisName') }</small>
            </div>

            <div className='d-flex'>
            {
                twitterSearchParameters.requestKeyword && (
                    <div className='d-flex flex-column search-parameters-keyword'>
                        <h4 className='search-parameters mb-0'>{ twitterSearchParameters.requestKeyword }</h4>
                        <small className='search-parameters-description'>{ t('analysis') }</small>
                    </div>
                )
            }

            {
                twitterSearchParameters.requestAccounts[0] !== '' && (
                    <div className='d-flex flex-column'>
                        {
                            twitterSearchParameters.requestAccounts.map( (account, index) => (
                                <div key={index} className='d-flex flex-column search-parameters-info'>
                                    <h4 className='search-parameters mb-0'>@{ account }</h4>
                                    <small className='search-parameters-description'>
                                        { t('account') }
                                    </small>
                                </div>
                            ))
                        }
                    </div>
                )
            }
            </div>
            
            <div className="d-flex">
            {
                twitterSearchParameters.requestAmount && (
                    <div className='d-flex flex-column search-parameters-info'>
                        <h4 className='search-parameters mb-0'>{ twitterSearchParameters.requestAmount }</h4>
                        <small className='search-parameters-description'>{ t('tweetsRequested') }</small>
                    </div>
                )
            }
            
            {
                twitterSearchParameters.resultsType && (
                    <div className='d-flex flex-column search-parameters-info'>
                        <h4 className='search-parameters mb-0'>{ twitterSearchParameters.resultsType }</h4>
                        <small className='search-parameters-description'>{ t('resultsType') }</small>
                    </div>
                )
            }
            </div>

            <div className='d-flex'>
                <div className='d-flex flex-column search-parameters-info'>
                    <h4 className='search-parameters mb-0'>{ twitterSearchParameters.requestType }</h4>
                    <small className='search-parameters-description'>{ t('analysisType') }</small>
                </div>
                {
                    twitterSearchParameters.requestLanguage && (
                        <div className='d-flex flex-column search-parameters-info'>
                            <h4 className='search-parameters mb-0'>
                                { twitterSearchParameters.requestLanguage }
                            </h4>
                            <small className='search-parameters-description'>{ t('language') }</small>
                        </div>
                    )
                }

                {
                    twitterSearchParameters.requestAccounts[0] !== '' && (
                        <div className='d-flex flex-column search-parameters-info'>
                            <h4 className='search-parameters mb-0'>{ twitterSearchParameters.requestAccounts.length }</h4>
                            <small className='search-parameters-description'>
                                { t('amountAccounts') }
                            </small>
                        </div>
                    )
                }
            </div>

            <div className="d-flex">
                {
                    twitterSearchParameters.requestDate && (
                        <div className='d-flex flex-column search-parameters-info'>
                            <h6 className='search-parameters mb-0 w-100'>
                                { twitterSearchParameters.requestDate }
                            </h6>
                            <small className='search-parameters-description'>
                            { t('analysisDate') }
                            </small>
                        </div>
                    )
                }
            </div>
        </div>
    </div>
  )
}

export default TwitterSearchParameters