import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../store/slices/modal/modalSlice';


const lngs = {
    en: { nativeName: 'EN' },
    es: { nativeName: 'ES' }
}

const Configuraciones = () => {

    
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const handleSubmitSettings = (e) => {
        e.preventDefault();
        dispatch( closeModal() );
    }
    
    const searchAndClickLangButton = async(e, language) => {
        e.preventDefault();
        await document.querySelector(`#${language}`).click();
    }

    const closeFormModal = (e) => {
        e.preventDefault()
        dispatch( closeModal() )
    }

  return (
    <>
        <h4 className='text-center text-mediumblue'>{t('settings')}</h4>
        <hr />
        <form onSubmit={handleSubmitSettings}>

            <div className='osc-style d-flex justify-content-between align-items-center'>
                <small className='text-mediumblue'>{t('language')}:</small>
                <div className="btn-group">
                    {Object.keys(lngs).map(lng => (
                        <button
                            key={lng}
                            onClick={ (e) => searchAndClickLangButton(e,lng) }
                            disabled={i18n.resolvedLanguage === lng}
                            className='btn btn-sm btn-lightblue'
                        >
                            {lngs[lng].nativeName}
                        </button>
                    ))}
                </div>
            </div>

            <div className='d-flex justify-content-end mt-5'>
                <div>
                    <button
                        className='btn btn-sm btn-lightblue me-1'
                    >
                        {t('apply')}
                    </button>
                    <button
                        className='btn btn-sm btn-91'
                        onClick={ closeFormModal }
                    >
                        {t('cancel')}
                    </button>
                </div>
            </div>

        </form>

        <div className='btn-group d-none'>
            {Object.keys(lngs).map(lng => (
                <button
                    key={lng}
                    id={lng}
                    onClick={() => i18n.changeLanguage(lng)}
                    disabled={i18n.resolvedLanguage === lng}
                    className='btn btn-sm btn-secondary'
                >
                    {lngs[lng].nativeName}
                </button>
            ))}
        </div>
    </>
  )
}

export default Configuraciones