// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../img/feelings-logo.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newUserForm label{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #3C4A5A;
}

.newUserForm{
    min-width: 350px;
    max-width: 440px;
}

.termsandpolicy{
    font-size: 14px;
}

.termsandpolicy a{
    font-weight: 600;
}

.newUserForm input::placeholder{
    font-size: 14px;
    color: #919191;
}

.signUpPageTitle{
    color: #3C4A5A;
    font-weight: 600;
}

.feelings-logo{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.color-login{
    color: #3C4A5A;
}

.loading-button{
    width: 28px;
    height: 28px;
}`, "",{"version":3,"sources":["webpack://./src/styles/newuserpage.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,yDAA+C;IAC/C,aAAa;IACb,wBAAwB;IACxB,4BAA4B;IAC5B,4BAA4B;AAChC;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".newUserForm label{\r\n    font-size: 14px;\r\n    font-weight: 500;\r\n    margin-bottom: 5px;\r\n    color: #3C4A5A;\r\n}\r\n\r\n.newUserForm{\r\n    min-width: 350px;\r\n    max-width: 440px;\r\n}\r\n\r\n.termsandpolicy{\r\n    font-size: 14px;\r\n}\r\n\r\n.termsandpolicy a{\r\n    font-weight: 600;\r\n}\r\n\r\n.newUserForm input::placeholder{\r\n    font-size: 14px;\r\n    color: #919191;\r\n}\r\n\r\n.signUpPageTitle{\r\n    color: #3C4A5A;\r\n    font-weight: 600;\r\n}\r\n\r\n.feelings-logo{\r\n    background-image: url(../img/feelings-logo.png);\r\n    height: 200px;\r\n    background-size: contain;\r\n    background-repeat: no-repeat;\r\n    background-position: 50% 50%;\r\n}\r\n\r\n.color-login{\r\n    color: #3C4A5A;\r\n}\r\n\r\n.loading-button{\r\n    width: 28px;\r\n    height: 28px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
