import Swal from 'sweetalert2'
import countries from 'i18n-iso-countries';
import validator from 'validator';
import { fetchBackend } from './fetch';
import { validate } from 'secure-password-validator';


export const registerUserWithEmailAndPassword = async(data) => {
    const URL = process.env.REACT_APP_AUTH0_SIGNUP_URL;
    const method = 'POST'

    const auth0NewUser = {
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        email: data.newUserInformation.userEmail.toLowerCase(),
        password: data.newUserInformation.userPassword,
        connection: "Username-Password-Authentication",
        given_name: data.newUserInformation.userName,
        family_name: data.newUserInformation.userLastname,
        name: `${data.newUserInformation.userName} ${data.newUserInformation.userLastname}`,
        user_metadata: {
            plan: data.newUserInformation.selectedPlan,
            country: data.country,
            phone: data.newUserInformation.userPhone,
            company: data.newUserInformation.userCompany,
            fecha_alta: data.fecha,
            language: data.language,
            tenant_id: data.tenantid
        }
    }

    try {
        const resp = await fetch( URL, { method,
                                         headers: { 'Content-type': 'application/json' },
                                         body: JSON.stringify( auth0NewUser ) }).catch( error => console.log(error) );
        const jsonResp = await resp.json()

        if(!resp.ok){
            return { jsonResp, ok: resp.ok }
        } else {
            return {
                jsonResp,
                ok: resp.ok,
                email: data.newUserInformation.userEmail,
                name: `${data.newUserInformation.userName} ${data.newUserInformation.userLastname}`,
            }
        }
    } catch (error) {
        console.log(error)
        return {
            ok: false,
            errorMessage: error.message
        }
    }
}



export const getCountriesList = (language) => {
    const countriesJson = countries.getNames(language)

    let keysArray = [];
    let valuesArray = [];
    for(let key in countriesJson){
      keysArray.push(key);
    }
    for(let value in countriesJson){
      valuesArray.push(countriesJson[value]);
    }

    const countriesArray = [];
    for(let i=0; i<valuesArray.length; i++){
      countriesArray.push({ value:keysArray[i], label:valuesArray[i] })
    }

    return countriesArray
}


export const validarEmail = async(email) => {
    const esEmail = validator.isEmail(email);
    const stringEmail = email
    const desiredEmail = stringEmail.replace(/[^\w\s\@\.\-]/gi, 'regex')
    const emailCharVerify = desiredEmail.includes('regex')

    if(!esEmail){
        Swal.fire('Atención', 'Ingrese un email válido por favor', 'warning')
        return
    } else if (emailCharVerify){
        Swal.fire('Atención', 'El email ingresado contiene caracteres no permitidos', 'warning')
        return
    }
}


export const checkIfEmailExists = async(email) => {
    const emailRequest = await fetchBackend('tenants');
    const emailResponse = await emailRequest.json();
    const emailAlreadyExists = await emailResponse.data.some( row => row.email === email )
    return emailAlreadyExists
}


export const createNewUserInDB = async(data) => {
    const newUserPost = await fetchBackend('register/create', data, 'POST');
    const newUserRes = await newUserPost.json();
    return newUserRes
}


export const checkBadResponse = async(respJson) => {
    if(!respJson.ok && respJson.message.includes('duplicada')){
        Swal.fire('Atención', 'Usuario ya existe', 'warning')
        return
    } else if(!respJson.ok){
        Swal.fire('Atención', respJson.message, 'warning')
        return
    }
}


export const validarPassword = (password) => {
    const myWeakPassword = password
     
    const options = { minLength: 8, digits: true, letters: true, uppercase: true, lowercase: true, symbols: true };
    
    const result = validate(myWeakPassword, options)

    if( result.errors.length > 1 ){
        return { passwordOk: false }
    } else {
        return { passwordOk: true }
    }
}