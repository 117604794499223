import { toast } from "react-toastify"
import { getAccountAndAnalysis, getTweetsAndAnalysis } from "../../../helpers/twitter"
import { startGettingHistoricAnalysis } from "../history/thunks"
import { cleanAccountSearchParams, cleanKeywordSearchParams, getTwitterAccountResult, getTwitterAnalysisResult, processingRequest, requestReady, setTwitterSearchParameters } from "./twitterSlice"


export const startGettingTweetsAnalysis = (body) => {
    return async( dispatch ) => {
        dispatch( cleanAccountSearchParams() )
        dispatch( processingRequest() )
        const respJson = await getTweetsAndAnalysis(body)

        if(respJson.ok){
            dispatch( getTwitterAnalysisResult(respJson) )
            dispatch( setTwitterSearchParameters({ name: 'analysisId', value: respJson.analysisid }) )
            dispatch( startGettingHistoricAnalysis(body.tenant) )
        } else {
            dispatch( requestReady() )
            toast.error('No se encontraron tweets con la keyword ingresada', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }
}



export const startGettingAccountAnalysis = (body) => {
    return async( dispatch ) => {
        dispatch( cleanKeywordSearchParams() )
        dispatch( processingRequest(body) )
        const respJson = await getAccountAndAnalysis(body)

        if(respJson.ok){
            dispatch( getTwitterAccountResult(respJson) )
            dispatch( startGettingHistoricAnalysis(body.tenant) )
        } else {
            dispatch( requestReady() )
            toast.error('Una de las cuentas ingresadas es inexistente', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }

    }
}