import React from 'react'
import { useTranslation } from 'react-i18next';
import SelectPlatform from './SelectPlatform'

const AnalysisScreen = () => {
    
  const { t } = useTranslation();

  return (
    <div className='home'>
      <h3 className='text-mediumblue text-center mt-5 mb-0'>
        { t('selectPlatformTitle') }
      </h3>
      <p className='subtitle text-center'>
        { t('selectPlatformSubtitle') }
      </p>
      <SelectPlatform />
    </div>
  )
}

export default AnalysisScreen