import React from 'react'
import { nfObject } from '../helpers/utils'
import '../styles/results-page.css'


export const PostsFormatter = (props) => {
  return (
    <div>{ nfObject.format(props.data.posts_count) }</div>
  )
}

export const FollowingFormatter = (props) => {
  return (
    <div>{ nfObject.format(props.data.amigos_totales) }</div>
  )
}

export const FollowersFormatter = (props) => {
  return (
    <div className='followers-count'>{ nfObject.format(props.data.followers_count) }</div>
  )
}

export const LikesFormatter = (props) => {
  return (
    <div className='likes-count'>{ nfObject.format(props.data.likes) }</div>
  )
}

export const EmittedLikesFormatter = (props) => {
  return (
    <div className='likes-count'>{ nfObject.format(props.data.likes_totales) }</div>
  )
}

export const RetweetsFormatter = (props) => {
  return (
    <div className='retweets-count'>{ nfObject.format(props.data.retweet_count) }</div>
  )
}

export const AccountFormatter = (props) => {
  return (
      <a className='user-account text-decoration-none' href={`https://twitter.com/${props.data.user_account}`} target="_blank" rel="noopener noreferrer">
        @{ props.data.user_account }
      </a>
  )
}

export const UsernameFormatter = (props) => {
  return (
    <div className='user-name'>{ props.data.user_name }</div>
  )
}

export const LocationFormatter = (props) => {
  return (
    <div className='user-name'>{ props.data.user_location }</div>
  )
}

export const PolarityFormatter = (props) => {
  return (
    <small className='polarity-result'>{ props.data.tweet_analysis.polarity }</small>
  )
}

export default FollowersFormatter