export const handleToggle = () => {
    const sidebar = document.querySelector('nav')
    sidebar.classList.toggle('close')
}

export const handleDarkMode = () => {
    const body = document.querySelector('body')
    const modeText = document.querySelector('.mode-text')

    body.classList.toggle('dark')
    if(body.classList.contains("dark")){
        modeText.innerText = "Light mode";
    }else{
        modeText.innerText = "Dark mode";
    }
}