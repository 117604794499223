import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { checkingAuthentication } from '../store/auth/thunks';

const SuccessDisplay = () => {
    
  const { t } = useTranslation();

  const { loginWithRedirect } = useAuth0();
  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();
    loginWithRedirect();
    dispatch( checkingAuthentication() )
  }


  return (
    <div style={{ "height": "100vh" }} className='d-flex flex-column justify-content-center'>
        <div>
          <div className='feelings-logo'></div>
        </div>
        <h5 className='text-center text-mediumblue mt-5'>
            { t('welcomeToYou') }
        </h5>

        <div className='d-flex flex-column text-center mt-3'>
          <span>{ t('accountVerified') }.</span>
        </div>

        <div
          onClick={ handleLogin }
          className='text-decoration-none text-center text-lightblue pointer mt-3'
        >
          { t('goToApp') }
        </div>
    </div>
  )
}

export default SuccessDisplay