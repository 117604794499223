import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../store/slices/modal/modalSlice';
import AppInfo from '../../../package.json'


const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;


const AcercaDe = () => {
    
    const { t } = useTranslation();
    const dispatch = useDispatch();


  return (
    <>
        <h4 className='text-center text-mediumblue'>{t('about')}</h4>
        <hr />
        <div className='d-flex flex-column align-items-center my-4'>
            <h3 className='text-lightblue'>Feelings</h3>
            <h6 className='mb-0 text-91'>{t('version')}: {AppInfo.version}</h6>
        </div>

        <hr />
        <div className='d-flex flex-column align-items-center'>
            <small>{t('anyQuestions')}:</small>
            <h6>
                <a
                    href={`mailto:${supportEmail}`}
                    className='text-decoration-none'
                    target='_blank'
                    rel="noopener noreferrer"
                >
                    {supportEmail}
                </a>
            </h6>
        </div>

        <div className='d-flex justify-content-center mt-4'>
            <button
                className='btn btn-sm btn-lightblue'
                onClick={ () => dispatch( closeModal() ) }
            >
                {t('close')}
            </button>
        </div>
    </>
  )
}

export default AcercaDe