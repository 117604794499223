import { useAuth0 } from '@auth0/auth0-react';
import AppRouter from './components/AppRouter';
import Loader from './components/pages/Loader';
import RedirectUserScreen from './components/pages/RedirectUserScreen';


function App() {

  const { isAuthenticated, isLoading } = useAuth0();

  if(isLoading){
    return <Loader/>
  }

  return (
    <>
      {
        isAuthenticated
        ? <AppRouter/>
        : <RedirectUserScreen />
      }
    </>
  );
}

export default App;
